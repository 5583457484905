import { Stack } from '@mui/material'
import { memo, useCallback, useEffect, useState } from 'react'
import { BodyField, BodyPart } from '../BodyField'
import { ColorPicker } from '../ColorPicker'
import { ImageFileDropZone, ImageFileDropZoneResult } from '../FileDropZone'
import { fileStore } from '../fileStore'
import { useRefresh } from '../hooks'
import { ImageButton, ImageButtonNone } from '../ModelButton'
import { BackdropListItem, useStoreDispatch, useStoreSelector } from '../store'
import { generateID } from '../utils'

const BackdropImage = memo(function BackdropImage({ item }: { item: BackdropListItem }) {
    const imageID = useStoreSelector(s => s.backdrop.imageID)
    const [img, setImg] = useState<HTMLImageElement | null>(null)
    const dispatch = useStoreDispatch()

    useEffect(() => {
        fileStore.retrieveImage(item.iconStorageKey).then(img => {
            setImg(img)
        })
    }, [item.iconStorageKey])

    const onClick = useCallback(() => {
        dispatch(a => a.setBackdropImageID({ value: item.id }))
    }, [dispatch, item.id])

    const onDelete = useCallback(() => {
        fileStore.deleteFile(item.fullStorageKey)
        fileStore.deleteFile(item.iconStorageKey)
        dispatch(a => a.removeBackdropImage(item))
    }, [dispatch, item])

    return <ImageButton label={item.fileName} size='small' src={img?.src} active={imageID === item.id} onClick={onClick} onDelete={onDelete} />
})

export const BackdropPart = memo(function BackdropPart() {
    const [refreshImages, refreshImageKey] = useRefresh()
    const color = useStoreSelector(s => s.backdrop.color)
    const imageID = useStoreSelector(s => s.backdrop.imageID)
    const images = useStoreSelector(s => s.backdrop.backdropImagesList)
    const dispatch = useStoreDispatch()

    const setColor = useCallback((color: string) => {
        dispatch(a => a.setBackdropColor({ value: color }))
    }, [dispatch])

    const clearColor = useCallback(() => {
        dispatch(a => a.setBackdropColor({ value: null }))
    }, [dispatch])

    const clearImage = useCallback(() => {
        dispatch(a => a.setBackdropImageID({ value: null }))
    }, [dispatch])

    const onUpload = useCallback(({ buffer, fileName, image }: ImageFileDropZoneResult) => {
        const id = generateID()
        const fullStorageKey = generateID()
        const iconStorageKey = generateID()
        fileStore.storeFile(fullStorageKey, buffer)
        {
            const cvs = document.createElement('canvas')
            cvs.width = 100
            cvs.height = 100
            const ctx = cvs.getContext('2d')!

            const imageRatio = image.width / image.height
            const canvasRatio = cvs.width / cvs.height
            let w = cvs.width
            let h = cvs.height
            if (imageRatio < canvasRatio) {
                h = w / imageRatio
            } else {
                w = h * imageRatio
            }
            const x = -(w - cvs.width) / 2
            const y = -(h - cvs.height) / 2
            ctx.drawImage(image, x, y, w, h)
            cvs.toBlob(blob => blob?.arrayBuffer().then(buffer => fileStore.storeFile(iconStorageKey, buffer).then(() => refreshImages())))
        }

        dispatch(a => a.addBackdropImage({ id, fileName, fullStorageKey, iconStorageKey }))
    }, [dispatch, refreshImages])

    return <BodyPart label='Backdrop'>
        <BodyField label='Color'>
            <Stack direction='row' spacing={1}>
                <ImageButtonNone label='No Color' size='tiny' active={color === null} onClick={clearColor} />
                <ColorPicker group='Backdrop' label='Color' value={color ?? '000000'} setValue={setColor} setValueCommitted={setColor} />
            </Stack>
        </BodyField>
        <BodyField label='Image'>
            <Stack key={refreshImageKey} direction='row' flexWrap='wrap' spacing={1} pt={1} pb={1}>
                <ImageButtonNone label='No Image' size='small' active={imageID === null} onClick={clearImage} />
                {images.map(i => <BackdropImage key={i.id} item={i} />)}
            </Stack>
        </BodyField>
        <BodyField label='Upload Image'>
            <ImageFileDropZone fileDesc='a .jpg or .png image file' onUpload={onUpload} />
        </BodyField>
    </BodyPart>
})
