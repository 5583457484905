import { createModel, enumToChoiceOptions, enumToClothingOptions, GenericModelStaticData, ModelType } from './common'

export enum EarType {
    None = 0,
    Droopy = 1,
    Perky = 2,
}

export enum HornType {
    None = 0,
    Small = 1,
    Large = 2,
}

export enum TailType {
    None = 0,
    Fluffy = 1,
    Thin = 2,
    Tentacle = 3,
}

export enum HairType {
    Normal = 0,
    Buns = 1,
}

export enum NoseType {
    Normal = 0,
    Snout = 1,
}

export enum BellyType {
    None = 0,
    Pregnant = 1,
    Stuffed = 2,
    Vore = 3,
    Eggs = 4,
}

export enum BellyButtonType {
    Outie = 0,
    Thin = 1,
    Wide = 2,
}

export enum ClothingTopType {
    None = 0,
    Tunic = 1,
    OnePiece = 2,
    OnePieceFlashing = 3,
    Bra = 4,
}

export enum ClothingTopUnderType {
    None = 0,
    Wrap = 1,
}

export enum ClothingBottomType {
    None = 0,
    Tunic = 1,
    Shorts = 2,
}

export enum ClothingBottomUnderType {
    None = 0,
    Underwear = 1,
}

export enum ClothingLegsUnderType {
    None = 0,
    ThighHighs = 1,
}

export enum ExpressionEyeType {
    None = 0,
    Dark = 1,
}

export enum ExpressionMouthType {
    None = 0,
    Pout = 1,
    Hungry = 2,
    TongueOut = 3,
}

export const GENA_MODEL: GenericModelStaticData = createModel(ModelType.GenaTypeI_HSGBaseModelR1_P_V1, {
    type: ModelType.GenaTypeI_HSGBaseModelR1_P_V1,
    colors: {
        skin: ['FFDBCA', '9F4428'],
        skinHighlights: ['FFDBCA', '9F4428'],
        skinShadows: ['FFDBCA', '9F4428'],
        hair: ['FFB78A', '000000'],
        tunicTop: ['FFFFFF', '000000'],
        onePiece: ['FFFFFF', '000000'],
        bra: ['FFFFFF', '000000'],
        wrap: ['FFFFFF', '000000'],
        tunicBottom: ['FFFFFF', '000000'],
        shorts: ['D49D5D', '000000'],
        underwear: ['FFFFFF', '000000'],
        thighHighs: ['FFFFFF', '000000'],
        fluffyTail: ['FFB693', '000000'],
        thinTail: ['FFB693', '000000'],
        tentacleTail: ['53B589', '000000'],
        legScales: ['FFFFFF', '000000'],
        tongue: ['FF6977', '000000'],
        mouth: ['FF0000', '000000'],
        ears: ['FFB693', '000000'],
        horns: ['FFFFFF', '000000'],
        irisLeft: ['76B8FF', '000000'],
        irisRight: ['76B8FF', '000000'],
        eyeLeft: ['76B8FF', '000000'],
        eyeRight: ['76B8FF', '000000'],
        scleraLeft: ['FFFFFF', '000000'],
        scleraRight: ['FFFFFF', '000000'],
        eyelashes: ['FFFFFF', '000000'],
        eyebrows: ['FFFFFF', '000000'],
        blush: ['FFFFFF', '000000'],
    },
    links: {
        skinHighlights: true,
        skinShadows: true,
        eyes: true,
        tunic: true,
    },
    toggles: {
        backSide: false,
        breastsOn: true,
        dickOn: false,
        legScalesOn: false,
        expressionConcern: false,
        expressionAngry: false,
        expressionHeart: false,
        expressionCheekPuff: false,
    },
    choices: {
        earType: EarType.None,
        hornType: HornType.None,
        tailType: TailType.None,
        hairType: HairType.Normal,
        noseType: NoseType.Normal,
        bellyType: BellyType.None,
        bellyButtonType: BellyButtonType.Outie,
    },
    clothing: {
        top: ClothingTopType.Tunic,
        topUnder: ClothingTopUnderType.None,
        bottom: ClothingBottomType.Tunic,
        bottomUnder: ClothingBottomUnderType.Underwear,
        legsUnder: ClothingLegsUnderType.None,
        expressionEye: ExpressionEyeType.None,
        expressionMouth: ExpressionMouthType.None,
    },
    sliders: {
        bodySize: 15,
        breastSize: 0,
        nippleSize: 0,
        thighSize: 0,
        ballSize: 0,
        dickSize: 0,
        bellySize: 0,
        weightGainSize: 0,
        muscleSize: 0,
        headSize: 0,
        torsoSlim: 0,
        thighSlim: 0,
        tailUp: 0,
        tailScale: 0,
        bellyBulges: 0,
        hairLength: 2,
        breastPose: -0.4,
        jawShape: 0,
        mouthShape: 0,
        eyeShape: 0,
    },
    unmappedArtMeshColors: {},
    unmappedParameterValues: {},
    motions: [],
    expressions: [],
    decals: {
        stomach: '',
    },
}, {
    type: ModelType.GenaTypeI_HSGBaseModelR1_P_V1,
    possibleModelFiles: [
        'Gena Type I - Base R1 P V1 (VTS Webcam)/HSG Base Model R1 P V1.model3.json',
        'HSG Base Model R1 P V1.model3.json',
    ],
    sharedParts: [{
        name: 'View',
        fields: [{
            name: 'Show Back',
            type: 'toggle',
            key: 'backSide',
        }],
    }, {
        name: 'Skin',
        tabs: ['Head', 'Body'],
        fields: [{
            name: 'Skin Color',
            type: 'color',
            key: 'skin',
        }, {
            name: 'Link Shadow Colors',
            type: 'link',
            key: 'skinShadows',
        }, {
            name: 'Skin Shadows',
            type: 'color',
            key: 'skinShadows',
            conditions: [{ type: 'link', key: 'skinShadows', value: false }],
        }, {
            name: 'Link Highlight Colors',
            type: 'link',
            key: 'skinHighlights',
        }, {
            name: 'Skin Highlights',
            type: 'color',
            key: 'skinHighlights',
            conditions: [{ type: 'link', key: 'skinHighlights', value: false }],
        }],
    }],
    tabs: [{
        name: 'Head',
        parts: [{
            name: 'Head',
            fields: [{
                name: 'Head Size',
                type: 'slider',
                key: 'headSize',
            }],
        }, {
            name: 'Hair',
            fields: [{
                name: 'Hair Color',
                type: 'color',
                key: 'hair',
            }, {
                name: 'Hair Type',
                type: 'choice',
                key: 'hairType',
            }, {
                name: 'Hair Length',
                type: 'slider',
                key: 'hairLength',
            }],
        }, {
            name: 'Ears',
            fields: [{
                name: 'Ear Type',
                type: 'choice',
                key: 'earType',
            }, {
                name: 'Ear Color',
                type: 'color',
                key: 'ears',
                conditions: [{ type: 'choice', key: 'earType', value: EarType.None, invert: true }],
            }],
        }, {
            name: 'Horns',
            fields: [{
                name: 'Horn Type',
                type: 'choice',
                key: 'hornType',
            }, {
                name: 'Horn Color',
                type: 'color',
                key: 'horns',
                conditions: [{ type: 'choice', key: 'hornType', value: HornType.None, invert: true }],
            }],
        }, {
            name: 'Eyes',
            fields: [{
                name: 'Eye Shape',
                type: 'slider',
                key: 'eyeShape',
            }, {
                name: 'Heterochromia',
                type: 'link',
                key: 'eyes',
                invert: true,
            }, {
                name: 'Eye Color',
                type: 'color',
                key: 'eyeLeft',
                conditions: [{ type: 'link', key: 'eyes', value: true }],
            }, {
                name: 'Iris Color',
                type: 'color',
                key: 'irisLeft',
                conditions: [{ type: 'link', key: 'eyes', value: true }],
            }, {
                name: 'Sclera Color',
                type: 'color',
                key: 'scleraLeft',
                conditions: [{ type: 'link', key: 'eyes', value: true }],
            }, {
                name: 'Left Eye Color',
                type: 'color',
                key: 'eyeLeft',
                conditions: [{ type: 'link', key: 'eyes', value: false }],
            }, {
                name: 'Left Iris Color',
                type: 'color',
                key: 'irisLeft',
                conditions: [{ type: 'link', key: 'eyes', value: false }],
            }, {
                name: 'Left Sclera Color',
                type: 'color',
                key: 'scleraLeft',
                conditions: [{ type: 'link', key: 'eyes', value: false }],
            }, {
                name: 'Right Eye Color',
                type: 'color',
                key: 'eyeRight',
                conditions: [{ type: 'link', key: 'eyes', value: false }],
            }, {
                name: 'Right Iris Color',
                type: 'color',
                key: 'irisRight',
                conditions: [{ type: 'link', key: 'eyes', value: false }],
            }, {
                name: 'Right Sclera Color',
                type: 'color',
                key: 'scleraRight',
                conditions: [{ type: 'link', key: 'eyes', value: false }],
            }],
        }, {
            name: 'Face',
            fields: [{
                name: 'Eyelash Color',
                type: 'color',
                key: 'eyelashes',
            }, {
                name: 'Eyebrow Color',
                type: 'color',
                key: 'eyebrows',
            }, {
                name: 'Blush Color',
                type: 'color',
                key: 'blush',
            }],
        }, {
            name: 'Nose',
            fields: [{
                name: 'Nose Type',
                type: 'choice',
                key: 'noseType',
            }],
        }, {
            name: 'Mouth',
            fields: [{
                name: 'Mouth Shape',
                type: 'slider',
                key: 'mouthShape',
            }, {
                name: 'Jaw Shape',
                type: 'slider',
                key: 'jawShape',
            }, {
                name: 'Mouth Color',
                type: 'color',
                key: 'mouth',
            }, {
                name: 'Tongue Color',
                type: 'color',
                key: 'tongue',
            }],
        }],
    }, {
        name: 'Body',
        parts: [{
            name: 'Body',
            fields: [{
                name: 'Body Size',
                type: 'slider',
                key: 'bodySize',
            }, {
                name: 'Weight Gain',
                type: 'slider',
                key: 'weightGainSize',
            }, {
                name: 'Muscles',
                type: 'slider',
                key: 'muscleSize',
            }, {
                name: 'Stomach Decal',
                type: 'decal',
                key: 'stomach',
            }],
        }, {
            name: 'Chest',
            fields: [{
                name: 'Breasts On',
                type: 'toggle',
                key: 'breastsOn',
            }, {
                name: 'Breast Size',
                type: 'slider',
                key: 'breastSize',
                conditions: [{ type: 'toggle', key: 'breastsOn', value: true }],
            }, {
                name: 'Breast Pose',
                type: 'slider',
                key: 'breastPose',
                conditions: [{ type: 'toggle', key: 'breastsOn', value: true }],
            }, {
                name: 'Nipple Size',
                type: 'slider',
                key: 'nippleSize',
                conditions: [{ type: 'toggle', key: 'breastsOn', value: true }],
            }, {
                name: 'Slim Torso',
                type: 'slider',
                key: 'torsoSlim',
            }],
        }, {
            name: 'Belly',
            fields: [{
                name: 'Belly Type',
                type: 'choice',
                key: 'bellyType',
            }, {
                name: 'Belly Size',
                type: 'slider',
                key: 'bellySize',
                conditions: [{ type: 'choice', key: 'bellyType', value: BellyType.None, invert: true }],
            }, {
                name: 'Belly Button Type',
                type: 'choice',
                key: 'bellyButtonType',
                conditions: [{ type: 'choice', key: 'bellyType', value: BellyType.None, invert: true }],
            }, {
                name: 'Belly Bulges',
                type: 'slider',
                key: 'bellyBulges',
                conditions: [{ type: 'choice', key: 'bellyType', value: BellyType.Vore }],
            }],
        }, {
            name: 'Dick',
            fields: [{
                name: 'Dick On',
                type: 'toggle',
                key: 'dickOn',
            }, {
                name: 'Dick Size',
                type: 'slider',
                key: 'dickSize',
                conditions: [{ type: 'toggle', key: 'dickOn', value: true }],
            }, {
                name: 'Balls Size',
                type: 'slider',
                key: 'ballSize',
                conditions: [{ type: 'toggle', key: 'dickOn', value: true }],
            }],
        }, {
            name: 'Tail',
            fields: [{
                name: 'Tail Type',
                type: 'choice',
                key: 'tailType',
            }, {
                name: 'Fluffy Tail Color',
                type: 'color',
                key: 'fluffyTail',
                conditions: [{ type: 'choice', key: 'tailType', value: TailType.Fluffy }],
            }, {
                name: 'Thin Tail Color',
                type: 'color',
                key: 'thinTail',
                conditions: [{ type: 'choice', key: 'tailType', value: TailType.Thin }],
            }, {
                name: 'Tentacle Color',
                type: 'color',
                key: 'tentacleTail',
                conditions: [{ type: 'choice', key: 'tailType', value: TailType.Tentacle }],
            }, {
                name: 'Tail Height',
                type: 'slider',
                key: 'tailUp',
                conditions: [{ type: 'choice', key: 'tailType', value: TailType.None, invert: true }],
            }, {
                name: 'Tail Size',
                type: 'slider',
                key: 'tailScale',
                conditions: [{ type: 'choice', key: 'tailType', value: TailType.None, invert: true }],
            }],
        }, {
            name: 'Hips',
            fields: [{
                name: 'Thigh Size',
                type: 'slider',
                key: 'thighSize',
            }, {
                name: 'Slim Thighs',
                type: 'slider',
                key: 'thighSlim',
            }, {
                name: 'Leg Scales On',
                type: 'toggle',
                key: 'legScalesOn',
            }, {
                name: 'Leg Scales Color',
                type: 'color',
                key: 'legScales',
                conditions: [{ type: 'toggle', key: 'legScalesOn', value: true }],
            }],
        }],
    }, {
        name: 'Clothing',
        parts: [{
            name: 'Top',
            fields: [{
                name: 'Top',
                type: 'clothing',
                key: 'top',
            }, {
                name: 'Tunic Top Color',
                type: 'color',
                key: 'tunicTop',
                conditions: [{ type: 'clothing', key: 'top', value: ClothingTopType.Tunic }],
            }, {
                name: 'One-Piece Color',
                type: 'color',
                key: 'onePiece',
                conditions: [{ type: 'clothing', key: 'top', value: ClothingTopType.OnePiece }],
            }, {
                name: 'One-Piece (Flashing) Color',
                type: 'color',
                key: 'onePiece',
                conditions: [{ type: 'clothing', key: 'top', value: ClothingTopType.OnePieceFlashing }],
            }, {
                name: 'Bra Color',
                type: 'color',
                key: 'bra',
                conditions: [{ type: 'clothing', key: 'top', value: ClothingTopType.Bra }],
            }, {
                name: 'Under Top',
                type: 'clothing',
                key: 'topUnder',
            }, {
                name: 'Wrap Color',
                type: 'color',
                key: 'wrap',
                conditions: [{ type: 'clothing', key: 'topUnder', value: ClothingTopUnderType.Wrap }],
            }],
        }, {
            name: 'Bottom',
            fields: [{
                name: 'Bottom',
                type: 'clothing',
                key: 'bottom',
            }, {
                name: 'Match Tunic Top Color',
                type: 'link',
                key: 'tunic',
                conditions: [{ type: 'clothing', key: 'bottom', value: ClothingBottomType.Tunic }],
            }, {
                name: 'Tunic Bottom Color',
                type: 'color',
                key: 'tunicBottom',
                conditions: [
                    { type: 'clothing', key: 'bottom', value: ClothingBottomType.Tunic },
                    { type: 'link', key: 'tunic', value: false },
                ],
            }, {
                name: 'Shorts Color',
                type: 'color',
                key: 'shorts',
                conditions: [{ type: 'clothing', key: 'bottom', value: ClothingBottomType.Shorts }],
            }, {
                name: 'Underwear',
                type: 'clothing',
                key: 'bottomUnder',
            }, {
                name: 'Underwear Color',
                type: 'color',
                key: 'underwear',
                conditions: [{ type: 'clothing', key: 'bottomUnder', value: ClothingBottomUnderType.Underwear }],
            }],
        }, {
            name: 'Legwear',
            fields: [{
                name: 'Legwear',
                type: 'clothing',
                key: 'legsUnder',
            }, {
                name: 'Thigh-Highs Color',
                type: 'color',
                key: 'thighHighs',
                conditions: [{ type: 'clothing', key: 'legsUnder', value: ClothingLegsUnderType.ThighHighs }],
            }],
        }],
    }, {
        name: 'Expressions',
        parts: [{
            name: 'Expressions',
            fields: [{
                name: 'Concerned',
                type: 'toggle',
                key: 'expressionConcern',
            }, {
                name: 'Angry',
                type: 'toggle',
                key: 'expressionAngry',
            }, {
                name: 'Hearts',
                type: 'toggle',
                key: 'expressionHeart',
            }, {
                name: 'Eyes',
                type: 'clothing',
                key: 'expressionEye',
            }, {
                name: 'Mouth',
                type: 'clothing',
                key: 'expressionMouth',
            }, {
                name: 'Cheek Puff',
                type: 'toggle',
                key: 'expressionCheekPuff',
            }],
        }],
    }],
    choiceOptions: {
        earType: enumToChoiceOptions(EarType),
        hornType: enumToChoiceOptions(HornType),
        tailType: enumToChoiceOptions(TailType),
        hairType: enumToChoiceOptions(HairType),
        noseType: enumToChoiceOptions(NoseType),
        bellyType: enumToChoiceOptions(BellyType),
        bellyButtonType: enumToChoiceOptions(BellyButtonType),
    },
    clothingOptions: {
        top: enumToClothingOptions(ClothingTopType),
        topUnder: enumToClothingOptions(ClothingTopUnderType),
        bottom: enumToClothingOptions(ClothingBottomType),
        bottomUnder: enumToClothingOptions(ClothingBottomUnderType),
        legsUnder: enumToClothingOptions(ClothingLegsUnderType),
        expressionEye: enumToClothingOptions(ExpressionEyeType),
        expressionMouth: enumToClothingOptions(ExpressionMouthType),
    },
    links: {
        skinHighlights: {
            colors: {
                'skinHighlights': 'skin',
            },
        },
        skinShadows: {
            colors: {
                'skinShadows': 'skin',
            },
        },
        eyes: {
            colors: {
                'eyeRight': 'eyeLeft',
                'irisRight': 'irisLeft',
                'scleraRight': 'scleraLeft',
            },
        },
        tunic: {
            colors: {
                'tunicBottom': 'tunicTop',
            },
        }
    },
    colorArtMeshes: {
        skin: ['LipTop', 'LipBottom', 'LipTopLine', 'LipBottomLine', 'MouthCover', 'HeadLine', 'Head2', 'Nips', 'BoobNude', 'BoobBelly', 'PregBelly', 'LegL2', 'Neck', 'Neck2', 'SkinBlobboob', 'LegL', 'ButtBack', 'ButtBack2', 'ButtBack3', 'LegL3', 'ButtBack4', 'LegL4', 'LidL', 'FatBelly', 'ButtBView2', 'TorsoBViewBack', 'TorsoShadeBView', 'TorsoBView', 'Ball2', 'Dick2', 'Boob', 'Dick', 'TorsoLine', 'TorsoShade', 'Torso2', 'NeckLines', 'LidR', 'Nose', 'Hand2Back', 'Hand2BackL', 'Hand2Back2', 'Hand2BackL2', 'Hand', 'HandSL', 'HandL', 'Hand2Line', 'Hand2', 'Hand2Line2', 'Hand2R', 'ArmL', 'ArmL2', 'ArmL3', 'ArmL4', 'pregbuttoncover', 'Ball', 'NipsB', 'Boobnips', 'BoobNipcover', 'BoobNipcover2', 'BoobnipsB', 'PregLines', 'Butt2Bview2', 'Butt2Bview', 'ButtBViewLines22', 'ButtBviewLine2', 'TorsoLineBView', 'TorsoLineBViewBack', 'ButtBViewLines2', 'ButtBView', 'ButtBviewLine', 'chest', 'chestTop', 'Mucle', 'MucleLine', 'BellyButton2', 'ArtMesh'],
        skinHighlights: ['NoseHL', 'BoobHL', 'BoobHL2', 'LegLHL2', 'LegLHL', 'ButtBackHL', 'ButtBackHL2', 'ButtBackHL3', 'LegLHL3', 'ButtBackHL4', 'LegLHL4', 'FatBellyHL', 'ButtBViewHL', 'ButtBViewHL2', 'PregBellyHL', 'MucleHL'],
        skinShadows: ['Boobs2', 'BoobNudeS', 'BoobBellyS', 'PregBellyS', 'LegLS2', 'NeckS', 'LegLS', 'ButtBackS', 'ButtBackS2', 'ButtBackS3', 'LegLS3', 'ButtBackS4', 'LegLS4', 'FatBellyS', 'ButtBViewS3', 'Ball2S', 'BallS', 'Dick2S', 'DickS', 'HandS', 'ArmLS', 'ArmLS2', 'ArmLS3', 'ArmLS4', 'ButtBViewS2', 'ButtBViewS', 'ButtBViewS4', 'ButtBViewS2', 'TorsoShade', 'TorsoShadeBView', 'EggBulge', 'VoreBulge'],
        hair: ['HairFront2', 'HairSides', 'HairFront3', 'HairSides2', 'HairTop', 'Buns', 'LongHair'],
        tunicTop: ['Top', 'TopS', 'Top3', 'TopS3', 'BoobTop', 'BoobTopS', 'BoobTop2', 'BoobTopS2'],
        onePiece: ['UnderTop', 'UnderTopS', 'UnderTop2', 'UnderTopS2', 'UnderTop3', 'BoobTop3', 'BoobTopS3'],
        bra: ['BraSB', 'BraB', 'Bra', 'BraS'],
        wrap: ['Wrap', 'WrapB', 'Wrap3', 'Wrap4'],
        tunicBottom: ['TopLong', 'TopLongS'],
        shorts: ['Bulge', 'BulgeS', 'Bulge2', 'BulgeS2', 'Underwear', 'UnderwearS'],
        underwear: ['Underwear1', 'Underwear1S'],
        thighHighs: ['ThighHigh', 'ThighHigh2', 'ThighHigh3', 'ThighHigh4'],
        fluffyTail: ['Tail2_0', 'Tail2_1', 'Tail2_2', 'Tail2_3', 'Tail2_4', 'Tail2_5', 'Tail2_6', 'Tail2_7', 'Tail2_8', 'Tail2_9', 'Tail2_10'],
        thinTail: ['Tail1_0', 'Tail1_1', 'Tail1_2', 'Tail1_3', 'Tail1_4', 'Tail1_5', 'Tail1_6', 'Tail1_7', 'Tail1_8', 'Tail1_9', 'Tail1_10', 'Tail1_11', 'Tail1_12'],
        tentacleTail: ['Tenticle1_0', 'Tenticle1_1', 'Tenticle1_2', 'Tenticle1_3', 'Tenticle1_4', 'Tenticle1_5', 'Tenticle1_6', 'Tenticle1_7', 'Tenticle1_8', 'Tenticle1_9', 'Tenticle1_10', 'Tenticle1_11', 'Tenticle1_12', 'Tenticle1_13', 'Tenticle1_14'],
        legScales: ['LegLDecal', 'LegLDecal2', 'LegLDecal3', 'LegLDecal4'],
        tongue: ['Toung', 'ToungOut'],
        mouth: ['Mouth2'],
        ears: ['Ears'],
        horns: ['Horns'],
        irisLeft: ['EyerisL'],
        irisRight: ['Eyeris'],
        eyeLeft: ['EyebaseL'],
        eyeRight: ['Eyebase'],
        scleraLeft: ['EyeWhiteL'],
        scleraRight: ['EyeWhite'],
        eyelashes: ['LashL', 'Lash2L', 'LashR', 'Lash2R'],
        eyebrows: ['BrowL', 'BrowR'],
        blush: ['Blush'],
    },
    toggleParams: {
        backSide: ['view_Back'],
        breastsOn: ['Body_BreastOn'],
        dickOn: ['Body_DickOn'],
        legScalesOn: ['cloth_LegScale'],
        expressionConcern: ['EXPConcern'],
        expressionAngry: ['EXPAngry'],
        expressionHeart: ['EXPHeart'],
        expressionCheekPuff: ['EXPCheekPuff'],
    },
    choiceParams: {
        earType: ['Body_EarType'],
        hornType: ['Body_HornType'],
        tailType: ['Body_TailType'],
        hairType: ['Body_HairType'],
        noseType: ['Body_HairType2'],
        bellyType: [],
        bellyButtonType: ['Body_BellyButtonType'],
    },
    clothingParams: {
        top: {
            [ClothingTopType.None]: [],
            [ClothingTopType.Tunic]: ['cloth_Bra', 'cloth_Top1Top'],
            [ClothingTopType.OnePiece]: ['cloth_Bra', 'cloth_Top2'],
            [ClothingTopType.OnePieceFlashing]: ['cloth_Top2'],
            [ClothingTopType.Bra]: ['cloth_Bra'],
        },
        topUnder: {
            [ClothingTopUnderType.None]: [],
            [ClothingTopUnderType.Wrap]: ['cloth_Wrap'],
        },
        bottom: {
            [ClothingBottomType.None]: [],
            [ClothingBottomType.Tunic]: ['cloth_Top1Bottom'],
            [ClothingBottomType.Shorts]: ['cloth_Shorts'],
        },
        bottomUnder: {
            [ClothingBottomUnderType.None]: [],
            [ClothingBottomUnderType.Underwear]: ['cloth_underwear'],
        },
        legsUnder: {
            [ClothingLegsUnderType.None]: [],
            [ClothingLegsUnderType.ThighHighs]: ['cloth_ThighHigh'],
        },
        expressionEye: {
            [ExpressionEyeType.None]: [],
            [ExpressionEyeType.Dark]: ['EXPDarkEyes'],
        },
        expressionMouth: {
            [ExpressionMouthType.None]: [],
            [ExpressionMouthType.Pout]: ['EXPPout'],
            [ExpressionMouthType.Hungry]: ['EXPHungy'],
            [ExpressionMouthType.TongueOut]: ['EXPToung'],
        },
    },
    sliderParams: {
        bodySize: ['size_Body'],
        breastSize: ['size_Breast1'],
        nippleSize: ['size_Nip1'],
        thighSize: ['size_Thigh'],
        ballSize: ['size_Ball1'],
        dickSize: ['size_Dick1'],
        bellySize: [''],
        weightGainSize: ['size_WeightGain'],
        muscleSize: ['size_Muscle'],
        headSize: ['size_BodyHead'],
        torsoSlim: ['slim_BodyTorso'],
        thighSlim: ['slim_BodyThigh'],
        tailUp: ['Body_TailUp'],
        tailScale: ['Body_TailScale'],
        bellyBulges: ['Body_BellyButtonType2'],
        hairLength: ['Body_HairLength'],
        breastPose: ['Body_BreastPose'],
        jawShape: ['Body_JawShape'],
        mouthShape: ['Body_MouthShape'],
        eyeShape: ['Body_EyeShape'],
    },
    smoothParams: {
        'size_Thigh': 5,
        'size_Breast1': 5,
        'size_Ball1': 5,
        'size_Dick1': 5,
        'size_Preg': 5,
        'size_Preg2': 5,
        'size_Preg3': 5,
        'size_Preg4': 5,
        'size_WeightGain': 5,
        'cloth_Bra': 2,
        'cloth_Top1Top': 2,
        'cloth_Top2': 2,
        'cloth_Wrap': 2,
        'cloth_Top1Bottom': 2,
        'cloth_Shorts': 2,
        'cloth_underwear': 2,
        'cloth_ThighHigh': 2,
    },
    staticArtMeshColors: {
        "Drop": ["00A6FF", "000000"],
        "Heart": ["FF3B72", "000000"],
        "Heart3": ["FF3B72", "000000"],
        "Heart2": ["FF3B72", "000000"],
        "Heart4": ["FF3B72", "000000"],
        "angy": ["FFFFFF", "DB0000"],
    },
    staticParameterValues: {},
    decals: {
        stomach: {
            textureIndex: 0,
            x: 448,
            y: 4448,
            w: 1024,
            h: 512,
        }
    },
})
