import { Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'
import Menu from '@mui/material/Menu'
import Slider from '@mui/material/Slider'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import ColorConvert from 'color-convert'
import { memo, useState, useCallback, useRef } from 'react'

function isValidHexNumber(v: string) {
    return v.length === 6 && /^[0-9a-fA-F]+$/.test(v)
}

const ColorBox = memo(function ColorBox({ values, width, height, onClick, marginBottom }: { values: string[], width?: number, height?: number, onClick?: (e: React.MouseEvent<HTMLElement>) => void, marginBottom?: number }) {
    return <Box component='button' sx={{
        appearance: 'none',
        padding: 0,
        display: 'block',
        border: '1px solid rgba(255, 255, 255, 0.5)',
        background: values.length > 1 ? `linear-gradient(to right, ${values.map(v => `#${v}`).join(', ')})` : `#${values[0]}`,
        width: width ?? 'auto',
        height: height ?? 'auto',
        borderRadius: 2,
        cursor: onClick ? 'pointer' : undefined,
        marginBottom,
        ':hover': {
            border: '1px solid rgba(255, 255, 255, 0.75)',
        },
        ':focus': {
            border: `2px solid black`,
            outline: '2px solid white',
        },
    }
    } tabIndex={onClick ? 0 : undefined} onClick={onClick} />
})

export const ColorPicker = memo(function ColorPicker({ value, setValue, setValueCommitted, group, label }: { value: string, setValue: (v: string) => void, setValueCommitted: (newValue: string, oldValue: string) => void, group: string, label: string }) {
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null)
    const [rgbValue, setRgbValueDirect] = useState<[number, number, number]>(ColorConvert.hex.rgb(value))
    const [hsvValue, setHsvValueDirect] = useState<[number, number, number]>(ColorConvert.hex.hsv(value))
    const [hexValue, setHexValueDirect] = useState<string>(value)
    const initialValueRef = useRef<string | null>(null)

    const onMenuOpen = useCallback((e: React.MouseEvent<HTMLElement>) => {
        setMenuAnchor(e.currentTarget)
        initialValueRef.current = value
    }, [value])

    const onMenuClose = useCallback(() => {
        setMenuAnchor(null)
        const initialValue = initialValueRef.current ?? hexValue
        setValueCommitted(hexValue, initialValue)
        initialValueRef.current = null
    }, [hexValue, setValueCommitted])

    const setRgbValue = useCallback((v: [number, number, number]) => {
        setRgbValueDirect(v)
        setHsvValueDirect(ColorConvert.rgb.hsv(v))
        setHexValueDirect(ColorConvert.rgb.hex(v))
        setValue(ColorConvert.rgb.hex(v))
    }, [setValue])

    const setHsvValue = useCallback((v: [number, number, number]) => {
        setHsvValueDirect(v)
        setRgbValueDirect(ColorConvert.hsv.rgb(v))
        setHexValueDirect(ColorConvert.hsv.hex(v))
        setValue(ColorConvert.hsv.hex(v))
    }, [setValue])

    const setHexValue = useCallback((v: string) => {
        setHexValueDirect(v)
        if (!isValidHexNumber(v)) return
        setRgbValueDirect(ColorConvert.hex.rgb(v))
        setHsvValueDirect(ColorConvert.hex.hsv(v))
        setValue(v)
    }, [setValue])

    return <>
        <Stack direction='row'>
            <Tooltip title={`${group} - ${label}`}>
                <div>
                    <ColorBox values={[value]} width={40} height={40} onClick={onMenuOpen} />
                </div>
            </Tooltip>
        </Stack>
        <Menu open={!!menuAnchor} onClose={onMenuClose} anchorEl={menuAnchor}>
            <Stack p={2} pt={1}>
                <Typography mb={1} variant="h5" color="inherit" component="div">{group} - {label}</Typography>
                <ColorBox values={[value]} height={50} marginBottom={1} />
                <ColorPickerHSV value={hsvValue} setValue={setHsvValue} />
                <ColorPickerRGB value={rgbValue} setValue={setRgbValue} />
                <ColorPickerHex value={hexValue} setValue={setHexValue} />
            </Stack>
        </Menu>
    </>
})

const ColorPickerHex = memo(function ColorPickerHex({ value, setValue }: { value: string, setValue: (value: string) => void }) {
    return <>
        <TextField value={value} onChange={e => setValue(e.target.value)} size='small' label='Hex' error={!isValidHexNumber(value)} inputProps={{ pattern: '[0-9a-fA-F]*' }} InputProps={{ startAdornment: <InputAdornment position='start'>#</InputAdornment> }} />
    </>
})

const ColorPickerHSV = memo(function ColorPickerHSV({ value, setValue }: { value: [number, number, number], setValue: (value: [number, number, number]) => void }) {
    const [h, s, l] = value
    return <>
        <ColorBox values={new Array(361).fill(0).map((_, i) => ColorConvert.hsv.hex([i, s, l]))} height={10} />
        <Slider size='small' min={0} max={360} marks={[{ value: h, label: 'H' }]} value={h} onChange={(_, v) => setValue([Array.isArray(v) ? v[0] : v, s, l])} />
        <ColorBox values={new Array(101).fill(0).map((_, i) => ColorConvert.hsv.hex([h, i, l]))} height={10} />
        <Slider size='small' min={0} max={100} marks={[{ value: s, label: 'S' }]} value={s} onChange={(_, v) => setValue([h, Array.isArray(v) ? v[0] : v, l])} />
        <ColorBox values={new Array(101).fill(0).map((_, i) => ColorConvert.hsv.hex([h, s, i]))} height={10} />
        <Slider size='small' min={0} max={100} marks={[{ value: l, label: 'V' }]} value={l} onChange={(_, v) => setValue([h, s, Array.isArray(v) ? v[0] : v])} />
    </>
})

const ColorPickerRGB = memo(function ColorPickerRGB({ value, setValue }: { value: [number, number, number], setValue: (value: [number, number, number]) => void }) {
    const [r, g, b] = value
    return <>
        <ColorBox values={new Array(256).fill(0).map((_, i) => ColorConvert.rgb.hex([i, g, b]))} height={10} />
        <Slider size='small' min={0} max={255} step={1} marks={[{ value: r, label: 'R' }]} value={r} onChange={(_, v) => setValue([Array.isArray(v) ? v[0] : v, g, b])} />
        <ColorBox values={new Array(256).fill(0).map((_, i) => ColorConvert.rgb.hex([r, i, b]))} height={10} />
        <Slider size='small' min={0} max={255} step={1} marks={[{ value: g, label: 'G' }]} value={g} onChange={(_, v) => setValue([r, Array.isArray(v) ? v[0] : v, b])} />
        <ColorBox values={new Array(256).fill(0).map((_, i) => ColorConvert.rgb.hex([r, g, i]))} height={10} />
        <Slider size='small' min={0} max={255} step={1} marks={[{ value: b, label: 'B' }]} value={b} onChange={(_, v) => setValue([r, g, Array.isArray(v) ? v[0] : v])} />
    </>
})

