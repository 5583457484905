import { generateID, getEnumEntries, getEnumKeys, identifierToLabel, safeMerge, tuple } from '../utils'

export enum ModelType {
    Unknown = 'unknown',
    GenaTypeI_HSGBaseModelR1_P_V1 = 'Gena Type I - HSG Base Model R1 P V1',
    Ellie_P_V7 = 'Ellie P v7',
    Lace_P_V8 = 'Lace P v8',
    Flame_V3 = 'Flame v3',
    PathBandit_V10 = 'Path Bandit v10',
    Mia_P_V7 = 'Mia P v7',
    Ivy_P_V6 = 'Ivy P v6',
    Boa_V8 = 'Boa v8',
    Flynn_P_V5 = 'Flynn P v5',
    Slime_V27 = 'Slime v27',
    Slime_Familiar = 'Patreon Slime',
    Maze_A_V40 = 'Maze A v40',
}

export function safeMergeCustomization(defaults: GenericModelCustomization, other: any): GenericModelCustomization {
    return {
        ...safeMerge(defaults, other),
        unmappedArtMeshColors: {
            ...other?.unmappedArtMeshColors,
        },
        unmappedParameterValues: {
            ...other?.unmappedParameterValues,
        },
        decals: {
            ...other?.decals,
        },
    }
}

export function safeMergePreset(defaults: GenericModelCustomization, other: any): GenericModelPreset {
    return {
        ...safeMerge(defaults, other),
        unmappedArtMeshColors: {
            ...other?.unmappedArtMeshColors,
        },
        unmappedParameterValues: {
            ...other?.unmappedParameterValues,
        },
        decals: {
            ...other?.decals,
        },
        id: other.id ?? generateID(),
        name: other.name ?? defaults.type,
        fileName: other.fileName ?? '',
        updated: Date.now(),
    }
}

export function isPresetModified(defaults: GenericModelCustomization, preset: GenericModelPreset): boolean {
    if (Object.keys(defaults.colors).some(k => preset.colors[k]?.[0] !== defaults.colors[k]?.[0])) return true
    if (Object.keys(defaults.colors).some(k => preset.colors[k]?.[1] !== defaults.colors[k]?.[1])) return true
    if (Object.keys(defaults.links).some(k => preset.links[k] !== defaults.links[k])) return true
    if (Object.keys(defaults.toggles).some(k => preset.toggles[k] !== defaults.toggles[k])) return true
    if (Object.keys(defaults.choices).some(k => preset.choices[k] !== defaults.choices[k])) return true
    if (Object.keys(defaults.clothing).some(k => preset.clothing[k] !== defaults.clothing[k])) return true
    if (Object.keys(defaults.sliders).some(k => preset.sliders[k] !== defaults.sliders[k])) return true
    if (Object.keys(preset.unmappedArtMeshColors).length > 0) return true
    if (Object.keys(preset.unmappedParameterValues).length > 0) return true
    if (preset.expressions?.length) return true
    if (preset.motions?.length) return true
    if (Object.keys(preset.decals).length > 0) return true
    return false
}

export interface ModelCustomization<T extends ModelType> {
    type: T
    colors: Record<string, [string, string]>
    links: Record<string, boolean>
    toggles: Record<string, boolean>
    choices: Record<string, number>
    clothing: Record<string, number>
    sliders: Record<string, number>
    unmappedArtMeshColors: Partial<Record<string, [string, string]>>
    unmappedParameterValues: Partial<Record<string, number>>
    motions: string[]
    expressions: string[]
    decals: Partial<Record<string, string>>
}

export type GenericModelCustomization = ModelCustomization<ModelType>

export type ModelCustomizationOverrides<T extends ModelType, C extends ModelCustomization<T>> = Partial<{ [K in keyof Omit<C, 'type'>]: Partial<C[K]> }>

export type GenericModelCustomizationOverrides = Partial<Omit<GenericModelCustomization, 'type'>>

export interface ModelPreset<T extends ModelType> extends ModelCustomization<T> {
    id: string
    name: string
    fileName: string
    updated: number
}

export type GenericModelPreset = ModelPreset<ModelType>

export type ModelCondition<T extends ModelType, C extends ModelCustomization<T>> =
    { invert?: boolean } & (
        | { type: 'link', key: Extract<keyof C['links'], string>, value: boolean }
        | { type: 'toggle', key: Extract<keyof C['toggles'], string>, value: boolean }
        | { type: 'choice', key: Extract<keyof C['choices'], string>, value: number }
        | { type: 'clothing', key: Extract<keyof C['clothing'], string>, value: number }
    )

export type GenericModelCondition = ModelCondition<ModelType, GenericModelCustomization>

export type ModelField<T extends ModelType, C extends ModelCustomization<T>> = {
    name: string
    conditions?: ModelCondition<T, C>[]
} & (
        | { type: 'color', key: Extract<keyof C['colors'], string> }
        | { type: 'link', key: Extract<keyof C['links'], string>, invert?: boolean }
        | { type: 'toggle', key: Extract<keyof C['toggles'], string>, invert?: boolean }
        | { type: 'choice', key: Extract<keyof C['choices'], string> }
        | { type: 'clothing', key: Extract<keyof C['clothing'], string> }
        | { type: 'slider', key: Extract<keyof C['sliders'], string> }
        | { type: 'decal', key: Extract<keyof C['decals'], string> }
    )

export type GenericModelField = ModelField<ModelType, GenericModelCustomization>

export type ModelPart<T extends ModelType, C extends ModelCustomization<T>> = {
    name: string
    fields: ModelField<T, C>[]
}

export type GenericModelPart = ModelPart<ModelType, GenericModelCustomization>

export type SharedModelPart<T extends ModelType, C extends ModelCustomization<T>> = ModelPart<T, C> & {
    tabs?: string[]
}

export type GenericSharedModelPart = SharedModelPart<ModelType, GenericModelCustomization>

export type ModelTab<T extends ModelType, C extends ModelCustomization<T>> = {
    name: string
    parts: ModelPart<T, C>[]
}

export type GenericModelTab = ModelTab<ModelType, GenericModelCustomization>

export interface ModelDefinition<T extends ModelType, C extends ModelCustomization<T>> {
    type: T
    possibleModelFiles: string[]
    sharedParts: SharedModelPart<T, C>[]
    tabs: ModelTab<T, C>[]
    choiceOptions: Record<Extract<keyof C['choices'], string>, Record<string, number>>
    clothingOptions: Record<Extract<keyof C['clothing'], string>, string[]>
    links: Record<Extract<keyof C['links'], string>, { colors: Partial<Record<Extract<keyof C['colors'], string>, Extract<keyof C['colors'], string>>> }>
    colorArtMeshes: Record<Extract<keyof C['colors'], string>, string[]>
    toggleParams: Record<Extract<keyof C['toggles'], string>, string[]>
    choiceParams: Record<Extract<keyof C['choices'], string>, string[]>
    clothingParams: Record<Extract<keyof C['clothing'], string>, Record<number, string[]>>
    sliderParams: Record<Extract<keyof C['sliders'], string>, string[]>
    smoothParams: Partial<Record<string, number>>
    staticArtMeshColors: Partial<Record<string, [string, string]>>
    staticParameterValues: Partial<Record<string, number>>
    decals: Record<Extract<keyof C['decals'], string>, { textureIndex: number, x: number, y: number, w: number, h: number }>
}

export type GenericModelDefinition = ModelDefinition<ModelType, GenericModelCustomization>

export type ModelStaticData<T extends ModelType, C extends ModelCustomization<T>> = { defaults: C, definition: ModelDefinition<T, C> }

export type GenericModelStaticData = { defaults: GenericModelCustomization, definition: GenericModelDefinition }

export type GenericModelStaticDataMap = Record<ModelType, GenericModelStaticData>

export function createModel<T extends ModelType, C extends ModelCustomization<T>, D extends ModelDefinition<T, C>>(type: T, defaults: C, definition: D): GenericModelStaticData {
    return { defaults, definition: definition as any }
}

export function createModels<T extends GenericModelStaticDataMap>(models: T): GenericModelStaticDataMap {
    return models
}

export function enumToChoiceOptions(enumType: Record<number | string, string | number>, sort = true) {
    if (sort) {
        return Object.fromEntries(getEnumEntries(enumType).map(([k, v]) => tuple(identifierToLabel(k), v)).sort(([a], [b]) => a.localeCompare(b)))

    } else {
        return Object.fromEntries(getEnumEntries(enumType).map(([k, v]) => tuple(identifierToLabel(k), v)))
    }
}

export function enumToClothingOptions(enumType: Record<number | string, string | number>) {
    return getEnumKeys(enumType).map(s => identifierToLabel(s))
}
