import { createSlice, configureStore, PayloadAction } from '@reduxjs/toolkit'
import { ReactNode, useCallback } from 'react'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { GenericModelCustomization, GenericModelCustomizationOverrides, GenericModelPreset, MODELS, ModelType, safeMergeCustomization } from './models'
import { deserializeJson, generateID, safeMerge } from './utils'

const DEPRECATED_CUSTOMIZATION_STORAGE_KEY = 'gena-customization'

const CUSTOMIZER_STORAGE_KEY = 'hsg-customizer-state'

const PRESET_UNDO_LIMIT = 50

interface CustomizerPresetListState {
    knownModels: Partial<Record<ModelType, GenericModelPreset[]>>
    unknownModels: Partial<Record<string, GenericModelPreset[]>>
}

interface TimeTraveling<T> {
    next: { value: T, reason: string }[]
    current: T
    previous: { value: T, reason: string }[]
}

export interface ModelListItem {
    type: ModelType
    name: string
    fileName: string
    storageKey: string
}

interface ModelListState {
    models: ModelListItem[]
}

type PresetState = TimeTraveling<GenericModelPreset>

export interface BackdropListItem {
    id: string
    fileName: string
    iconStorageKey: string
    fullStorageKey: string
}

interface BackdropState {
    color: string | null
    imageID: string | null
    backdropImagesList: BackdropListItem[]
}

export type VoreType = 'stomach' | 'womb' | 'ass' | 'dick' | 'ball'

export type WeightGainBodyPart = 'fat' | 'breast' | 'thigh' | 'dick' | 'ball'

export interface VoreSettings {
    enableSim: boolean
    weightLossRates: Record<WeightGainBodyPart, number>
    weightGainScales: Record<WeightGainBodyPart, number>
    types: Record<VoreType, {
        scale: number
        deathRate: number
        digestRate: number
        weightGainRates: Record<WeightGainBodyPart, number>
        target: VoreType | 'none'
    }>
}

export interface VoreSimulation {
    prey: {
        id: string
        size: number
        health: number
        location: VoreType
    }[]
    weights: Record<WeightGainBodyPart, number>
    presetOverrides: GenericModelCustomizationOverrides
}

interface VoreState {
    settings: VoreSettings
    simulation: VoreSimulation
}

interface VTubeStudioState {
    authToken: string | null
    port: number
    enableTracking: boolean
    presetOverrides: GenericModelCustomizationOverrides
}

interface SettingsState {
    tab: CustomizerTab
    hideUI: boolean
    enableDebug: boolean
    enableVore: boolean
}

interface PatreonState {
    patreonKey: string | null
    patreonKeyExpiration: number
}

export enum CustomizerTab {
    None = -1,

    Model = 0,
    Presets = 1,
    Import = 2,
    Export = 3,

    Other = 10,
    Motions = 11,
    Expressions = 12,
    Definition = 13,

    Backdrop = 20,
    Vore = 21,
    VTubeStudio = 22,

    Settings = 30,
    Help = 31,

    Custom = 100,
}

interface CustomizerState {
    modelList: ModelListState
    presetLists: CustomizerPresetListState
    preset: PresetState
    backdrop: BackdropState
    vore: VoreState
    vtubeStudio: VTubeStudioState
    patreon: PatreonState
    settings: SettingsState
}

const getStateFromLocalStorage = () => {
    const storedState = deserializeJson<CustomizerState>(localStorage.getItem(CUSTOMIZER_STORAGE_KEY) ?? 'null')
    let state: CustomizerState = safeMerge<CustomizerState>({
        modelList: {
            models: [],
        },
        presetLists: {
            knownModels: {
                ...storedState?.presetLists?.knownModels,
            },
            unknownModels: {
                ...storedState?.presetLists?.unknownModels,
            },
        },
        preset: {
            previous: [],
            current: {
                id: generateID(),
                name: '',
                fileName: '',
                updated: Date.now(),
                ...MODELS.unknown.defaults,
                ...storedState?.preset?.current,
            },
            next: [],
        },
        backdrop: {
            backdropImagesList: [],
            color: null,
            imageID: null,
            ...storedState?.backdrop,
        },
        vore: {
            settings: {
                enableSim: false,
                weightLossRates: {
                    fat: 0.02,
                    breast: 0.02,
                    thigh: 0.02,
                    dick: 0.02,
                    ball: 0.02,
                },
                weightGainScales: {
                    fat: 1,
                    breast: 1,
                    thigh: 1,
                    dick: 1,
                    ball: 1,
                },
                types: {
                    stomach: {
                        deathRate: 0.2,
                        digestRate: 0.1,
                        scale: 0.2,
                        weightGainRates: {
                            fat: 0.5,
                            breast: 0.5,
                            thigh: 0.3,
                            dick: 0.2,
                            ball: 0.2,
                        },
                        target: 'none',
                    },
                    womb: {
                        deathRate: 0.2,
                        digestRate: 0.1,
                        scale: 0.2,
                        weightGainRates: {
                            fat: 0,
                            breast: 0,
                            thigh: 0,
                            dick: 0,
                            ball: 0,
                        },
                        target: 'none',
                    },
                    ass: {
                        deathRate: 0.2,
                        digestRate: 0.1,
                        scale: 0.2,
                        weightGainRates: {
                            fat: 0.5,
                            breast: 0.3,
                            thigh: 0.5,
                            dick: 0.2,
                            ball: 0.2,
                        },
                        target: 'stomach',
                    },
                    dick: {
                        deathRate: 0.2,
                        digestRate: 0.1,
                        scale: 1,
                        weightGainRates: {
                            fat: 0,
                            breast: 0,
                            thigh: 0,
                            dick: 0,
                            ball: 0,
                        },
                        target: 'ball',
                    },
                    ball: {
                        deathRate: 0.2,
                        digestRate: 0.1,
                        scale: 1,
                        weightGainRates: {
                            fat: 0,
                            breast: 0,
                            thigh: 0,
                            dick: 0,
                            ball: 0,
                        },
                        target: 'none',
                    },
                }
            },
            simulation: {
                prey: [],
                weights: {
                    fat: 0,
                    breast: 0,
                    thigh: 0,
                    dick: 0,
                    ball: 0,
                },
                presetOverrides: {},
            },
        },
        vtubeStudio: {
            authToken: storedState?.vtubeStudio?.authToken ?? null,
            port: 8001,
            enableTracking: false,
            presetOverrides: {},
        },
        patreon: {
            patreonKey: storedState?.patreon?.patreonKey ?? null,
            patreonKeyExpiration: -1,
        },
        settings: {
            tab: CustomizerTab.Model,
            hideUI: false,
            enableDebug: false,
            enableVore: false,
        },
    }, storedState)

    // Handle former autosaved customization
    const deprecatedCustomizationJson = localStorage.getItem(DEPRECATED_CUSTOMIZATION_STORAGE_KEY)
    if (deprecatedCustomizationJson) {
        const storedCustomization = deserializeJson(deprecatedCustomizationJson)
        if (storedCustomization) {
            const updatedCustomization = safeMergeCustomization(MODELS[ModelType.GenaTypeI_HSGBaseModelR1_P_V1]!.defaults, storedCustomization) as GenericModelCustomization
            state.preset.current = {
                ...updatedCustomization,
                id: generateID(),
                name: '',
                fileName: 'HSG Base Model R1 P V1.model3.json', // Existing customizations are always Gena Type 1 V1
                updated: Date.now(),
            }
            localStorage.removeItem(DEPRECATED_CUSTOMIZATION_STORAGE_KEY)
        }
    }

    // Fix possibly outdated objects
    state.preset.previous = []
    state.preset.next = []
    state.preset.current = {
        ...MODELS.unknown.defaults,
        ...state.preset.current,
    }

    // Reset defaults
    state.settings.tab = CustomizerTab.None
    state.settings.hideUI = false
    state.vore.simulation.presetOverrides = {}
    state.vtubeStudio.presetOverrides = {}
    return state
}

const initialState = getStateFromLocalStorage()

const modelListSlice = createSlice({
    name: 'modelList',
    initialState: initialState.modelList,
    reducers: {
        upsertModel: (state, { payload: item }: PayloadAction<ModelListItem>) => {
            const existingIndex = state.models.findIndex(m => m.type === item.type && m.name === item.name && m.fileName === item.fileName)
            if (existingIndex >= 0) {
                state.models[existingIndex] = item
            } else {
                state.models.push(item)
            }
        },
        removeModel: (state, { payload: item }: PayloadAction<ModelListItem>) => {
            const existingIndex = state.models.findIndex(m => m.type === item.type && m.name === item.name && m.fileName === item.fileName)
            if (existingIndex >= 0) {
                state.models.splice(existingIndex, 1)
            }
        },
    },
})

const presetListsSlice = createSlice({
    name: 'presetLists',
    initialState: initialState.presetLists,
    reducers: {
        upsertPreset: (state, { payload: preset }: PayloadAction<GenericModelPreset>) => {
            const type = preset.type as ModelType
            if (type === ModelType.Unknown) {
                if (!state.unknownModels[preset.fileName]) {
                    state.unknownModels[preset.fileName] = [preset]
                } else {
                    const index = state.unknownModels[preset.fileName]!.findIndex(p => p.id === preset.id)
                    if (index >= 0) {
                        state.unknownModels[preset.fileName]![index] = preset
                    } else {
                        state.unknownModels[preset.fileName]!.push(preset)
                    }
                }
            } else {
                if (!state.knownModels[type]) {
                    state.knownModels[type] = [preset]
                } else {
                    const index = state.knownModels[type]!.findIndex(p => p.id === preset.id)
                    if (index >= 0) {
                        state.knownModels[type]![index] = preset
                    } else {
                        state.knownModels[type]!.push(preset)
                    }
                }
            }
        },
        removePreset: (state, { payload: preset }: PayloadAction<GenericModelPreset>) => {
            const type = preset.type as ModelType
            if (type === ModelType.Unknown) {
                if (state.unknownModels[preset.fileName]) {
                    const index = state.unknownModels[preset.fileName]!.findIndex(p => p.id === preset.id)
                    if (index >= 0) state.unknownModels[preset.fileName]!.splice(index, 1)
                }
            } else {
                if (state.knownModels[type]) {
                    const index = state.knownModels[type]!.findIndex(p => p.id === preset.id)
                    if (index >= 0) state.knownModels[type]!.splice(index, 1)
                }
            }
        }
    },
})

type PresetStateCurrent = Exclude<PresetState['current'], null>

function createPresetKeyValueReducer<K extends keyof PresetStateCurrent>(stateKey: K) {
    const reducer = (state: PresetState, { payload: { key, value, reason } }: PayloadAction<{ key: keyof PresetStateCurrent[K], value: PresetStateCurrent[K][keyof PresetStateCurrent[K]], reason: string | null }>) => {
        if (reason !== null) {
            state.previous.push({ value: { ...state.current }, reason })
            while (state.previous.length > PRESET_UNDO_LIMIT) state.previous.shift()
            state.current[stateKey][key] = value
            state.next.length = 0
        } else {
            state.current[stateKey][key] = value
        }
        state.current.updated = Date.now()
    }
    return reducer
}

const presetSlice = createSlice({
    name: 'preset',
    initialState: initialState.preset,
    reducers: {
        updateColor: createPresetKeyValueReducer('colors'),
        updateLink: createPresetKeyValueReducer('links'),
        updateToggle: createPresetKeyValueReducer('toggles'),
        updateChoice: createPresetKeyValueReducer('choices'),
        updateClothing: createPresetKeyValueReducer('clothing'),
        updateSlider: createPresetKeyValueReducer('sliders'),
        updateUnmappedArtMeshColor: createPresetKeyValueReducer('unmappedArtMeshColors'),
        updateUnmappedParameterValue: createPresetKeyValueReducer('unmappedParameterValues'),
        updateDecal: createPresetKeyValueReducer('decals'),
        addActiveMotion: (state, { payload: motion }: PayloadAction<string>) => {
            const index = state.current.motions.indexOf(motion)
            if (index < 0) {
                state.current.motions.push(motion)
            }
        },
        removeActiveMotion: (state, { payload: motion }: PayloadAction<string>) => {
            const index = state.current.motions.indexOf(motion)
            if (index >= 0) {
                state.current.motions.splice(index, 1)
            }
        },
        addActiveExpression: (state, { payload: expression }: PayloadAction<string>) => {
            const index = state.current.expressions.indexOf(expression)
            if (index < 0) {
                state.current.expressions.push(expression)
            }
        },
        removeActiveExpression: (state, { payload: expression }: PayloadAction<string>) => {
            const index = state.current.expressions.indexOf(expression)
            if (index >= 0) {
                state.current.expressions.splice(index, 1)
            }
        },
        renamePreset: (state, { payload: { name } }: PayloadAction<{ name: string }>) => {
            state.current.name = name
        },
        loadPreset: (state, { payload: { value, reason } }: PayloadAction<{ value: GenericModelPreset, reason: string }>) => {
            if (state.current && value.type === state.current.type && (value.type !== ModelType.Unknown || value.fileName === state.current.fileName)) {
                state.previous.push({ value: { ...state.current }, reason })
                while (state.previous.length > PRESET_UNDO_LIMIT) state.previous.shift()
            } else {
                state.previous.length = 0
            }
            state.current = value
            state.next.length = 0
        },
        undoPresetChange: state => {
            if (state.previous.length) {
                const { value, reason } = state.previous.pop()!
                if (state.current) state.next.push({ value: { ...state.current }, reason })
                state.current = value
            }
        },
        redoPresetChange: state => {
            if (state.next.length) {
                const { value, reason } = state.next.pop()!
                if (state.current) state.previous.push({ value: { ...state.current }, reason })
                state.current = value
            }
        },
    }
})

const backdropSlice = createSlice({
    name: 'backdrop',
    initialState: initialState.backdrop,
    reducers: {
        setBackdropColor: (state, { payload: { value } }: PayloadAction<{ value: string | null }>) => {
            state.color = value
        },
        setBackdropImageID: (state, { payload: { value } }: PayloadAction<{ value: string | null }>) => {
            state.imageID = value
        },
        addBackdropImage: (state, { payload: item }: PayloadAction<BackdropListItem>) => {
            const existingIndex = state.backdropImagesList.findIndex(m => m.id === item.id)
            if (existingIndex >= 0) {
                state.backdropImagesList[existingIndex] = item
            } else {
                state.backdropImagesList.push(item)
            }
        },
        removeBackdropImage: (state, { payload: item }: PayloadAction<BackdropListItem>) => {
            const existingIndex = state.backdropImagesList.findIndex(m => m.id === item.id)
            if (existingIndex >= 0) {
                state.backdropImagesList.splice(existingIndex, 1)
            }
        },
    }
})

const voreSlice = createSlice({
    name: 'vore',
    initialState: initialState.vore,
    reducers: {
        setVoreSimActive: (state, { payload: active }: PayloadAction<boolean>) => {
            state.settings.enableSim = active
        },
        setVoreWeightLossRate: (state, { payload: { part, value } }: PayloadAction<{ part: WeightGainBodyPart, value: number }>) => {
            state.settings.weightLossRates[part] = value
        },
        setVoreWeightGainScale: (state, { payload: { part, value } }: PayloadAction<{ part: WeightGainBodyPart, value: number }>) => {
            state.settings.weightGainScales[part] = value
        },
        setVoreTypeScale: (state, { payload: { type, value } }: PayloadAction<{ type: VoreType, value: number }>) => {
            state.settings.types[type].scale = value
        },
        setVoreTypeDeathRate: (state, { payload: { type, value } }: PayloadAction<{ type: VoreType, value: number }>) => {
            state.settings.types[type].deathRate = value
        },
        setVoreTypeDigestRate: (state, { payload: { type, value } }: PayloadAction<{ type: VoreType, value: number }>) => {
            state.settings.types[type].digestRate = value
        },
        setVoreTypeTarget: (state, { payload: { type, value } }: PayloadAction<{ type: VoreType, value: VoreType | 'none' }>) => {
            state.settings.types[type].target = value
        },
        setVoreTypeWeightGainRate: (state, { payload: { type, part, value } }: PayloadAction<{ type: VoreType, part: WeightGainBodyPart, value: number }>) => {
            state.settings.types[type].weightGainRates[part] = value
        },
        updateVoreSimulation: (state, { payload: simulation }: PayloadAction<VoreState['simulation']>) => {
            state.simulation = simulation
        },
        addVoreSimulationPrey: (state, { payload: prey }: PayloadAction<VoreState['simulation']['prey'][number]>) => {
            state.simulation.prey.push(prey)
        },
    }
})

const vtubeStudioSlice = createSlice({
    name: 'vtubeStudio',
    initialState: initialState.vtubeStudio,
    reducers: {
        setVTubeStudioAuthToken: (state, { payload: authToken }: PayloadAction<string | null>) => {
            state.authToken = authToken
        },
        setVTubeStudioPort: (state, { payload: port }: PayloadAction<number>) => {
            if (!Number.isNaN(port))
                state.port = port
        },
        setVTubeStudioTrackingEnabled: (state, { payload: enableTracking }: PayloadAction<boolean>) => {
            state.enableTracking = enableTracking
        },
        updateVTubeStudioTracking: (state, { payload: presetOverrides }: PayloadAction<VTubeStudioState['presetOverrides']>) => {
            state.presetOverrides = presetOverrides
        },
    }
})

const patreonSlice = createSlice({
    name: 'patreon',
    initialState: initialState.patreon,
    reducers: {
        setPatreonKey: (state, { payload: { patreonKey, patreonKeyExpiration } }: PayloadAction<{ patreonKey: string | null, patreonKeyExpiration: number }>) => {
            state.patreonKey = patreonKey
            state.patreonKeyExpiration = patreonKeyExpiration
        }
    }
})

const settingsSlice = createSlice({
    name: 'settings',
    initialState: initialState.settings,
    reducers: {
        setAppSetting: <K extends keyof SettingsState,>(state: SettingsState, { payload: { key, value } }: PayloadAction<{ key: K, value: SettingsState[K] }>) => {
            state[key] = value
        }
    }
})

const actions = {
    ...modelListSlice.actions,
    ...presetListsSlice.actions,
    ...presetSlice.actions,
    ...backdropSlice.actions,
    ...voreSlice.actions,
    ...vtubeStudioSlice.actions,
    ...patreonSlice.actions,
    ...settingsSlice.actions,
}

const store = configureStore({
    reducer: {
        modelList: modelListSlice.reducer,
        presetLists: presetListsSlice.reducer,
        preset: presetSlice.reducer,
        backdrop: backdropSlice.reducer,
        vore: voreSlice.reducer,
        vtubeStudio: vtubeStudioSlice.reducer,
        patreon: patreonSlice.reducer,
        settings: settingsSlice.reducer,
    },
})

export function StoreProvider({ children }: { children: ReactNode }) {
    return <Provider store={store}>
        {children}
    </Provider>
}

export const useStoreDispatch = () => {
    const dispatch = useDispatch()
    const storeDispatch = useCallback((callback: (actionSet: typeof actions) => ReturnType<(typeof actions)[keyof typeof actions]>) => dispatch(callback(actions)), [dispatch])
    return storeDispatch
}

export const useStoreSelector: <T>(selector: (state: ReturnType<typeof store.getState>) => T) => T = useSelector

export const storeDispatch = (callback: (actionSet: typeof actions) => ReturnType<(typeof actions)[keyof typeof actions]>) => store.dispatch(callback(actions))

export const storeSelector: <T>(selector: (state: ReturnType<typeof store.getState>) => T) => T = (selector) => selector(store.getState())

store.subscribe(() => {
    localStorage.setItem(CUSTOMIZER_STORAGE_KEY, JSON.stringify(store.getState()))
})
