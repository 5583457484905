import { Button } from '@mui/material'
import { memo, useCallback } from 'react'
import { BodyPart, BodyField } from '../BodyField'
import { useAppSettersContext, useModel, useModelDefinition } from '../context'
import { ImageButton } from '../ModelButton'
import { ModelType } from '../models'

export const ModelStatusPart = memo(function ModelStatusPart() {
    const model = useModel()
    const modelDefinition = useModelDefinition()
    const { setModel } = useAppSettersContext()

    const unloadModel = useCallback(() => {
        setModel(null)
    }, [setModel])

    return <BodyPart label='Model'>
        <BodyField label=''>
            <ImageButton size='large' active label={modelDefinition.type !== ModelType.Unknown ? modelDefinition.type : model.modelName} src={`/ModelIcons/${modelDefinition.type}.png`} onClick={() => { }} />
        </BodyField>
        <BodyField label=''>
            <Button onClick={unloadModel}>Unload Model</Button>
        </BodyField>
    </BodyPart>
})
