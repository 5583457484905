import JSZip from 'jszip'
import { DropzoneArea } from 'material-ui-dropzone'
import { memo, useCallback } from 'react'
import { useLatestCallback, useRefresh } from './hooks'
import { deserializeJson, loadImage } from './utils'

interface FileDropZoneResult {
    buffer: ArrayBuffer
    fileName: string
}

export interface JsonFileDropZoneResult extends FileDropZoneResult {
    data: any
    json: string
}

export interface ZipFileDropZoneResult extends FileDropZoneResult {
    zip: JSZip
}

export interface ImageFileDropZoneResult extends FileDropZoneResult {
    image: HTMLImageElement
}

export interface RawFileDropZoneResult extends FileDropZoneResult {

}

interface FileDropZoneArgs<T extends FileDropZoneResult> {
    fileDesc: string
    compact?: boolean
    onBeforeUpload?: (fileName: string) => void
    onUploadFailed?: (reason: any, fileName: string) => void
    onUpload: (file: T) => void | Promise<void>
}

export const JsonFileDropZone = memo(function JsonFileDropZone({ onUpload, ...props }: FileDropZoneArgs<JsonFileDropZoneResult>) {
    const doUpload = useLatestCallback(onUpload)

    const wrapOnUpload = useCallback(({ buffer, fileName }: { buffer: ArrayBuffer, fileName: string }) => {
        const json = new TextDecoder('utf-8').decode(buffer)
        const data = deserializeJson(json)
        if (!data) throw new Error('The uploaded file was not a valid JSON file')
        return doUpload({ data, json, buffer, fileName })
    }, [doUpload])

    return <RawFileDropZone {...props} onUpload={wrapOnUpload} />
})

export const ZipFileDropZone = memo(function ZipFileDropZone({ onUpload, ...props }: FileDropZoneArgs<ZipFileDropZoneResult>) {
    const doUpload = useLatestCallback(onUpload)

    const wrapOnUpload = useCallback(({ buffer, fileName }: { buffer: ArrayBuffer, fileName: string }) => JSZip.loadAsync(buffer).then(zip => doUpload({ zip, buffer, fileName })), [doUpload])

    return <RawFileDropZone {...props} onUpload={wrapOnUpload} />
})

export const ImageFileDropZone = memo(function ImageFileDropZone({ onUpload, ...props }: FileDropZoneArgs<ImageFileDropZoneResult>) {
    const doUpload = useLatestCallback(onUpload)

    const wrapOnUpload = useCallback(({ buffer, fileName }: { buffer: ArrayBuffer, fileName: string }) => {
        const blob = new Blob([buffer])
        const uri = URL.createObjectURL(blob)
        return loadImage(uri).then(image => doUpload({ buffer, fileName, image }))
    }, [doUpload])

    return <RawFileDropZone {...props} onUpload={wrapOnUpload} />
})

export const RawFileDropZone = memo(function FileDropZone({ fileDesc, onBeforeUpload, onUpload, onUploadFailed, compact }: FileDropZoneArgs<RawFileDropZoneResult>) {
    const [refresh, key] = useRefresh()
    const doBeforeUpload = useLatestCallback(onBeforeUpload)
    const doUpload = useLatestCallback(onUpload)
    const doUploadFailed = useLatestCallback(onUploadFailed)

    const onAddFile = useCallback((file: File) => {
        const fileName = file.name
        doBeforeUpload(fileName)
        file.arrayBuffer().then(buffer => doUpload({ buffer, fileName })).catch(e => doUploadFailed(e, fileName))
        refresh()
    }, [doBeforeUpload, doUpload, doUploadFailed, refresh])

    return <DropzoneArea key={key} dropzoneClass={`file-drop-zone-${compact ? 'compact' : 'normal'}`} maxFileSize={1024 * 1024 * 1024} clearOnUnmount dropzoneText={`Drag and drop ${fileDesc} here or click to upload`} filesLimit={1} onChange={files => files.length && onAddFile(files[0])} />
})
