import { memo, useMemo, useCallback, useRef, useState, useEffect } from 'react'
import ColorConvert from 'color-convert'
import { useModel, useModelDefinition } from './context'
import { Live2D, Live2DDecal } from './live2D'
import { GenericModelCustomizationOverrides, ModelType } from './models'
import { BellyType } from './models/gena'
import { useStoreDispatch, useStoreSelector } from './store'
import { Box, Stack } from '@mui/material'
import { Accessibility, CameraAlt, FilterCenterFocus, Visibility, VisibilityOff } from '@mui/icons-material'
import { generateFileTimestamp, tuple } from './utils'
import { fileStore } from './fileStore'
import { useVoreSimulation } from './parts/VorePart'
import { useMotions } from './parts/PlayMotionsPart'
import { useExpressions } from './parts/ToggleExpressionsPart'
import { useVTubeStudio } from './parts/VTubeStudioSyncPart'
import { ShortcutIconButton, useKeyboardShortcuts } from './shortcuts'
import { useErrorHandler } from './error'

export function useComputedModelParams() {
    const model = useModel()
    const modelDefinition = useModelDefinition()
    const colors = useStoreSelector(s => s.preset.current.colors)
    const links = useStoreSelector(s => s.preset.current.links)
    const choices = useStoreSelector(s => s.preset.current.choices)
    const toggles = useStoreSelector(s => s.preset.current.toggles)
    const clothing = useStoreSelector(s => s.preset.current.clothing)
    const sliders = useStoreSelector(s => s.preset.current.sliders)
    const unmappedArtMeshColors = useStoreSelector(s => s.preset.current.unmappedArtMeshColors)
    const unmappedParameterValues = useStoreSelector(s => s.preset.current.unmappedParameterValues)
    const setDecals = useStoreSelector(s => s.preset.current.decals)

    const voreSimEnabled = useStoreSelector(s => s.vore.settings.enableSim)
    const voreOverrides = useStoreSelector(s => s.vore.simulation.presetOverrides)

    const vtsTrackingEnabled = useStoreSelector(s => s.vtubeStudio.enableTracking)
    const vtsOverrides = useStoreSelector(s => s.vtubeStudio.presetOverrides)

    const { api } = useVTubeStudio()

    const allOverrides = useMemo(() => {
        const overrides: GenericModelCustomizationOverrides = {
            ...(voreSimEnabled ? voreOverrides : {}),
            ...(vtsTrackingEnabled && !!api ? vtsOverrides : {}),
        }
        return overrides
    }, [api, voreOverrides, voreSimEnabled, vtsOverrides, vtsTrackingEnabled])

    useMotions()
    useExpressions()
    useVoreSimulation()

    const multiplyColors = useMemo(() => {
        return {
            ...Object.fromEntries((Object.entries({ ...modelDefinition.staticArtMeshColors, ...unmappedArtMeshColors, ...(allOverrides.unmappedArtMeshColors ?? {}) })).flatMap(([key, value]) => {
                if (!value) return []
                const [r, g, b] = ColorConvert.hex.rgb(value[0])
                return [tuple(key, { r: r / 255, g: g / 255, b: b / 255 })]
            })),
            ...(colors && links ? Object.fromEntries((Object.entries(colors)).flatMap(([key, [color]]) => {
                for (const [, link] of Object.entries(modelDefinition.links).filter(([linkKey]) => links[linkKey])) {
                    const linkedColor = link.colors[key]
                    if (linkedColor) color = colors[linkedColor][0]
                }
                const [r, g, b] = ColorConvert.hex.rgb(color)
                return modelDefinition.colorArtMeshes[key]?.map(mesh => tuple(mesh, { r: r / 255, g: g / 255, b: b / 255 })) ?? []
            })) : {}),
        }
    }, [allOverrides.unmappedArtMeshColors, colors, links, modelDefinition.colorArtMeshes, modelDefinition.links, modelDefinition.staticArtMeshColors, unmappedArtMeshColors])

    const screenColors = useMemo(() => {
        return {
            ...Object.fromEntries((Object.entries({ ...modelDefinition.staticArtMeshColors, ...unmappedArtMeshColors, ...unmappedArtMeshColors, ...(allOverrides.unmappedArtMeshColors ?? {}) })).flatMap(([key, value]) => {
                if (!value) return []
                const [r, g, b] = ColorConvert.hex.rgb(value[1])
                return [tuple(key, { r: r / 255, g: g / 255, b: b / 255 })]
            })),
            ...(colors && links ? Object.fromEntries((Object.entries(colors)).flatMap(([key, [_, color]]) => {
                for (const [, link] of Object.entries(modelDefinition.links ?? {}).filter(([linkKey]) => links[linkKey])) {
                    const linkedColor = link.colors[key]
                    if (linkedColor) color = colors[linkedColor][1]
                }
                const [r, g, b] = ColorConvert.hex.rgb(color)
                return modelDefinition.colorArtMeshes[key]?.map(mesh => tuple(mesh, { r: r / 255, g: g / 255, b: b / 255 })) ?? []
            })) : {}),
        }
    }, [modelDefinition.staticArtMeshColors, modelDefinition.colorArtMeshes, modelDefinition.links, unmappedArtMeshColors, allOverrides.unmappedArtMeshColors, colors, links])

    const parameterValues = useMemo(() => {
        return {
            ...Object.fromEntries((Object.entries({ ...modelDefinition.staticParameterValues, ...unmappedParameterValues, ...(allOverrides.unmappedParameterValues ?? {}) })).flatMap(([key, value]) => {
                return value !== undefined ? [tuple(key, value)] : []
            })),
            ...(choices ? Object.fromEntries(Object.entries(choices).concat(Object.entries(allOverrides.choices ?? {})).flatMap(([key, value]) => {
                return modelDefinition.choiceParams[key]?.map(p => tuple(p, value)) ?? []
            })) : {}),
            ...(clothing ? Object.fromEntries(Object.entries(clothing).concat(Object.entries(allOverrides.clothing ?? {})).flatMap(([key, value]) => {
                const valueCount = modelDefinition.clothingOptions[key]?.length ?? 0
                const allParams = modelDefinition.clothingParams[key] ?? {}
                const activeParams = allParams[value]
                return new Array(valueCount).fill(0).flatMap((_, i) => allParams[i].map(p => {
                    const def = model.parameterDefinitions[p]
                    const max = def?.maxValue ?? 1
                    const min = def?.minValue ?? 0
                    return tuple(p, activeParams.includes(p) ? max : min)
                }))
            })) : {}),
            ...(sliders && choices ? Object.fromEntries(Object.entries(sliders).concat(Object.entries(allOverrides.sliders ?? {})).flatMap(([key, value]) => {
                if (modelDefinition.type === ModelType.GenaTypeI_HSGBaseModelR1_P_V1 && key === 'bellySize') {
                    const bellyType = allOverrides.choices?.bellyType ?? choices.bellyType
                    return [
                        ['size_Preg', bellyType === BellyType.Pregnant ? value : 0],
                        ['size_Preg2', bellyType === BellyType.Stuffed ? value : 0],
                        ['size_Preg3', bellyType === BellyType.Vore ? value : 0],
                        ['size_Preg4', bellyType === BellyType.Eggs ? value : 0],
                    ]
                }
                return modelDefinition.sliderParams[key]?.map(p => tuple(p, value)) ?? []
            })) : {}),
            ...(toggles ? Object.fromEntries(Object.entries(toggles).concat(Object.entries(allOverrides.toggles ?? {})).flatMap(([key, value]) => {
                return modelDefinition.toggleParams[key]?.map(p => {
                    const def = model.parameterDefinitions[p]
                    const max = def?.maxValue ?? 1
                    const min = def?.minValue ?? 0
                    return tuple(p, value ? max : min)
                }) ?? []
            })) : {}),
        }
    }, [allOverrides.choices, allOverrides.clothing, allOverrides.sliders, allOverrides.toggles, allOverrides.unmappedParameterValues, choices, clothing, model.parameterDefinitions, modelDefinition.choiceParams, modelDefinition.clothingOptions, modelDefinition.clothingParams, modelDefinition.sliderParams, modelDefinition.staticParameterValues, modelDefinition.toggleParams, modelDefinition.type, sliders, toggles, unmappedParameterValues])

    const decals = useMemo(() => {
        return {
            ...Object.fromEntries(Object.entries(setDecals).flatMap(([key, value]) => {
                const def = modelDefinition.decals[key]
                if (!def || !value) return []
                const img = new Image()
                img.src = value
                const decal: Live2DDecal = {
                    textureIndex: def.textureIndex,
                    x: def.x,
                    y: def.y,
                    data: img,
                }
                return [tuple(key, decal)]
            })),
        }
    }, [modelDefinition.decals, setDecals])

    return { multiplyColors, screenColors, parameterValues, decals }
}

export const ModelWrapper = memo(function ModelWrapper() {
    const hideUI = useStoreSelector(s => s.settings.hideUI)
    const dispatch = useStoreDispatch()
    const model = useModel()
    const modelDefinition = useModelDefinition()
    const addError = useErrorHandler()
    const requestScreenshotRef = useRef(false)

    const backdropColor = useStoreSelector(s => s.backdrop.color)
    const [backdropImage, setBackdropImage] = useState<HTMLImageElement | null>(null)
    const backdropStorageKey = useStoreSelector(s => s.backdrop.backdropImagesList.find(i => i.id === s.backdrop.imageID)?.fullStorageKey)

    useEffect(() => {
        setBackdropImage(null)
        if (backdropStorageKey) {
            fileStore.retrieveImage(backdropStorageKey).then(img => setBackdropImage(img))
        }
    }, [backdropStorageKey])

    const onError = useCallback((err: any) => {
        addError(err)
    }, [addError])

    const onScreenShotTaken = useCallback((blob: Blob) => {
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `Screenshot_${generateFileTimestamp()}.png`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)

        setTimeout(() => {
            URL.revokeObjectURL(url)
        }, 60 * 1000)
    }, [])

    const { multiplyColors, screenColors, parameterValues, decals } = useComputedModelParams()

    const position = { x: 1 / 3, y: -0.2 }
    const scale = 1

    const shortcuts = useKeyboardShortcuts('View Model', useMemo(() => ({
        resetPose: { name: 'Reset Pose', key: 'r', callback: () => model.userModel.resetPose() },
        recenter: { name: 'Recenter Camera', key: 'c', callback: () => model.userModel.resetCamera() },
        screenshot: { name: 'Take Screenshot', key: 'p', callback: () => requestScreenshotRef.current = true },
        toggleUI: { name: hideUI ? 'Show UI' : 'Hide UI', key: '\\', callback: () => dispatch(a => a.setAppSetting({ key: 'hideUI', value: !hideUI })) },
    }), [dispatch, hideUI, model.userModel]))

    return <>
        <Live2D model={model} onError={onError} position={position} scale={scale} multiplyColors={multiplyColors} screenColors={screenColors} parameterValues={parameterValues} parameterSmoothing={modelDefinition.smoothParams} decals={decals} requestScreenShot={requestScreenshotRef} onScreenShotTaken={onScreenShotTaken} backgroundColor={backdropColor} backgroundImage={backdropImage} />
        <div style={{ position: 'sticky', right: '0', top: '0', overflow: 'visible' }}>
            <Box sx={{ position: 'absolute', right: 0, top: 96, zIndex: 100, opacity: hideUI ? 0 : 1, transition: 'opacity .2s', ":hover": { opacity: 1 } }}>
                <Stack direction='row' p={1} spacing={1}>
                    <ShortcutIconButton shortcut={shortcuts.resetPose} icon={<Accessibility />} />
                    <ShortcutIconButton shortcut={shortcuts.recenter} icon={<FilterCenterFocus />} />
                    <ShortcutIconButton shortcut={shortcuts.screenshot} icon={<CameraAlt />} />
                    <ShortcutIconButton shortcut={shortcuts.toggleUI} icon={hideUI ? <VisibilityOff /> : <Visibility />} />
                </Stack>
            </Box>
        </div>
    </>
})
