import { BOA_MODEL } from './boa'
import { createModels, ModelType } from './common'
import { ELLIE_MODEL } from './ellie'
import { FLAME_MODEL } from './flame'
import { FLYNN_MODEL } from './flynn'
import { GENA_MODEL } from './gena'
import { IVY_MODEL } from './ivy'
import { LACE_MODEL } from './lace'
import { MAZE_MODEL } from './maze'
import { MIA_MODEL } from './mia'
import { PATH_BANDIT_MODEL } from './pathBandit'
import { SLIME_MODEL } from './slime'
import { SLIME_FAMILIAR_MODEL } from './slimeFamiliar'
import { UNKNOWN_MODEL } from './unknown'
export * from './common'

export const MODELS = createModels({
    [ModelType.GenaTypeI_HSGBaseModelR1_P_V1]: GENA_MODEL,
    [ModelType.Ellie_P_V7]: ELLIE_MODEL,
    [ModelType.Lace_P_V8]: LACE_MODEL,
    [ModelType.Flame_V3]: FLAME_MODEL,
    [ModelType.PathBandit_V10]: PATH_BANDIT_MODEL,
    [ModelType.Mia_P_V7]: MIA_MODEL,
    [ModelType.Ivy_P_V6]: IVY_MODEL,
    [ModelType.Boa_V8]: BOA_MODEL,
    [ModelType.Flynn_P_V5]: FLYNN_MODEL,
    [ModelType.Slime_Familiar]: SLIME_FAMILIAR_MODEL,
    [ModelType.Slime_V27]: SLIME_MODEL,
    [ModelType.Maze_A_V40]: MAZE_MODEL,
    [ModelType.Unknown]: UNKNOWN_MODEL,
})
