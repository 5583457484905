import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { StoreProvider } from './store'
import { ThemeProvider } from '@emotion/react'
import { createTheme, CssBaseline, Link } from '@mui/material'
import { Customizer } from './Customizer'
import { AnalyticsDashboard } from './AnalyticsDashboard'
import { ErrorProvider } from './error'

const theme = createTheme({
  palette: {
    mode: 'dark'
  }
})

export function App() {
  const url = new URL(window.location.href)
  switch (url.pathname) {
    case '/':
      return <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <ErrorProvider>
          <StoreProvider>
            <Customizer />
          </StoreProvider>
        </ErrorProvider>
      </ThemeProvider>
    case '/analytics':
      return <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <AnalyticsDashboard />
      </ThemeProvider>
    default:
      return <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <div>404: Invalid URL. <Link href='/'>Return to main page</Link>.</div>
      </ThemeProvider>
  }
}
