import { Typography, Stack, Backdrop, CircularProgress, Box, Link } from '@mui/material'
import JSZip from 'jszip'
import { memo, useState, useCallback, useEffect } from 'react'
import { BodyPart, BodyField, BodyTab } from '../BodyField'
import { useConfirm } from '../Confirm'
import { useAppSettersContext } from '../context'
import { fileStore } from '../fileStore'
import { loadLive2DModel } from '../live2d/live2DModel'
import { ImageButton, ImageButtonNew } from '../ModelButton'
import { MODELS, ModelType } from '../models'
import { CustomizerTab, ModelListItem, useStoreDispatch, useStoreSelector } from '../store'
import { generateID } from '../utils'
import { ZipFileDropZone, ZipFileDropZoneResult } from '../FileDropZone'
import { downloadPatreonModel, fetchPatreonModels, isLoggedInToPatreon, PatreonModelsResponse, PatreonSignInLink, PatreonSignOutLink, PATREON_ENABLED } from '../patreon'
import { downloadGumroadModel, fetchGumroadModels, GumroadModelsResponse, GUMROAD_ENABLED } from '../gumroad'
import { useErrorHandler } from '../error'

export const ModelListPart = memo(function ModelListPart() {
    const { setModel } = useAppSettersContext()
    const addError = useErrorHandler()
    const [loading, setLoading] = useState(false)
    const [downloading, setDownloading] = useState(false)
    const [downloadProgress, setDownloadProgress] = useState(0)
    const models = useStoreSelector(s => s.modelList.models)
    const preset = useStoreSelector(s => s.preset)
    const dispatch = useStoreDispatch()
    const { alert, prompt } = useConfirm()
    const [patreonModels, setPatreonModels] = useState<PatreonModelsResponse | null>(null)
    const [gumroadModels, setGumroadModels] = useState<GumroadModelsResponse | null>(null)

    const onBeforeUpload = useCallback((fileName: string) => {
        setLoading(true)
    }, [])

    const onUploadFailed = useCallback((reason: any, fileName: string) => {
        addError(reason)
        setLoading(false)
    }, [addError])

    const onUpload = useCallback(({ zip, buffer, fileName }: ZipFileDropZoneResult) => {
        let modelType = ModelType.Unknown
        let modelPath: string | null = null
        for (const { definition } of Object.values(MODELS)) {
            for (const possibleModelFile of definition.possibleModelFiles) {
                const possiblePath = Object.values(zip.files).map(f => f.name).find(n => n.endsWith(possibleModelFile)) ?? null
                if (possiblePath) {
                    modelType = definition.type
                    modelPath = possiblePath
                    break
                }
            }
        }
        if (modelType === ModelType.Unknown && !modelPath) {
            modelPath = Object.values(zip.files).find(f => f.name.endsWith('.model3.json'))?.name ?? null
        }

        if (!modelPath) {
            addError(new Error('Loading failed. The uploaded zip file did not appear to contain a Live2D model.'))
            setLoading(false)
            return
        }

        const modelZipFetcher = async (filePath: string) => {
            const file = zip.file(filePath)
            if (!file) throw new Error(`Failed to find ${filePath} in zip file`)
            const buffer = await file.async('arraybuffer')
            if (!buffer) throw new Error(`Failed to load data for ${filePath}`)
            return buffer
        }

        const modelZipSearcher = async (pattern: RegExp) => {
            return zip.file(pattern).map(f => f.name)
        }

        const modelFolder = modelPath.substring(0, modelPath.lastIndexOf('/'))
        const modelName = modelPath.substring(modelPath.lastIndexOf('/') + 1)

        loadLive2DModel(modelFolder, modelName, modelZipFetcher, modelZipSearcher)
            .then(live2DModel => {
                const model = models.find(m => m.type === modelType && m.name === live2DModel.modelName && m.fileName === fileName)
                if (!model) {
                    const storageKey = generateID()
                    fileStore.storeFile(storageKey, buffer).then(() => {
                        dispatch(a => a.upsertModel({ type: modelType, name: live2DModel.modelName, fileName, storageKey }))
                    }).catch(e => addError(e))
                }
                if (preset.current.type !== modelType || (modelType === ModelType.Unknown && preset.current.fileName !== fileName)) {
                    const newPreset = { ...MODELS[modelType].defaults, id: generateID(), name: '', fileName, updated: Date.now() }
                    setModel(live2DModel)
                    setLoading(false)
                    dispatch(a => a.loadPreset({ value: newPreset, reason: 'Load Model' }))
                } else {
                    setModel(live2DModel)
                    setLoading(false)
                }
                dispatch(a => a.setAppSetting({ key: 'tab', value: CustomizerTab.Model }))
            })
            .catch(e => {
                addError(e)
                setLoading(false)
            })
    }, [addError, dispatch, models, preset, setModel])

    const onLoadModel = useCallback((model: ModelListItem) => {
        setLoading(true)
        fileStore.retrieveFile(model.storageKey)
            .then(buffer => {
                if (buffer) {
                    JSZip.loadAsync(buffer)
                        .then(zip => {
                            onUpload({ zip, buffer, fileName: model.fileName })
                        })
                        .catch(e => {
                            addError(e)
                            setLoading(false)
                        })
                } else {
                    dispatch(a => a.removeModel(model))
                    alert('The load failed because the selected model is no longer available in the browser cache. It will be removed from the list.')
                    setLoading(false)
                }
            })
            .catch(e => {
                addError(e)
                setLoading(false)
            })
    }, [addError, alert, dispatch, onUpload])

    const onDeleteModel = useCallback((model: ModelListItem) => {
        dispatch(a => a.removeModel(model))
        fileStore.deleteFile(model.storageKey)
    }, [dispatch])

    const loadPatreonModel = useCallback(async (modelType: ModelType, fileName: string) => {
        const existingModel = models.find(m => m.type === modelType)
        if (existingModel) {
            onLoadModel(existingModel)
            return
        }
        try {
            setDownloading(true)
            setDownloadProgress(0)
            const buffer = await downloadPatreonModel(modelType, setDownloadProgress)
            setDownloading(false)
            setLoading(true)
            const zip = await JSZip.loadAsync(buffer)
            onUpload({ zip, buffer, fileName })
        } catch (e) {
            addError(e as Error)
            setDownloading(false)
            setLoading(false)
        }
    }, [addError, models, onLoadModel, onUpload])

    const loadGumroadModel = useCallback(async (modelType: ModelType) => {
        const existingModel = models.find(m => m.type === modelType)
        if (existingModel) {
            onLoadModel(existingModel)
            return
        }
        try {
            const gumroadLink = `https://kurotamaart.gumroad.com/l/${gumroadModels?.models.find(m => m.type === modelType)?.gumroadID}`
            const licenseKey = await prompt(<>Provide the Gumroad license key associated with your purchase of this model. You can find it in the receipt you received from Gumroad, or from <Link href='https://app.gumroad.com/library' target='_blank'>your Library in Gumroad</Link>, or on <Link href={gumroadLink} target='_blank'>the product's download page</Link>.<br /><br />If you have not purchased this model yet, <Link href={gumroadLink} target='_blank'>you can do so here</Link>.</>, { input: 'Gumroad License Key', title: 'Provide License Key' })
            if (!licenseKey) return
            setDownloading(true)
            setDownloadProgress(0)
            const { buffer, fileName } = await downloadGumroadModel(licenseKey, modelType, setDownloadProgress)
            setDownloading(false)
            setLoading(true)
            const zip = await JSZip.loadAsync(buffer)
            onUpload({ zip, buffer, fileName })
        } catch (e) {
            addError(e as Error)
            setDownloading(false)
            setLoading(false)
        }
    }, [addError, gumroadModels, models, onLoadModel, onUpload, prompt])

    const loadUnknownGumroadModel = useCallback(async () => {
        try {
            const gumroadLink = `https://kurotamaart.gumroad.com/`
            const licenseKey = await prompt(<>Provide the Gumroad license key associated with your purchase of a model. You can find it in the receipt you received from Gumroad, or from <Link href='https://app.gumroad.com/library' target='_blank'>your Library in Gumroad</Link>, or on the product's download page.<br /><br />If you have not purchased a model yet, <Link href={gumroadLink} target='_blank'>you can do so here</Link>.</>, { input: 'Gumroad License Key', title: 'Provide License Key' })
            if (!licenseKey) return
            setDownloading(true)
            setDownloadProgress(0)
            const { buffer, fileName } = await downloadGumroadModel(licenseKey, ModelType.Unknown, setDownloadProgress)
            setDownloading(false)
            setLoading(true)
            const zip = await JSZip.loadAsync(buffer)
            onUpload({ zip, buffer, fileName })
        } catch (e) {
            addError(e as Error)
            setDownloading(false)
            setLoading(false)
        }
    }, [addError, onUpload, prompt])

    useEffect(() => {
        if (!PATREON_ENABLED) return
        (async () => {
            const result = await fetchPatreonModels()
            if (result) setPatreonModels(result)
        })()
    }, [])

    useEffect(() => {
        if (!GUMROAD_ENABLED) return
        (async () => {
            const result = await fetchGumroadModels()
            setGumroadModels(result)
        })()
    }, [])

    return <>
        <BodyTab hidden={loading} side='right'>
            <BodyPart label='Models'>
                <BodyField label='Load Recent Model'>
                    <Stack direction='row' gap={1} flexWrap='wrap' pb={1}>
                        {models.map(m => <ImageButton key={m.storageKey} label={getCleanModelName(m)} title={m.fileName} src={`/ModelIcons/${m.type}.png`} onClick={() => onLoadModel(m)} onDelete={() => onDeleteModel(m)} />)}
                    </Stack>
                </BodyField>
                <BodyField label='Load New Model'>
                    <ZipFileDropZone fileDesc='model zip file' onBeforeUpload={onBeforeUpload} onUpload={onUpload} onUploadFailed={onUploadFailed} />
                </BodyField>
            </BodyPart>
            {PATREON_ENABLED ? <BodyPart label='Download from Patreon'>
                <BodyField label='' hidden={!patreonModels}>
                    <Typography variant='body2'>Signed in to Patreon as {patreonModels?.patreonName}, with access to the following tiers: {patreonModels?.patreonTiers.join(', ')} and below</Typography>
                </BodyField>
                <BodyField label='' hidden={!patreonModels}>
                    <Stack direction='row' gap={1} flexWrap='wrap' pb={1}>
                        {patreonModels?.models.map(m => <ImageButton key={m.type} label={m.type} src={`/ModelIcons/${m.type}.png`} faded={!models.find(o => o.type === m.type)} onClick={() => loadPatreonModel(m.type, m.fileName)} />)}
                    </Stack>
                </BodyField>
                {!isLoggedInToPatreon() ? <>
                    <Typography variant='body2'>
                        <PatreonSignInLink /> to see the list of models you have access to via your Patreon pledges. You can pledge to <Link href="https://www.patreon.com/kurotamaart" target="_blank">Kurotama's Patreon campaign</Link> to gain access to various models, depending on your tier.
                    </Typography>
                </> : <>
                    <PatreonSignOutLink />
                </>}
            </BodyPart> : null}
            {GUMROAD_ENABLED ? <BodyPart label='Download from Gumroad'>
                <BodyField label='' hidden={!gumroadModels}>
                    <Typography variant='body2'>
                        You can purchase models from <Link href="https://kurotamaart.gumroad.com/" target="_blank">Kurotama's Gumroad page</Link>. You will need to provide a valid license key from Gumroad to download a model.
                    </Typography>
                </BodyField>
                <BodyField label='' hidden={!gumroadModels}>
                    <Stack direction='row' gap={1} flexWrap='wrap' pb={1}>
                        <ImageButtonNew label='Other License Key' onClick={() => loadUnknownGumroadModel()} />
                        {gumroadModels?.models.map(m => <ImageButton key={m.type} label={m.type} src={`/ModelIcons/${m.type}.png`} faded={!models.find(o => o.type === m.type)} onClick={() => loadGumroadModel(m.type)} />)}
                    </Stack>
                </BodyField>
            </BodyPart> : null}
        </BodyTab>
        <Backdrop open={loading}>
            <Stack justifyContent='center' alignItems='center'>
                <Typography>Loading model...</Typography>
                <CircularProgress />
            </Stack>
        </Backdrop>
        <Backdrop open={downloading}>
            <Stack justifyContent='center' alignItems='center'>
                <Typography>Downloading model...</Typography>
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                    <CircularProgress variant='determinate' value={Math.round(downloadProgress * 100)} />
                    <Box sx={{ top: 0, left: 0, bottom: 0, right: 0, position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography variant="caption" component="div" color="text.secondary">{`${Math.round(downloadProgress * 100)}%`}</Typography>
                    </Box>
                </Box>
            </Stack>
        </Backdrop>
    </>
})

function getCleanModelName(m: ModelListItem) {
    return m.type === ModelType.Unknown ? m.name.includes('.model3.json') ? m.name.substring(0, m.name.indexOf('.model3.json')) : m.name : m.type
}
