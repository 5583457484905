import localforage from 'localforage'
import { loadImage } from './utils'

localforage.config({ name: 'hsg-customizer', storeName: 'files' })

function storeFile(key: string, buffer: ArrayBuffer) {
    return localforage.setItem(key, buffer)
}

function retrieveFile(key: string) {
    return localforage.getItem<ArrayBuffer>(key)
}

function deleteFile(key: string) {
    return localforage.removeItem(key)
}

async function retrieveImage(key: string) {
    const buffer = await retrieveFile(key)
    if (!buffer) return null
    const blob = new Blob([buffer])
    const url = URL.createObjectURL(blob)
    try {
        return await loadImage(url)
    } catch {
        return null
    }
}

export const fileStore = {
    storeFile,
    retrieveFile,
    deleteFile,
    retrieveImage,
}
