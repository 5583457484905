import { Button, Typography, Stack, TextField } from '@mui/material'
import { memo, useMemo, useCallback } from 'react'
import { BodyPart } from '../BodyField'
import { useStoreSelector } from '../store'

export const PresetExportPart = memo(function PresetExportPart() {
    const customization = useStoreSelector(s => s.preset.current)

    const customizationJson = useMemo(() => JSON.stringify(customization), [customization])

    const onDownloadJson = useCallback(() => {
        const a = document.createElement('a')
        a.href = 'data:application/json,' + encodeURI(customizationJson)
        a.download = 'gena-configuration.json'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }, [customizationJson])

    return <BodyPart label='Export Customizer Preset'>
        <Button size='small' variant='outlined' onClick={onDownloadJson}>Download Preset</Button>
        <Typography>or</Typography>
        <Stack direction="row">
            <TextField value={customizationJson} InputProps={{ readOnly: true }} label='Copy Customization Preset' size='small' sx={{ flex: 1 }} />
            <Button size='small' variant='outlined' onClick={() => navigator.clipboard.writeText(customizationJson)}>Copy</Button>
        </Stack>
    </BodyPart>
})

