import { createModel, GenericModelStaticData, ModelType } from './common'

export const MIA_MODEL: GenericModelStaticData = createModel(ModelType.Mia_P_V7, {
    type: ModelType.Mia_P_V7,
    colors: {},
    links: {},
    toggles: {
        milk: false,
        expressionRed: false,
        expressionConcern: false,
        expressionSparkle: false,
        expressionExclamation: false,
        expressionSweat: false,
    },
    choices: {},
    clothing: {},
    sliders: {
        blush: 0,
        breastSize: 0,
    },
    unmappedArtMeshColors: {},
    unmappedParameterValues: {},
    motions: [],
    expressions: [],
    decals: {},
}, {
    type: ModelType.Mia_P_V7,
    possibleModelFiles: [
        'HSG Game Asset -  Mia (Vtube Studio)/HSG Characters Live2D Set 1 Mia P v7.model3.json',
        'HSG Characters Live2D Set 1 Mia P v7.model3.json',
    ],
    sharedParts: [],
    tabs: [{
        name: 'Options',
        parts: [{
            name: 'Expressions',
            fields: [{
                name: 'Blush',
                type: 'slider',
                key: 'blush',
            }, {
                name: 'Flushed',
                type: 'toggle',
                key: 'expressionRed',
            }, {
                name: 'Concerned',
                type: 'toggle',
                key: 'expressionConcern',
            }, {
                name: 'Sparkles',
                type: 'toggle',
                key: 'expressionSparkle',
            }, {
                name: 'Exclamation',
                type: 'toggle',
                key: 'expressionExclamation',
            }, {
                name: 'Sweating',
                type: 'toggle',
                key: 'expressionSweat',
            }]
        }, {
            name: 'Chest',
            fields: [{
                name: 'Breast Size',
                type: 'slider',
                key: 'breastSize',
            }, {
                name: 'Milk',
                type: 'toggle',
                key: 'milk',
            }],
        }]
    }],
    choiceOptions: {},
    clothingOptions: {},
    links: {},
    colorArtMeshes: {},
    toggleParams: {
        milk: ['ParamMilk'],
        expressionRed: ['ParamExp'],
        expressionConcern: ['ParamExp2'],
        expressionSparkle: ['ParamExp3'],
        expressionExclamation: ['ParamExp4'],
        expressionSweat: ['ParamBlush2'],
    },
    choiceParams: {},
    clothingParams: {},
    sliderParams: {
        blush: ['ParamBlush'],
        breastSize: ['ParamBSize'],
    },
    smoothParams: {
        'ParamBSize': 5,
    },
    staticArtMeshColors: {},
    staticParameterValues: {},
    decals: {},
})
