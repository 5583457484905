import { createModel, enumToChoiceOptions, GenericModelStaticData, ModelType } from './common'

enum ClothType {
    Top = 0,
    Sock = 0.5,
    Bottom = 1,
}

export const IVY_MODEL: GenericModelStaticData = createModel(ModelType.Ivy_P_V6, {
    type: ModelType.Ivy_P_V6,
    colors: {},
    links: {},
    toggles: {
        eyeUpgrade: false,
        armDown: false,
        bulgeOn: false,
        clothOn: true,
        expressionRed: false,
        expressionConcern: false,
        expressionSparkle: false,
        expressionExclamation: false,
    },
    choices: {
        clothType: ClothType.Top,
    },
    clothing: {},
    sliders: {
        blush: 0,
        height: 0,
        thighSize: 0,
        ballSize: 0,
    },
    unmappedArtMeshColors: {},
    unmappedParameterValues: {},
    motions: [],
    expressions: [],
    decals: {},
}, {
    type: ModelType.Ivy_P_V6,
    possibleModelFiles: [
        'HSG Game Asset -  Ivy (Vtube Studio)/HSG Characters Live2D Set 1 Ivy P v6.model3.json',
        'HSG Characters Live2D Set 1 Ivy P v6.model3.json',
    ],
    sharedParts: [],
    tabs: [{
        name: 'Options',
        parts: [{
            name: 'Body',
            fields: [{
                name: 'Height',
                type: 'slider',
                key: 'height',
            }],
        }, {
            name: 'Face',
            fields: [{
                name: 'Blush',
                type: 'slider',
                key: 'blush',
            }, {
                name: 'Upgraded Eye',
                type: 'toggle',
                key: 'eyeUpgrade',
            }],
        }, {
            name: 'Hips',
            fields: [{
                name: 'Thigh Size',
                type: 'slider',
                key: 'thighSize',
            }, {
                name: 'Bulge On',
                type: 'toggle',
                key: 'bulgeOn',
            }, {
                name: 'Bulge Size',
                type: 'slider',
                key: 'ballSize',
                conditions: [{ type: 'toggle', key: 'bulgeOn', value: true }],
            }],
        }, {
            name: 'Pose',
            fields: [{
                name: 'Arm Raised',
                type: 'toggle',
                key: 'armDown',
                invert: true,
            }, {
                name: 'Cloth On',
                type: 'toggle',
                key: 'clothOn',
                conditions: [{ type: 'toggle', key: 'armDown', value: false }],
            }, {
                name: 'Cloth Type',
                type: 'choice',
                key: 'clothType',
                conditions: [{ type: 'toggle', key: 'armDown', value: false }, { type: 'toggle', key: 'clothOn', value: true }],
            }],
        }, {
            name: 'Expressions',
            fields: [{
                name: 'Flushed',
                type: 'toggle',
                key: 'expressionRed',
            }, {
                name: 'Concerned',
                type: 'toggle',
                key: 'expressionConcern',
            }, {
                name: 'Sparkles',
                type: 'toggle',
                key: 'expressionSparkle',
            }, {
                name: 'Exclamation',
                type: 'toggle',
                key: 'expressionExclamation',
            }],
        }],
    }],
    choiceOptions: {
        clothType: enumToChoiceOptions(ClothType),
    },
    clothingOptions: {},
    links: {},
    colorArtMeshes: {},
    toggleParams: {
        eyeUpgrade: ['ParamExp7'],
        armDown: ['ParamExp8'],
        bulgeOn: ['ParamHSize5'],
        clothOn: ['ParamExp6'],
        expressionRed: ['ParamExp'],
        expressionConcern: ['ParamExp2'],
        expressionSparkle: ['ParamExp3'],
        expressionExclamation: ['ParamExp4'],
    },
    choiceParams: {
        clothType: ['ParamExp5'],
    },
    clothingParams: {},
    sliderParams: {
        blush: ['ParamBlush'],
        height: ['ParamHSize4'],
        thighSize: ['ParamHSize', 'ParamHSize2'],
        ballSize: ['ParamHSize3'],
    },
    smoothParams: {
        'ParamHSize': 5,
        'ParamHSize2': 5,
        'ParamHSize3': 5,
        'ParamHSize5': 2,
    },
    staticArtMeshColors: {},
    staticParameterValues: {},
    decals: {},
})
