import React, { memo, useMemo, useState } from 'react'
import { VideoBackground } from './VideoBackground'
import { AppSettersContextProvider, ModelContextProvider } from './context'
import { ModelWrapper } from './ModelWrapper'
import { Container, Grid } from '@mui/material'
import { Live2DModel } from './live2D'
import { ConfirmDialog, ConfirmProvider } from './Confirm'
import { ModelListPart } from './parts/ModelListPart'
import { useStoreSelector } from './store'
import { VTubeStudioProvider } from './parts/VTubeStudioSyncPart'
import { useKeyboardShortcutHandler } from './shortcuts'
import { CustomizerToolbar } from './CustomizerToolbar'
import { CustomizerTabContent } from './CustomizerTabs'
import { BodyTabs } from './BodyField'
import { ErrorBoundary, ErrorList } from './error'

export const Customizer = memo(function Customizer() {
    const hideUI = useStoreSelector(s => s.settings.hideUI)
    const [model, setModel] = useState<Live2DModel | null>(null)


    const modelContext = useMemo(() => ({ model }), [model])
    const settersContext = useMemo(() => ({ setModel }), [])

    useKeyboardShortcutHandler()

    return <>
        <ConfirmProvider>
            <AppSettersContextProvider value={settersContext}>
                <ModelContextProvider value={modelContext}>
                    <VTubeStudioProvider>
                        <VideoBackground src="Wallpaper/HSG Ship Wallpaper 1080p Dark.mp4" />
                        {model ? <>
                            <ErrorBoundary>
                                <ModelWrapper />
                            </ErrorBoundary>
                        </> : null}
                        {!hideUI ? <>
                            <ErrorBoundary>
                                <CustomizerToolbar />
                            </ErrorBoundary>
                        </> : null}
                        <Container sx={{ position: 'relative' }} className='customizer'>
                            <Grid container>
                                <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
                                    {!hideUI ? <>
                                        <ErrorBoundary>
                                            <CustomizerTabContent />
                                        </ErrorBoundary>
                                    </> : null}
                                </Grid>
                                <Grid item xl={10} lg={9} md={8} sm={6} xs={12}>
                                    <ErrorList />
                                    {!model ? <>
                                        <ErrorBoundary>
                                            <BodyTabs>
                                                <ModelListPart />
                                            </BodyTabs>
                                        </ErrorBoundary>
                                    </> : null}
                                </Grid>
                            </Grid>
                        </Container>
                        <ConfirmDialog />
                    </VTubeStudioProvider>
                </ModelContextProvider>
            </AppSettersContextProvider>
        </ConfirmProvider>
    </>
})
