import { deserializeJson } from './utils'

interface Color {
    r: number
    g: number
    b: number
    a: number
}

interface Live2DViewerSettingsFile {
    ModelFileName: string
    Contents: {
        TypeName: ContentTypeName
        SerializedContents: string
    }[]
}

export type Live2DViewerSettings = ReturnType<typeof deserializeLive2DViewerSettingsFile>

enum ContentTypeName {
    Animator = 'a86846a6420b13e72dd7cc6f1a5f7f4b',
    ColorPicker = '447842ebcacf919d0b94f2b73ecb5753',
    Drawable = '33e55cc25f6826c438e1bb009c2ba93e',
    AutoEffect = '99a778f0e980e17fdbafa55a8fbe30ef',
    Look = 'adade5492ac7a8edc9803effc699b5e1',
    Blending = '7221b2c37abef5ce69dfc5185197923f',
    Parameters = '03cca611659e1699abd9d27cea6b7cb1',
}

function parseContents<T extends ContentTypeName>(typeName: ContentTypeName, serializedContents: string): ContentTypeMap[T] | null {
    return deserializeJson<ContentTypeMap[T]>(serializedContents)
}

export function deserializeLive2DViewerSettings(json: string) {
    const value = deserializeJson<Live2DViewerSettingsFile>(json)
    if (!value || !('ModelFileName' in value) || !('Contents' in value)) return null
    return deserializeLive2DViewerSettingsFile(value)
}

export function serializeLive2DViewerSettings(settings: Live2DViewerSettings) {
    return JSON.stringify(serializeLive2DViewerSettingsFile(settings))
}

function serializeLive2DViewerSettingsFile(settings: Live2DViewerSettings): Live2DViewerSettingsFile {
    return {
        ModelFileName: settings.modelFileName,
        Contents: [
            ...(settings.animator ? [{ TypeName: ContentTypeName.Animator, SerializedContents: JSON.stringify(settings.animator) }] : []),
            ...(settings.blending ? [{ TypeName: ContentTypeName.Blending, SerializedContents: JSON.stringify(settings.blending) }] : []),
            ...(settings.parameters ? [{ TypeName: ContentTypeName.Parameters, SerializedContents: JSON.stringify(settings.parameters) }] : []),
            ...(settings.look ? [{ TypeName: ContentTypeName.Look, SerializedContents: JSON.stringify(settings.look) }] : []),
            ...(settings.colorPicker ? [{ TypeName: ContentTypeName.ColorPicker, SerializedContents: JSON.stringify(settings.colorPicker) }] : []),
            { TypeName: ContentTypeName.AutoEffect, SerializedContents: '' }, // Weird bug?
            ...(settings.drawables ? [{ TypeName: ContentTypeName.Drawable, SerializedContents: JSON.stringify(settings.drawables) }] : []),
        ]
    }
}

function deserializeLive2DViewerSettingsFile(file: Live2DViewerSettingsFile) {
    const modelFileName = file.ModelFileName
    const contents = file.Contents.map(c => ({ TypeName: c.TypeName, Contents: parseContents(c.TypeName, c.SerializedContents) }))
    const animator = (contents.find(c => c.TypeName === ContentTypeName.Animator)?.Contents as AnimatorData | undefined) ?? null
    const colorPicker = (contents.find(c => c.TypeName === ContentTypeName.ColorPicker)?.Contents as ColorPickerData | undefined) ?? null
    const drawables = (contents.find(c => c.TypeName === ContentTypeName.Drawable)?.Contents as DrawableData | undefined) ?? null
    const autoEffect = (contents.find(c => c.TypeName === ContentTypeName.AutoEffect)?.Contents as AutoEffectData | undefined) ?? null
    const look = (contents.find(c => c.TypeName === ContentTypeName.Look)?.Contents as LookData | undefined) ?? null
    const blending = (contents.find(c => c.TypeName === ContentTypeName.Blending)?.Contents as BlendingData | undefined) ?? null
    const parameters = (contents.find(c => c.TypeName === ContentTypeName.Parameters)?.Contents as ParameterData | undefined) ?? null
    return {
        modelFileName,
        animator,
        colorPicker,
        drawables,
        autoEffect,
        look,
        blending,
        parameters,
    }
}

type ContentTypeMap = {
    [ContentTypeName.Animator]: AnimatorData
    [ContentTypeName.ColorPicker]: ColorPickerData
    [ContentTypeName.Drawable]: DrawableData
    [ContentTypeName.AutoEffect]: AutoEffectData
    [ContentTypeName.Look]: LookData
    [ContentTypeName.Blending]: BlendingData
    [ContentTypeName.Parameters]: ParameterData
}

interface AnimatorData {
    Motion3JsonPathes: []
}

interface ColorPickerData {
    Enabled: boolean
    BackgroundColor: Color
}

interface DrawableData {
    Drawables: {
        Id: string
        MultiplyColor: Color
        ScreenColor: Color
    }[]
}

interface AutoEffectData {
    EnabledHarmonicMotionController: boolean
    EnabledEyeBlinkController: boolean
}

interface LookData {
    Items: {
        SerializableParameterName: string
        SerializableLookAt: boolean
        SerializableParameterReverse: boolean
        SerializableSourceAxis: number
        SerializableWeight: number
    }[]
    Enabled: boolean
}

interface BlendingData {
    SelectedMotionsList: {
        OptionName: string
        MotionList: string
    }[]
    Weights: number[]
    BlendModes: number[]
}

interface ParameterData {
    Parameters: {
        Id: string
        Value: number
    }[]
}
