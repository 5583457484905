import { Alert, Switch, Typography } from '@mui/material'
import { memo } from 'react'
import { BodyField, BodyPart } from '../BodyField'
import { useStoreDispatch, useStoreSelector } from '../store'
import * as metadata from '../metadata'
import { NSFW_ENABLED } from '../nsfw'

export const SettingsPart = memo(function SettingsPart() {
    const stg = useStoreSelector(s => s.settings)
    const dispatch = useStoreDispatch()
    return <>
        <BodyPart label='General Settings'>
            <BodyField label='Enable Debugging Tools'>
                <Switch checked={stg.enableDebug} onChange={(_, v) => dispatch(a => a.setAppSetting({ key: 'enableDebug', value: v }))} />
            </BodyField>
        </BodyPart>
        {NSFW_ENABLED ? <>
            <Alert color='warning'>This app contains some extreme fetish content. If you don't want to see features related to a fetish, don't enable it. You have been warned.</Alert>
            <BodyPart label='Content Settings'>
                <BodyField label='Enable Vore Content'>
                    <Switch checked={stg.enableVore} onChange={(_, v) => dispatch(a => a.setAppSetting({ key: 'enableVore', value: v }))} />
                </BodyField>
            </BodyPart>
        </> : null}
        <BodyPart label='App Info'>
            <BodyField label='App Version'>
                <Typography variant='body2'>{metadata.PUBLISH_DATE}</Typography>
            </BodyField>
        </BodyPart>
    </>
})