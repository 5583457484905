import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import { memo, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { createCustomContext } from './hooks'
import { ExposedPromise, makeExposedPromise } from './utils'

interface AnyConfirmArgs {
    title?: string
    confirm?: string | null
    cancel?: string | null
    input?: string | null
}

type ConfirmArgs = Omit<AnyConfirmArgs, 'input'>
type AlertArgs = Omit<AnyConfirmArgs, 'cancel' | 'input'>
type PromptArgs = AnyConfirmArgs

interface BaseConfirmState<T extends string> {
    type: T
    content: ReactNode
}

interface ConfirmState extends BaseConfirmState<'confirm'>, ConfirmArgs {
    promise: ExposedPromise<boolean>
}

interface AlertState extends BaseConfirmState<'alert'>, AlertArgs {
    promise: ExposedPromise<boolean>
}

interface PromptState extends BaseConfirmState<'prompt'>, PromptArgs {
    promise: ExposedPromise<string | null>
}

type AnyConfirmState = ConfirmState | AlertState | PromptState

const [useConfirmContext, ConfirmContextProvider] = createCustomContext<{
    confirm: (content: ReactNode, args?: ConfirmArgs) => Promise<boolean>
    alert: (content: ReactNode, args?: AlertArgs) => Promise<boolean>
    prompt: (content: ReactNode, args?: PromptArgs) => Promise<string | null>
}>('ConfirmContext')

const [useConfirmStateContext, ConfirmStateContextProvider] = createCustomContext<{
    stateQueue: AnyConfirmState[]
    setStateQueue: (states: AnyConfirmState[] | ((states: AnyConfirmState[]) => AnyConfirmState[])) => void
}>('ConfirmStateContext')

export const useConfirm = useConfirmContext

export const ConfirmProvider = memo(function ConfirmProvider({ children }: { children: ReactNode }) {
    const [stateQueue, setStateQueue] = useState<AnyConfirmState[]>([])

    const confirm = useCallback((content: ReactNode, args?: ConfirmArgs) => {
        const promise = makeExposedPromise<boolean>()
        setStateQueue(states => [...states, { ...args, type: 'confirm', content, promise }])
        return promise
    }, [])

    const alert = useCallback((content: ReactNode, args?: AlertArgs) => {
        const promise = makeExposedPromise<boolean>()
        setStateQueue(states => [...states, { confirm: 'OK', cancel: null, ...args, type: 'alert', content, promise }])
        return promise
    }, [])

    const prompt = useCallback((content: ReactNode, args?: PromptArgs) => {
        const promise = makeExposedPromise<string | null>()
        setStateQueue(states => [...states, { confirm: 'OK', ...args, type: 'prompt', content, promise }])
        return promise
    }, [])

    const confirmCtx = useMemo(() => ({ confirm, alert, prompt }), [confirm, alert, prompt])
    const confirmStateCtx = useMemo(() => ({ stateQueue, setStateQueue }), [stateQueue])

    return <ConfirmContextProvider value={confirmCtx}>
        <ConfirmStateContextProvider value={confirmStateCtx}>
            {children}
        </ConfirmStateContextProvider>
    </ConfirmContextProvider>
})

export const ConfirmDialog = memo(function Confirm() {
    const { stateQueue, setStateQueue } = useConfirmStateContext()
    const [inputValue, setInputValue] = useState<string>('')

    const first = stateQueue.length ? stateQueue[0] : null

    useEffect(() => {
        setInputValue('')
    }, [first])

    const onConfirm = useCallback(() => {
        if (!first) return
        if (first.type === 'prompt') first.promise.resolve(inputValue.trim())
        else first.promise.resolve(true)
        setStateQueue(states => states.slice(1))
    }, [first, inputValue, setStateQueue])

    const onCancel = useCallback(() => {
        if (!first) return
        if (first.type === 'prompt') first.promise.resolve(null)
        else first.promise.resolve(false)
        setStateQueue(states => states.slice(1))
    }, [first, setStateQueue])

    const onClose = useCallback(() => {
        onCancel()
    }, [onCancel])

    return <Dialog open={!!first} onClose={onClose}>
        {first?.title ? <DialogTitle>{first?.title}</DialogTitle> : null}
        <DialogContent>
            <DialogContentText>
                {first?.content}
            </DialogContentText>
            {first && first.type === 'prompt' ? <TextField sx={{ width: '100%', marginTop: '10px' }} label={first.input ?? 'Value'} value={inputValue} onChange={e => setInputValue(e.target.value ?? '')} autoFocus={first.type === 'prompt'} /> : null}
        </DialogContent>
        <DialogActions>
            {first && first.type !== 'alert' && first.cancel !== null ? <Button size='large' onClick={onCancel}>{first.cancel ?? 'Cancel'}</Button> : null}
            {first && first.confirm !== null ? <Button size='large' variant='contained' onClick={onConfirm} disabled={first.type === 'prompt' && !inputValue} autoFocus={first.type !== 'prompt'}>{first.confirm ?? 'Confirm'}</Button> : null}
        </DialogActions>
    </Dialog>
})
