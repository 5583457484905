import { CubismFramework } from 'live2d-cubism-framework'
import { CubismUserModel } from 'live2d-cubism-framework/dist/model/cubismusermodel'
import { CubismShaderSet, CubismShader_WebGL } from 'live2d-cubism-framework/dist/rendering/cubismshader_webgl'
import { CubismId } from 'live2d-cubism-framework/dist/id/cubismid'
import { CubismTargetPoint } from 'live2d-cubism-framework/dist/math/cubismtargetpoint'
import { CubismDefaultParameterId } from 'live2d-cubism-framework/dist/cubismdefaultparameterid'
import { csmVector } from 'live2d-cubism-framework/dist/type/csmvector'

type SmoothValueMap = Record<string, { p: number, v: number }>

function slerp(smoothValues: SmoothValueMap, dt: number, key: string, min: number, max: number, target: number, speed: number) {
    const normalizedTarget = (target - min) / (max - min)
    const value = smoothValues[key] ?? (smoothValues[key] = { p: normalizedTarget, v: 0 })
    const diff = normalizedTarget - value.p
    const accel = diff * (Math.sign(diff) !== Math.sign(value.v) ? 100 : 20) * speed
    value.v += accel * dt
    value.p = Math.min(1, Math.max(0, value.p + value.v * dt))
    return min + value.p * (max - min)
}

export class CustomUserModel extends CubismUserModel {
    public constructor() {
        super()
        this.scalePoint.set(1, 1)
    }

    public get dragManager() { return this._dragManager }
    public get motionManager() { return this._motionManager }
    public get expressionManager() { return this._expressionManager }
    public get eyeBlink() { return this._eyeBlink }
    public set eyeBlink(v) { this._eyeBlink = v }
    public get breath() { return this._breath }
    public set breath(v) { this._breath = v }
    public get physics() { return this._physics }
    public get pose() { return this._pose }

    public readonly scalePoint: CubismTargetPoint = new CubismTargetPoint()
    public readonly panPoint: CubismTargetPoint = new CubismTargetPoint()
    public readonly eyePoint: CubismTargetPoint = new CubismTargetPoint()
    public readonly headPoint: CubismTargetPoint = new CubismTargetPoint()
    public readonly bodyPoint: CubismTargetPoint = new CubismTargetPoint()

    private idCache: Map<string, CubismId> = new Map()
    private multiplyColorsToReset: Set<string> = new Set()
    private screenColorsToReset: Set<string> = new Set()
    private paramsToReset: Set<string> = new Set()
    private smoothValues: SmoothValueMap = {}

    private idExists(key: string) {
        if (this.idCache.has(key)) return true
        return CubismFramework.getIdManager().isExist(key)
    }

    private getId(key: string) {
        const value = this.idCache.get(key)
        if (value !== undefined) return value
        const id = CubismFramework.getIdManager().getId(key)
        this.idCache.set(key, id)
        return id
    }

    public update(deltaTime: number, { multiplyColors, screenColors, parameterValues, parameterSmoothing }: {
        multiplyColors?: Partial<Record<string, { r: number, g: number, b: number, a?: number }>>
        screenColors?: Partial<Record<string, { r: number, g: number, b: number, a?: number }>>
        parameterValues?: Partial<Record<string, number>>
        parameterSmoothing?: Partial<Record<string, number>>
    }) {
        this.scalePoint.update(deltaTime)
        this.panPoint.update(deltaTime)
        this.eyePoint.update(deltaTime)
        this.headPoint.update(deltaTime)
        this.bodyPoint.update(deltaTime)
        this.dragManager.update(deltaTime)

        const eyeLookX = this.eyePoint.getX()
        const eyeLookY = this.eyePoint.getY()
        const headLookX = this.headPoint.getX()
        const headLookY = this.headPoint.getY()
        const bodyLookX = this.bodyPoint.getX()
        const bodyLookY = this.bodyPoint.getY()
        const bodyMoveX = this.dragManager.getX()
        const bodyMoveY = this.dragManager.getY()

        let motionUpdated = false
        if (this.motionManager && !this.motionManager.isFinished()) {
            motionUpdated = this.motionManager.updateMotion(this.getModel(), deltaTime)
        }

        if (multiplyColors || this.multiplyColorsToReset.size > 0) {
            const currentMultiplyColors = new Set(Object.keys(multiplyColors ?? {}))
            for (const key of this.multiplyColorsToReset) {
                if (!currentMultiplyColors.has(key)) {
                    if (!this.idExists(key)) continue
                    const id = this.getId(key)
                    const index = this.getModel().getDrawableIndex(id)
                    if (index >= 0) {
                        this.getModel().setMultiplyColorByRGBA(index, 1, 1, 1, 1)
                    }
                }
            }
            this.multiplyColorsToReset.clear()
            for (const key of currentMultiplyColors) this.multiplyColorsToReset.add(key)
        }

        if (multiplyColors) {
            for (const key in multiplyColors) {
                const color = multiplyColors[key]
                if (!color) continue
                if (!this.idExists(key)) continue
                const id = this.getId(key)
                const index = this.getModel().getDrawableIndex(id)
                if (index >= 0) {
                    this.getModel().setOverwriteFlagForDrawableMultiplyColors(index, true)
                    this.getModel().setMultiplyColorByRGBA(index, color.r, color.g, color.b, color.a ?? 1)
                }
            }
        }

        if (screenColors || this.screenColorsToReset.size > 0) {
            const currentScreenColors = new Set(Object.keys(screenColors ?? {}))
            for (const key of this.screenColorsToReset) {
                if (!currentScreenColors.has(key)) {
                    if (!this.idExists(key)) continue
                    const id = this.getId(key)
                    const index = this.getModel().getDrawableIndex(id)
                    if (index >= 0) {
                        this.getModel().setScreenColorByRGBA(index, 0, 0, 0, 1)
                    }
                }
            }
            this.screenColorsToReset.clear()
            for (const key of currentScreenColors) this.screenColorsToReset.add(key)
        }

        if (screenColors) {
            for (const key in screenColors) {
                const color = screenColors[key]
                if (!color) continue
                if (!this.idExists(key)) continue
                const id = this.getId(key)
                const index = this.getModel().getDrawableIndex(id)
                if (index >= 0) {
                    this.getModel().setOverwriteFlagForDrawableScreenColors(index, true)
                    this.getModel().setScreenColorByRGBA(index, color.r, color.g, color.b, color.a ?? 1)
                }
            }
        }

        if (parameterValues || this.paramsToReset.size > 0) {
            const currentParams = new Set(Object.keys(parameterValues ?? {}))
            for (const key of this.paramsToReset) {
                if (!currentParams.has(key)) {
                    if (!this.idExists(key)) continue
                    const id = this.getId(key)
                    const index = this.getModel().getParameterIndex(id)
                    if (index >= 0) {
                        const value = this.getModel().getParameterDefaultValue(index)
                        this.getModel().setParameterValueByIndex(index, value, 1)
                    }
                }
            }
            this.paramsToReset.clear()
            for (const key of currentParams) this.paramsToReset.add(key)
        }

        if (parameterValues) {
            for (const key in parameterValues) {
                let value = parameterValues[key]
                if (value === undefined) continue
                if (!this.idExists(key)) continue
                const id = this.getId(key)
                const index = this.getModel().getParameterIndex(id)
                if (index >= 0) {
                    if (parameterSmoothing?.[key]) {
                        const min = this.getModel().getParameterMinimumValue(index)
                        const max = this.getModel().getParameterMaximumValue(index)
                        value = slerp(this.smoothValues, deltaTime, key, min, max, value, parameterSmoothing[key] ?? 1)
                    }
                    this.getModel().setParameterValueByIndex(index, value, 1)
                }
            }
        }

        this.getModel().saveParameters()

        if (!motionUpdated) {
            if (this.eyeBlink) this.eyeBlink.updateParameters(this.getModel(), deltaTime)
        }
        if (this.expressionManager && !this.expressionManager.isFinished()) {
            this.expressionManager.updateMotion(this.getModel(), deltaTime)
        }

        this.getModel().addParameterValueById(CubismFramework.getIdManager().getId(CubismDefaultParameterId.ParamAngleX), headLookX * 30)
        this.getModel().addParameterValueById(CubismFramework.getIdManager().getId(CubismDefaultParameterId.ParamAngleY), headLookY * 30)
        this.getModel().addParameterValueById(CubismFramework.getIdManager().getId(CubismDefaultParameterId.ParamAngleZ), headLookX * headLookY * -30)
        this.getModel().addParameterValueById(CubismFramework.getIdManager().getId(CubismDefaultParameterId.ParamBodyAngleX), bodyLookX * 10)
        this.getModel().addParameterValueById(CubismFramework.getIdManager().getId(CubismDefaultParameterId.ParamBodyAngleY), bodyLookY * 10)
        this.getModel().addParameterValueById(CubismFramework.getIdManager().getId(CubismDefaultParameterId.ParamBodyAngleZ), bodyLookX * bodyLookY * -10)
        this.getModel().addParameterValueById(CubismFramework.getIdManager().getId(CubismDefaultParameterId.ParamEyeBallX), eyeLookX)
        this.getModel().addParameterValueById(CubismFramework.getIdManager().getId(CubismDefaultParameterId.ParamEyeBallY), eyeLookY)
        this.getModel().addParameterValueById(CubismFramework.getIdManager().getId('ParamBodyAngleX2'), bodyMoveX * 10)
        this.getModel().addParameterValueById(CubismFramework.getIdManager().getId('ParamBodyAngleY2'), bodyMoveY * 10)

        if (this.breath) this.breath.updateParameters(this.getModel(), deltaTime)
        if (this.physics) this.physics.evaluate(this.getModel(), deltaTime)
        if (this.pose) this.pose.updateParameters(this.getModel(), deltaTime)


        this.getModel().update()
        this.getModel().loadParameters()
    }

    resetCamera() {
        this.scalePoint.set(1, 1)
        this.panPoint.set(0, 0)
    }

    resetPose() {
        this.eyePoint.set(0, 0)
        this.headPoint.set(0, 0)
        this.bodyPoint.set(0, 0)
        this.dragManager.set(0, 0)
    }

    resetRenderer(gl: WebGLRenderingContext) {
        this.getRenderer().firstDraw = true
        this.getRenderer()._bufferData = { vertex: null, uv: null, index: null } as any
        this.getRenderer().startUp(gl)
        this.getRenderer()._clippingManager._currentFrameNo = 0
        this.getRenderer()._clippingManager._maskTexture = undefined as any
        CubismShader_WebGL.getInstance()._shaderSets = new csmVector<CubismShaderSet>()
    }
}
