import { CubismFramework, LogLevel } from 'live2d-cubism-framework'
import { CubismDefaultParameterId } from 'live2d-cubism-framework/dist/cubismdefaultparameterid'
import { CubismModelSettingJson } from 'live2d-cubism-framework/dist/cubismmodelsettingjson'
import { CubismBreath, BreathParameterData } from 'live2d-cubism-framework/dist/effect/cubismbreath'
import { CubismExpressionMotion } from 'live2d-cubism-framework/dist/motion/cubismexpressionmotion'
import { CubismEyeBlink } from 'live2d-cubism-framework/dist/effect/cubismeyeblink'
import { csmMap } from 'live2d-cubism-framework/dist/type/csmmap'
import { csmVector } from 'live2d-cubism-framework/dist/type/csmvector'
import { Live2DModel } from '../live2D'
import { deserializeJson, getNameFromPath, promiseAllObject, tuple } from '../utils'
import { VTubeStudioConfigFile } from '../vtubestudio'
import { CustomUserModel } from './customModel'
import { CubismModel3JsonFileFormat } from './model3'
import { CubismPhysics3JsonFileFormat } from './physics3'
import { CubismUserdata3JsonFileFormat } from './userdata3'
import { CubismPose3JsonFileFormat } from './pose3'
import { CubismCdi3JsonFileFormat } from './cdi3'
import { CubismExp3JsonFileFormat } from './exp3'
import { CubismMotion3JsonFileFormat } from './motion3'

export type Live2DFileFetcher = (filePath: string) => Promise<ArrayBuffer>

export type Live2DFileSearcher = (pattern: RegExp) => Promise<string[]>

const fetchFileFetcher: Live2DFileFetcher = filePath => fetch(filePath).then(r => r.arrayBuffer()).catch(e => {
    throw new Error(`Failed to load ${filePath}: ${String(e)}`)
})

export function loadLive2DModel(modelFolder: string, modelName: string, fetcher: Live2DFileFetcher = fetchFileFetcher, searcher?: Live2DFileSearcher) {
    if (!CubismFramework.isStarted()) {
        CubismFramework.startUp({
            logFunction: console.log,
            loggingLevel: LogLevel.LogLevel_Verbose,
        })
    }
    if (!CubismFramework.isInitialized()) {
        CubismFramework.initialize()
    }

    const userModel = new CustomUserModel()
    userModel.setInitialized(false)
    userModel.setUpdating(true)

    const arrayToVector = <T,>(array: T[]) => {
        const vec = new csmVector<T>(array.length)
        for (const v of array) vec.pushBack(v)
        return vec
    }

    const fetchAbsoluteJson = <T,>(filePath: string | undefined) => {
        return filePath ? fetcher(filePath).then(buffer => {
            const json = deserializeJson<T>(new TextDecoder('utf-8').decode(buffer))
            if (!json) return null
            return { buffer, json }
        }).catch(() => null) : Promise.resolve(null)
    }

    const fetchRelativeJson = <T,>(fileName: string | undefined) => fileName ? fetchAbsoluteJson<T>(`${modelFolder}/${fileName}`) : Promise.resolve(null)

    return fetchRelativeJson<CubismModel3JsonFileFormat>(modelName).then(r => r!).then(({ buffer, json }) => promiseAllObject({
        settings: new CubismModelSettingJson(buffer, buffer.byteLength),
        model3: json,
        otherExpressionsData: searcher?.(/^.*.exp3.json$/).then(files => Promise.all(files.map(f => fetchAbsoluteJson<CubismExp3JsonFileFormat>(f).then(v => v ? { ...v, name: getNameFromPath(f, true) } : null))).then(files => files.filter(f => !!f).map(f => f!))) ?? Promise.resolve([]),
        otherMotionsData: searcher?.(/^.*.motion3.json$/).then(files => Promise.all(files.map(f => fetchAbsoluteJson<CubismMotion3JsonFileFormat>(f).then(v => v ? { ...v, name: getNameFromPath(f, true) } : null))).then(files => files.filter(f => !!f).map(f => f!))) ?? Promise.resolve([]),
    })).then(({ settings, model3, otherMotionsData, otherExpressionsData }) => promiseAllObject({
        settings,
        modelJson: model3,
        physicsJson: fetchRelativeJson<CubismPhysics3JsonFileFormat>(model3.FileReferences.Physics).then(r => r?.json ?? null),
        userDataJson: fetchRelativeJson<CubismUserdata3JsonFileFormat>(model3.FileReferences.UserData).then(r => r?.json ?? null),
        poseJson: fetchRelativeJson<CubismPose3JsonFileFormat>(model3.FileReferences.Pose).then(r => r?.json ?? null),
        displayInfoJson: fetchRelativeJson<CubismCdi3JsonFileFormat>(model3.FileReferences.DisplayInfo).then(r => r?.json ?? null),
        expressionsJson: Promise.all(model3.FileReferences.Expressions?.map(e => fetchRelativeJson<CubismExp3JsonFileFormat>(e.File).then(d => ({ ...d, Name: e.Name }))) ?? []),
        motionsJson: Promise.all(Object.entries(model3.FileReferences.Motions ?? {}).flatMap(([k, g]) => g.flatMap(m => fetchRelativeJson<CubismMotion3JsonFileFormat>(m.File).then(data => ({ ...m, Group: k, Data: data }))))),
        vtubeStudioJson: fetchRelativeJson<VTubeStudioConfigFile>(modelName.replace('.model3.', '.vtube.')),
        otherMotionsJson: otherMotionsData,
        otherExpressionsJson: otherExpressionsData,

        moc: fetcher(`${modelFolder}/${settings.getModelFileName()}`).then(buffer => userModel.loadModel(buffer)).then(() => settings.getModelFileName()),

        expressions: settings.getExpressionCount() ? Promise.all(new Array(settings.getExpressionCount()).fill(0).map((_, i) => fetcher(`${modelFolder}/${settings.getExpressionFileName(i)}`).then(buffer => ({ name: settings.getExpressionName(i), data: userModel.loadExpression(buffer, buffer.byteLength, settings.getExpressionName(i)) })))) : Promise.resolve([]),

        otherExpressions: otherExpressionsData.map(e => ({ name: e.name, json: e.json, data: CubismExpressionMotion.create(e.buffer, e.buffer.byteLength) })).sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })),

        physics: settings.getPhysicsFileName() ? fetcher(`${modelFolder}/${settings.getPhysicsFileName()}`).then(buffer => userModel.loadPhysics(buffer, buffer.byteLength)).then(() => settings.getPhysicsFileName()) : null,

        poses: settings.getPoseFileName() ? fetcher(`${modelFolder}/${settings.getPoseFileName()}`).then(buffer => userModel.loadPose(buffer, buffer.byteLength)).then(() => settings.getPoseFileName()) : null,

        motions: settings.getMotionGroupCount() ? Promise.all(new Array(settings.getMotionGroupCount()).fill(0).map((_, i) => settings.getMotionGroupName(i)).flatMap((group) => new Array(settings.getMotionCount(group)).fill(0).flatMap((_, i) => fetcher(`${modelFolder}/${settings.getMotionFileName(group, i)}`).then(buffer => {
            const motion = userModel.loadMotion(buffer, buffer.byteLength, `${group}_${i}`)
            const fadeInTime = settings.getMotionFadeInTimeValue(group, i)
            if (fadeInTime >= 0) motion.setFadeInTime(fadeInTime)
            const fadeOutTime = settings.getMotionFadeOutTimeValue(group, i)
            if (fadeOutTime >= 0) motion.setFadeOutTime(fadeOutTime)
            return {
                name: settings.getMotionFileName(group, i),
                data: motion,
            }
        })))) : Promise.resolve([]),

        otherMotions: otherMotionsData.map(m => {
            const motion = userModel.loadMotion(m.buffer, m.buffer.byteLength, m.name)
            const fadeInTime = m.json.Meta?.FadeInTime ?? 0
            if (fadeInTime >= 0) motion.setFadeInTime(fadeInTime)
            const fadeOutTime = m.json.Meta?.FadeOutTime ?? 0
            if (fadeOutTime >= 0) motion.setFadeOutTime(fadeOutTime)
            return {
                name: m.name,
                json: m.json,
                data: motion,
            }
        }),

        images: settings.getTextureCount() ? Promise.all(new Array(settings.getTextureCount()).fill(0).map((_, i) => fetcher(`${modelFolder}/${settings.getTextureFileName(i)}`).then(buffer => new Promise<HTMLImageElement>(resolve => {
            const blob = new Blob([buffer])
            const url = URL.createObjectURL(blob)
            const img = new Image()
            img.onload = () => {
                URL.revokeObjectURL(url)
                resolve(img)
            }
            img.src = url
        })))) : [],
    })).then((data) => {
        const blink = data.settings.getEyeBlinkParameterCount() ? CubismEyeBlink.create(data.settings) : null
        const breath = (() => {
            const breath = CubismBreath.create()
            const breathParams = new csmVector<BreathParameterData>()
            breathParams.pushBack(new BreathParameterData(CubismFramework.getIdManager().getId(CubismDefaultParameterId.ParamAngleX), 0, 15, 6.5345, 0.5))
            breathParams.pushBack(new BreathParameterData(CubismFramework.getIdManager().getId(CubismDefaultParameterId.ParamAngleY), 0, 8, 3.5345, 0.5))
            breathParams.pushBack(new BreathParameterData(CubismFramework.getIdManager().getId(CubismDefaultParameterId.ParamAngleZ), 0, 10, 5.5345, 0.5))
            breathParams.pushBack(new BreathParameterData(CubismFramework.getIdManager().getId(CubismDefaultParameterId.ParamBodyAngleX), 0, 4, 15.5345, 0.5))
            breathParams.pushBack(new BreathParameterData(CubismFramework.getIdManager().getId(CubismDefaultParameterId.ParamBreath), 0.5, 0.5, 3.2345, 1))
            breath.setParameters(breathParams)
            return breath
        })()
        const eyeBlinkParameterIds = arrayToVector(new Array(data.settings.getEyeBlinkParameterCount()).fill(0).map((_, i) => data.settings.getEyeBlinkParameterId(i)))
        const lipSyncParameterIds = arrayToVector(new Array(data.settings.getLipSyncParameterCount()).fill(0).map((_, i) => data.settings.getLipSyncParameterId(i)))
        const modelMatrix = (() => {
            const layout: csmMap<string, number> = new csmMap()
            data.settings.getLayoutMap(layout)
            userModel.getModelMatrix().setupFromLayout(layout)
            return userModel.getModelMatrix()
        })()

        userModel.getModel().saveParameters()
        for (const motion of data.motions)
            motion.data.setEffectIds(eyeBlinkParameterIds, lipSyncParameterIds)
        for (const motion of data.otherMotions)
            motion.data.setEffectIds(eyeBlinkParameterIds, lipSyncParameterIds)

        userModel.setInitialized(true)
        userModel.setUpdating(false)
        userModel.createRenderer(4)

        userModel.eyeBlink = blink!
        userModel.breath = breath

        const drawableIds = Object.fromEntries(userModel.getModel().getModel().drawables.ids.map((key, i) => tuple(key, i)))

        const parameterIds = Object.fromEntries(userModel.getModel().getModel().parameters.ids.map((key, i) => tuple(key, i)))

        const parameterDefinitions = Object.fromEntries(new Array(userModel.getModel().getParameterCount()).fill(0).map((_, i) => tuple(userModel.getModel().getModel().parameters.ids[i], {
            id: userModel.getModel().getModel().parameters.ids[i],
            index: i,
            type: userModel.getModel().getParameterType(i),
            defaultValue: userModel.getModel().getParameterDefaultValue(i),
            minValue: userModel.getModel().getParameterMinimumValue(i),
            maxValue: userModel.getModel().getParameterMaximumValue(i),
        })))

        return {
            ...data,
            modelName,
            userModel,
            blink,
            breath,
            eyeBlinkParameterIds,
            lipSyncParameterIds,
            modelMatrix,
            drawableIds,
            parameterIds,
            parameterDefinitions,
        }
    })
}

export function prepareLive2DModel(model: Live2DModel, gl: WebGLRenderingContext) {
    const textures = model.images.map((img, i) => {
        const tex = gl.createTexture()
        if (!tex) throw new Error('Failed to initialize texture')

        gl.bindTexture(gl.TEXTURE_2D, tex)
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR_MIPMAP_LINEAR)
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR)
        gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, 1)
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, img)
        gl.generateMipmap(gl.TEXTURE_2D)
        gl.bindTexture(gl.TEXTURE_2D, null)
        model.userModel.getRenderer().bindTexture(i, tex)

        const blit = (x: number, y: number, imageLike: TexImageSource) => {
            gl.bindTexture(gl.TEXTURE_2D, tex)
            gl.texSubImage2D(gl.TEXTURE_2D, 0, x, y, gl.RGBA, gl.UNSIGNED_BYTE, imageLike)
            gl.generateMipmap(gl.TEXTURE_2D)
            gl.bindTexture(gl.TEXTURE_2D, null)
        }

        const textureInfo = {
            img,
            id: tex,
            width: img.width,
            height: img.height,
            usePremultiply: true,
            fileName: img.src,
            blit,
        }
        return textureInfo
    })

    model.userModel.getRenderer().setIsPremultipliedAlpha(true)
    model.userModel.resetRenderer(gl)
    model.userModel.getRenderer().startUp(gl)
    model.userModel.getRenderer().setClippingMaskBufferSize(1024)
    model.userModel.getRenderer()._clippingManager.setGL(gl)

    return {
        ...model,
        textures,
    }
}
