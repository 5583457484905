import { Alert, Stack } from '@mui/material'
import { createContext, memo, ReactNode, useCallback, useContext, useMemo, useState } from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import StackTrace from 'stacktrace-js'

const errorValuesContext = createContext<{ errors: Error[] } | undefined>(undefined)
const errorSettersContext = createContext<{ addError: (err: unknown) => void, removeError: (err: Error) => void } | undefined>(undefined)

const errorsSeen = new Set<string>()
const errorsToReport: string[] = []
let reportErrorPromise: Promise<void> | null = null

async function reportErrors() {
    const body = JSON.stringify(errorsToReport)
    errorsToReport.length = 0
    const res = await fetch(`https://hsg-customizer.hawkbar.workers.dev/errors/report`, {
        method: 'POST',
        body,
        headers: {
            'Content-Type': 'application/json',
        },
    })
    if (!res.ok) {
        console.error('Failed to report errors: ' + await res.text())
    }
    if (errorsToReport.length > 0) {
        reportErrorPromise = reportErrors()
    } else {
        reportErrorPromise = null
    }
}

async function processError(error: Error) {
    const trace = (await StackTrace.fromError(error)).map(t => t.toString()).join('\n')
    const fullMsg = `${error.name}: ${error.message}\n${trace}`
    if (!errorsSeen.has(fullMsg)) {
        errorsSeen.add(fullMsg)
        errorsToReport.push(fullMsg)
        if (!reportErrorPromise) {
            reportErrorPromise = reportErrors()
        }
    }
}

export function useErrorHandler() {
    const ctx = useContext(errorSettersContext)
    if (!ctx) throw new Error('Missing ErrorContext')
    return ctx.addError
}

export const ErrorProvider = memo(function ErrorProvider({ children }: { children: ReactNode }) {
    const [errors, setErrors] = useState<Error[]>([])

    const addError = useCallback((error: unknown) => {
        const e = error instanceof Error ? error : new Error(String(error))
        setErrors(errors => [...errors, e])
        processError(e)
    }, [])

    const removeError = useCallback((error: Error) => {
        setErrors(errors => {
            const i = errors.indexOf(error)
            if (i < 0) return errors
            return [...errors.slice(0, i), ...errors.slice(i + 1)]
        })
    }, [])

    const errorValuesCtx = useMemo(() => ({ errors }), [errors])
    const errorSettersCtx = useMemo(() => ({ addError, removeError }), [addError, removeError])

    return <>
        <errorValuesContext.Provider value={errorValuesCtx}>
            <errorSettersContext.Provider value={errorSettersCtx}>
                {children}
            </errorSettersContext.Provider>
        </errorValuesContext.Provider>
    </>
})

export const ErrorBoundary = memo(function ErrorBoundary({ children }: { children: ReactNode }) {
    const addError = useErrorHandler()

    const onError = useCallback((err: Error, info: { componentStack: string }) => {
        console.error(err, info)
        addError(err)
    }, [addError])

    return <ReactErrorBoundary fallback={<></>} onError={onError}>
        {children}
    </ReactErrorBoundary>
})

export const ErrorList = memo(function ErrorList() {
    const valuesCtx = useContext(errorValuesContext)
    if (!valuesCtx) throw new Error('Missing ErrorContext')
    const { errors } = valuesCtx
    const settersCtx = useContext(errorSettersContext)
    if (!settersCtx) throw new Error('Missing ErrorContext')
    const { removeError } = settersCtx
    const errorList = useMemo(() => errors.length ? <>
        <Stack spacing={1} mt={1}>
            {errors.map((e, i) => <Alert severity='error' key={i} onClose={() => removeError(e)}>{String(e)}</Alert>)}
        </Stack>
    </> : null, [errors, removeError])
    return errorList
})
