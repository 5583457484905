import { createModel, GenericModelStaticData, ModelType } from './common'

export const BOA_MODEL: GenericModelStaticData = createModel(ModelType.Boa_V8, {
    type: ModelType.Boa_V8,
    colors: {},
    links: {},
    toggles: {
        sharkOn: true,
        dickOn: false,
        armDown: false,
        whipOn: true,
        topOff: false,
        helmetOff: false,
        bellyBulgesOn: false,
    },
    choices: {},
    clothing: {},
    sliders: {
        breastSize: 10,
        bellySize: 10,
        thighSize: 10,
        ballSize: 0,
        armSize: 0,
        tailSize: 0,
        whipAngle: 0,
        bellyBulges: 0.46809,
    },
    unmappedArtMeshColors: {},
    unmappedParameterValues: {},
    motions: [],
    expressions: [],
    decals: {},
}, {
    type: ModelType.Boa_V8,
    possibleModelFiles: [
        'Boa v8 - Hyperspace Genotype (Vtube Studio)/HSG Characters Live2D Set 2 Boa v8.model3.json',
        'HSG Characters Live2D Set 2 Boa v8.model3.json',
    ],
    sharedParts: [],
    tabs: [{
        name: 'Options',
        parts: [{
            name: 'Body',
            fields: [{
                name: 'Shark',
                type: 'toggle',
                key: 'sharkOn',
            }],
        }, {
            name: 'Head',
            fields: [{
                name: 'Helmet On',
                type: 'toggle',
                key: 'helmetOff',
                invert: true,
            }],
        }, {
            name: 'Arms',
            fields: [{
                name: 'Arm Size',
                type: 'slider',
                key: 'armSize',
            }, {
                name: 'Arm Down',
                type: 'toggle',
                key: 'armDown',
            }, {
                name: 'Whip Unfurled',
                type: 'toggle',
                key: 'whipOn',
                invert: true,
            }, {
                name: 'Whip Angle',
                type: 'slider',
                key: 'whipAngle',
            }],
        }, {
            name: 'Chest',
            fields: [{
                name: 'Breast Size',
                type: 'slider',
                key: 'breastSize',
            }, {
                name: 'Top On',
                type: 'toggle',
                key: 'topOff',
                invert: true,
            }],
        }, {
            name: 'Belly',
            fields: [{
                name: 'Belly Size',
                type: 'slider',
                key: 'bellySize',
            }, {
                name: 'Belly Bulges On',
                type: 'toggle',
                key: 'bellyBulgesOn',
            }, {
                name: 'Belly Bulges Size',
                type: 'slider',
                key: 'bellyBulges',
                conditions: [{ type: 'toggle', key: 'bellyBulgesOn', value: true }],
            }],
        }, {
            name: 'Dick',
            fields: [{
                name: 'Dick On',
                type: 'toggle',
                key: 'dickOn',
            }, {
                name: 'Balls Size',
                type: 'slider',
                key: 'ballSize',
                conditions: [{ type: 'toggle', key: 'dickOn', value: true }],
            }],
        }, {
            name: 'Hips',
            fields: [{
                name: 'Thigh Size',
                type: 'slider',
                key: 'thighSize',
            }, {
                name: 'Tail Size',
                type: 'slider',
                key: 'tailSize',
                conditions: [{ type: 'toggle', key: 'sharkOn', value: true }],
            }],
        }],
    }],
    choiceOptions: {},
    clothingOptions: {},
    links: {},
    colorArtMeshes: {},
    toggleParams: {
        sharkOn: ['ParamShork', 'ParamShork2', 'ParamShork3', 'ParamShork4', 'ParamShork5'],
        dickOn: ['ParamEXPAngy5'],
        armDown: ['ParamNose3'],
        whipOn: ['ParamBlus4'],
        topOff: ['ParamNose4'],
        helmetOff: ['ParamNose2'],
        bellyBulgesOn: ['ParamEXPAngy2'],
    },
    choiceParams: {},
    clothingParams: {},
    sliderParams: {
        breastSize: ['ParamBreastsize'],
        bellySize: ['ParamBellysize'],
        thighSize: ['ParamHipsize'],
        ballSize: ['ParamBulgesize'],
        armSize: ['ParamArmsize'],
        tailSize: ['ParamTailsize'],
        whipAngle: ['ParamBlus5'],
        bellyBulges: ['ParamEXPAngy4'],
    },
    smoothParams: {
        'ParamBreastsize': 5,
        'ParamBellysize': 5,
        'ParamHipsize': 5,
        'ParamBulgesize': 5,
        'ParamArmsize': 5,
        'ParamTailsize': 5,
        'ParamEXPAngy5': 2,
        'ParamNose2': 2,
        'ParamNose4': 2,
    },
    staticArtMeshColors: {},
    staticParameterValues: {},
    decals: {},
})
