import { Tabs, Tab } from '@mui/material'
import { memo, useCallback, useMemo } from 'react'
import { BodyTabs, BodyTab } from './BodyField'
import { useModel, useModelContext, useModelDefinition } from './context'
import { ModelDefinitionEditor } from './ModelDefinitionEditor'
import { ModelEditor } from './ModelEditor'
import { BackdropPart } from './parts/BackdropPart'
import { Live2DViewerExportPart } from './parts/Live2DViewerExportPart'
import { ModelStatusPart } from './parts/ModelStatusPart'
import { PlayMotionsPart } from './parts/PlayMotionsPart'
import { PresetExportPart } from './parts/PresetExportPart'
import { PresetImportPart } from './parts/PresetImportPart'
import { PresetPalettePart } from './parts/PresetListPart'
import { SettingsPart } from './parts/SettingsPart'
import { HelpPart } from './parts/HelpPart'
import { ToggleExpressionsPart } from './parts/ToggleExpressionsPart'
import { VorePart } from './parts/VorePart'
import { VTubeStudioExportPart } from './parts/VTubeStudioExportPart'
import { VTubeStudioImportPart } from './parts/VTubeStudioImportPart'
import { VTubeStudioSyncPart } from './parts/VTubeStudioSyncPart'
import { CustomizerTab, useStoreDispatch, useStoreSelector } from './store'

export interface CustomizerTabOption {
    label: string
    value: CustomizerTab
}

export const CustomizerTabs = memo(function CustomizerTabs() {
    const tab = useStoreSelector(s => s.settings.tab)
    const dispatch = useStoreDispatch()
    const model = useModel()
    const modelDefinition = useModelDefinition()

    const tabs: CustomizerTabOption[] = useMemo(() => {
        return [
            { label: 'Model', value: CustomizerTab.Model },
            { label: 'Presets', value: CustomizerTab.Presets },
            ...(tab === CustomizerTab.Import ? [
                { label: 'Import', value: CustomizerTab.Import },
            ] : []),
            ...(tab === CustomizerTab.Export ? [
                { label: 'Export', value: CustomizerTab.Export },
            ] : []),
            ...modelDefinition.tabs.map((t, i) => ({ label: t.name, value: CustomizerTab.Custom + i })),
            { label: 'Other', value: CustomizerTab.Other },
            ...(model.otherMotions.length ? [
                { label: 'Motions', value: CustomizerTab.Motions },
            ] : []),
            ...(model.otherExpressions.length ? [
                { label: 'Expressions', value: CustomizerTab.Expressions },
            ] : []),
            ...(tab === CustomizerTab.Definition ? [
                { label: 'Mapping', value: CustomizerTab.Definition },
            ] : []),
            ...(tab === CustomizerTab.Backdrop ? [
                { label: 'Backdrop', value: CustomizerTab.Backdrop },
            ] : []),
            ...(tab === CustomizerTab.Vore ? [
                { label: 'Vore', value: CustomizerTab.Vore },
            ] : []),
            ...(tab === CustomizerTab.VTubeStudio ? [
                { label: 'VTube Studio', value: CustomizerTab.VTubeStudio },
            ] : []),
            ...(tab === CustomizerTab.Settings ? [
                { label: 'Settings', value: CustomizerTab.Settings },
            ] : []),
            ...(tab === CustomizerTab.Help ? [
                { label: 'Help', value: CustomizerTab.Help },
            ] : []),
        ]
    }, [model, modelDefinition.tabs, tab])

    const setTab = useCallback((t: CustomizerTab) => {
        dispatch(a => a.setAppSetting({ key: 'tab', value: t }))
    }, [dispatch])

    const onChangeTab = useCallback((_: unknown, index: number) => {
        if (index >= 0 && index < tabs.length) {
            setTab(tabs[index].value)
        }
    }, [setTab, tabs])

    const currentIndex = useMemo(() => Math.min(tabs.length - 1, Math.max(0, tabs.findIndex(t => t.value === tab))), [tab, tabs])

    return <Tabs value={currentIndex} onChange={onChangeTab} variant='scrollable' allowScrollButtonsMobile>
        {tabs.map(t => <Tab key={t.value} label={t.label} />)}
    </Tabs>
})

export const CustomizerTabContent = memo(function CustomizerTabContent() {
    const tab = useStoreSelector(s => s.settings.tab)
    const { model } = useModelContext()

    return <BodyTabs>
        {model ? <>
            <BodyTab hidden={tab !== CustomizerTab.Model}>
                <ModelStatusPart />
            </BodyTab>
            <BodyTab hidden={tab !== CustomizerTab.Presets}>
                <PresetPalettePart />
            </BodyTab>
            <BodyTab hidden={tab !== CustomizerTab.Import}>
                <PresetImportPart />
                <VTubeStudioImportPart />
            </BodyTab>
            <BodyTab hidden={tab !== CustomizerTab.Export}>
                <PresetExportPart />
                <VTubeStudioExportPart />
                <Live2DViewerExportPart />
            </BodyTab>
            <ModelEditor />
            <BodyTab hidden={tab !== CustomizerTab.Motions}>
                <PlayMotionsPart />
            </BodyTab>
            <BodyTab hidden={tab !== CustomizerTab.Expressions}>
                <ToggleExpressionsPart />
            </BodyTab>
            <ModelDefinitionEditor />
        </> : null}
        <BodyTab hidden={tab !== CustomizerTab.Backdrop}>
            <BackdropPart />
        </BodyTab>
        <BodyTab hidden={tab !== CustomizerTab.Vore}>
            <VorePart />
        </BodyTab>
        <BodyTab hidden={tab !== CustomizerTab.VTubeStudio}>
            <VTubeStudioSyncPart />
        </BodyTab>
        <BodyTab hidden={tab !== CustomizerTab.Settings}>
            <SettingsPart />
        </BodyTab>
        <BodyTab hidden={tab !== CustomizerTab.Help}>
            <HelpPart />
        </BodyTab>
    </BodyTabs>
})
