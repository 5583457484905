
export function generateHotkeyID() {
    return new Array(32).fill(0).map(() => Math.floor(Math.random() * 16).toString(16)).join('')
}

interface Vector3 {
    x: number
    y: number
    z: number
}

interface Quaternion {
    x: number
    y: number
    z: number
    w: number
}

interface Color {
    r: number
    g: number
    b: number
    a: number
}

interface Position {
    X: number
    Y: number
    Z: number
    Rotation: number
}

interface StringFloatTuple {
    ID: string
    Value: number
}

interface StringStringTuple {
    ID: string
    Value: string
}

export interface VTubeStudioConfigFile {
    Version: number
    Name: string
    ModelID: string
    FileReferences: {
        Icon: string
        Model: string
        IdleAnimation: string
        IdleAnimationWhenTrackingLost: string
    }
    ModelSaveMetadata: {
        LastSavedVTubeStudioVersion: string
        LastSavedPlatform: string
        LastSavedDateUTC: string
        LastSavedDateLocalTime: string
        LastSavedDateUnixMillisecondTimestamp: string
    }
    SavedModelPosition: {
        Position: Vector3
        Rotation: Quaternion
        Scale: Vector3
    }
    ModelPositionMovement: {
        Use: boolean
        X: number
        Y: number
        Z: number
        SmoothingX: number
        SmoothingY: number
        SmoothingZ: number
    }
    ItemSettings: {
        OnlyMoveWhenPinned: boolean
        AllowNormalHotkeyTriggers: boolean
        Multiplier_HeadAngleX: number
        Multiplier_HeadAngleY: number
        Multiplier_HeadAngleZ: number
        Shift_HeadAngleX: number
        Shift_HeadAngleY: number
        Smoothing_HeadAngleX: number
        Smoothing_HeadAngleY: number
        Smoothing_HeadAngleZ: number
    }
    PhysicsSettings: {
        Use: boolean
        UseLegacyPhysics: boolean
        Live2DPhysicsFPS: number
        PhysicsStrength: number
        WindStrength: number
        DraggingPhysicsStrength: number
    }
    GeneralSettings: {
        TimeUntilTrackingLostIdleAnimation: number
        WorkshopSharingForbidden: boolean
    }
    ParameterSettings: {
        Name: string
        Input: string
        InputRangeLower: number
        InputRangeUpper: number
        OutputRangeLower: number
        OutputRangeUpper: number
        ClampInput: boolean
        ClampOutput: boolean
        UseBlinking: boolean
        UseBreathing: boolean
        OutputLive2D: string
        Smoothing: number
        Minimized: boolean
    }[]
    Hotkeys: {
        HotkeyID: string
        Name: string
        Action: string
        File: string
        Position: Position
        ColorOverlay: {
            On: boolean
            IsStaticColor: boolean
            Display: number
            WindowName: string
            IncludeLeft: boolean
            IncludeMid: boolean
            IncludeRight: boolean
            BaseValue: number
            OverlayValue: number
            Smoothing: number
            IncludeItems: boolean
            StaticColor: Color
        }
        ColorScreenMultiplyPreset: {
            ArtMeshMultiplyAndScreenColors: StringStringTuple[]
        }
        HandGestureSettings: {
            GestureLeft: string
            GestureRight: string
            GestureCombinator: string
            AllowMirroredGesture: boolean
            DeactivateExpWhenGestureNotDetected: boolean
            SecondsUntilDetection: number
            SecondsDetected: number
            PercentDetected: number
        }
        LoadModelSettings: {
            LoadAtCurrentPosition: boolean
        }
        Triggers: {
            Trigger1: string
            Trigger2: string
            Trigger3: string
            ScreenButton: number
        }
        IsGlobal: boolean
        IsActive: boolean
        Minimized: boolean
        StopsOnLastFrame: boolean
        DeactivateAfterKeyUp: boolean
        OnlyLoadOneRandomItem: boolean
        DeactivateAfterSeconds: boolean
        DeactivateAfterSecondsAmount: number
        FadeSecondsAmount: number
        OnScreenHotkeyColor: Color
    }[]
    HotkeySettings: {
        UseOnScreenHotkeys: boolean
        UseKeyboardHotkeys: boolean
        SendOnScreenHotkeysToPC: boolean
        OnScreenHotkeyAlpha: number
    }
    ArtMeshDetails: {
        ArtMeshesExcludedFromPinning: string[]
        ArtMeshesThatDeleteItemsOnDrop: string[]
        ArtMeshSceneLightingMultipliers: StringFloatTuple[]
        ArtMeshMultiplyAndScreenColors: StringStringTuple[]
    }
    PhysicsCustomizationSettings: {
        PhysicsMultipliersPerPhysicsGroup: StringFloatTuple[]
        WindMultipliersPerPhysicsGroup: StringFloatTuple[]
        DraggingPhysicsMultipliersPerPhysicsGroup: StringFloatTuple[]
    }
}

export type HotkeyConfig = VTubeStudioConfigFile['Hotkeys'][number]

export const DEFAULT_HOTKEY: HotkeyConfig = {
    HotkeyID: '',
    Name: '',
    Action: '',
    File: '',
    Position: { X: 0, Y: 0, Z: 0, Rotation: 0 },
    ColorOverlay: { On: false, IsStaticColor: false, Display: -1, WindowName: '', IncludeLeft: false, IncludeMid: false, IncludeRight: false, BaseValue: 0, OverlayValue: 0, Smoothing: 0, IncludeItems: false, StaticColor: { r: 0, g: 0, b: 0, a: 1 } },
    ColorScreenMultiplyPreset: { ArtMeshMultiplyAndScreenColors: [] },
    HandGestureSettings: { GestureLeft: '', GestureRight: '', GestureCombinator: 'AND', AllowMirroredGesture: false, DeactivateExpWhenGestureNotDetected: false, SecondsUntilDetection: 0.5, SecondsDetected: 0, PercentDetected: 0 },
    LoadModelSettings: { LoadAtCurrentPosition: true },
    Triggers: { Trigger1: '', Trigger2: '', Trigger3: '', ScreenButton: 1 },
    IsGlobal: true,
    IsActive: true,
    Minimized: false,
    StopsOnLastFrame: false,
    DeactivateAfterKeyUp: false,
    OnlyLoadOneRandomItem: false,
    DeactivateAfterSeconds: false,
    DeactivateAfterSecondsAmount: 10,
    FadeSecondsAmount: 0.5,
    OnScreenHotkeyColor: { r: 1, g: 1, b: 1, a: 1 },
}

export interface VTubeStudioExpressionFile {
    Type: 'Live2D Expression'
    Parameters: {
        Id: string
        Value: `${number}`
        Blend: 'Add'
    }[]
}
