import { Button, Stack, TextField } from '@mui/material'
import { memo, useCallback, useEffect } from 'react'
import { BodyPart } from '../BodyField'
import { useConfirm } from '../Confirm'
import { useModelDefaults } from '../context'
import { CustomizerTab, useStoreDispatch, useStoreSelector } from '../store'
import { GenericModelPreset, isPresetModified, ModelType } from '../models'
import { ImageButton, ImageButtonNew } from '../ModelButton'
import { generateID } from '../utils'

export const PresetPalettePart = memo(function PresetPalettePart() {
    const preset = useStoreSelector(s => s.preset.current)
    const presets = useStoreSelector(s => preset.type === ModelType.Unknown ? s.presetLists.unknownModels[preset.fileName] : s.presetLists.knownModels[preset.type])
    const modelDefaults = useModelDefaults()
    const dispatch = useStoreDispatch()
    const { confirm } = useConfirm()

    useEffect(() => {
        if (isPresetModified(modelDefaults, preset)) {
            dispatch(a => a.upsertPreset(preset))
        }
    }, [dispatch, modelDefaults, preset])

    const onReset = useCallback(() => {
        confirm('This will change all of your colors, parameter values, and other customizations back to their original defaults.', { title: 'Reset all changes?' }).then(success => {
            if (success && modelDefaults) {
                dispatch(a => a.loadPreset({ value: { ...modelDefaults, id: preset.id, name: preset.name, fileName: preset.fileName, updated: Date.now() }, reason: 'Reset to Default Preset' }))
            }
        })
    }, [confirm, dispatch, modelDefaults, preset])

    const onLoadPreset = useCallback((p: GenericModelPreset) => {
        if (p.id && preset.id && p.id === preset.id) return
        if (isPresetModified(modelDefaults, preset)) {
            dispatch(a => a.upsertPreset(preset))
        }
        dispatch(a => a.loadPreset({ value: p, reason: 'Loaded Preset' }))
    }, [dispatch, modelDefaults, preset])

    const onDeletePreset = useCallback((p: GenericModelPreset) => {
        if (isPresetModified(modelDefaults, p)) {
            confirm('This preset has been modified. If you proceed, you will lose all colors, parameter values, and other customizations applied to this preset. This action cannot be undone.', { title: 'Delete preset?' }).then(confirmed => {
                if (confirmed) {
                    dispatch(a => a.removePreset(p))
                }
            })
        } else {
            dispatch(a => a.removePreset(p))
        }
    }, [confirm, dispatch, modelDefaults])

    const onRenamePreset = useCallback((name: string) => {
        dispatch(a => a.renamePreset({ name }))
        dispatch(a => a.upsertPreset({ ...preset, name }))
    }, [dispatch, preset])

    const onCreatePreset = useCallback(() => {
        if (isPresetModified(modelDefaults, preset)) dispatch(a => a.upsertPreset(preset))
        const newPreset = { ...modelDefaults, id: generateID(), name: '', fileName: preset.fileName, updated: Date.now() }
        dispatch(a => a.upsertPreset(newPreset))
        dispatch(a => a.loadPreset({ value: newPreset, reason: 'Created New Preset' }))
    }, [dispatch, modelDefaults, preset])

    return <BodyPart label='Presets'>
        <Button onClick={onReset}>Reset to Default Preset</Button>
        <Stack direction='row' flexWrap='wrap' spacing={1} pb={1}>
            {presets?.map(p => <ImageButton key={p.id} active={p.id === preset.id} size='small' label={p.name ? p.name : 'Untitled Preset'} src={`/ModelIcons/${p.type}.png`} onClick={() => onLoadPreset(p)} onDelete={() => onDeletePreset(p)} />)}
            <ImageButtonNew size='small' label='Create New Preset' onClick={onCreatePreset} />
        </Stack>
        <TextField size='small' label='Preset Name' value={preset.name} onChange={e => onRenamePreset(e.target.value)} />
        <Stack direction='row' spacing={1}>
            <Button onClick={() => dispatch(a => a.setAppSetting({ key: 'tab', value: CustomizerTab.Import }))}>Import Preset</Button>
            <Button onClick={() => dispatch(a => a.setAppSetting({ key: 'tab', value: CustomizerTab.Export }))}>Export Preset</Button>
        </Stack>
    </BodyPart>
})
