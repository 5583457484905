import { Stack, Slider, Alert } from '@mui/material'
import { memo, useCallback, useMemo, useRef } from 'react'
import { BodyColor, BodyToggle, BodyChoice, BodyClothing, BodySlider, BodyLink, BodyPart, BodyField, BodyTab, BodyDecal } from './BodyField'
import { ColorPicker } from './ColorPicker'
import { useModel, useModelDefinition } from './context'
import { GenericModelField, GenericModelPart, GenericModelTab } from './models'
import { CustomizerTab, useStoreDispatch, useStoreSelector } from './store'
import { formatNumber, tuple } from './utils'
import { ResetButton } from './ResetButton'

const ModelField = memo(function ModelField({ field }: { field: GenericModelField }) {
    const visible = useStoreSelector(s => {
        let visible = true
        if (!field.conditions) return visible
        for (const condition of field.conditions) {
            let conditionResult = false
            switch (condition.type) {
                case 'link':
                    conditionResult = s.preset.current.links[condition.key] === condition.value
                    break
                case 'choice':
                    conditionResult = s.preset.current.choices[condition.key] === condition.value
                    break
                case 'toggle':
                    conditionResult = s.preset.current.toggles[condition.key] === condition.value
                    break
                case 'clothing':
                    conditionResult = s.preset.current.clothing[condition.key] === condition.value
                    break
            }
            if (condition.invert) conditionResult = !conditionResult
            visible = visible && conditionResult
            if (!visible) return visible
        }
        return visible
    })
    return <BodyField label={field.name} hidden={!visible}>
        {field.type === 'color' ? <>
            <BodyColor label={field.name} colorKey={field.key} />
        </> : field.type === 'toggle' ? <>
            <BodyToggle label={field.name} toggleKey={field.key} invert={field.invert} />
        </> : field.type === 'choice' ? <>
            <BodyChoice label={field.name} choiceKey={field.key} />
        </> : field.type === 'clothing' ? <>
            <BodyClothing label={field.name} clothingKey={field.key} />
        </> : field.type === 'slider' ? <>
            <BodySlider label={field.name} sliderKey={field.key} />
        </> : field.type === 'link' ? <>
            <BodyLink label={field.name} linkKey={field.key} invert={field.invert} />
        </> : field.type === 'decal' ? <>
            <BodyDecal label={field.name} decalKey={field.key} />
        </> : null}
    </BodyField>
})

const ModelPart = memo(function ModelPart({ part }: { part: GenericModelPart }) {
    return <BodyPart label={part.name}>
        {part.fields.map(field => <ModelField key={field.name} field={field} />)}
    </BodyPart>
})

const ModelTab = memo(function ModelTab({ tab, sharedParts }: { tab: GenericModelTab, sharedParts: GenericModelPart[] }) {
    const tabV = useStoreSelector(s => s.settings.tab)
    const index = useModelDefinition().tabs.indexOf(tab)
    return <>
        <BodyTab hidden={tabV !== CustomizerTab.Custom + index}>
            {sharedParts.map(part => <ModelPart key={part.name} part={part} />)}
            {tab.parts.map(part => <ModelPart key={part.name} part={part} />)}
        </BodyTab>
    </>
})

const OtherArtMesh = memo(function OtherArtMesh({ artMesh, displayName }: { artMesh: string, displayName?: string }) {
    const value = useStoreSelector(s => s.preset.current.unmappedArtMeshColors[artMesh])
    const dispatch = useStoreDispatch()

    const multiplyValue = value?.[0] ?? 'FFFFFF'
    const screenValue = value?.[1] ?? '000000'

    const setValue = useCallback((value: [string, string] | undefined, reason: string | null) => {
        dispatch(a => a.updateUnmappedArtMeshColor({ key: artMesh, value, reason }))
    }, [artMesh, dispatch])

    const resetValue = useCallback(() => {
        if (value === undefined) return
        setValue(['FFFFFF', '000000'], null)
        setTimeout(() => {
            setValue(undefined, `Remove Override (${artMesh})`)
        }, 1)
    }, [artMesh, setValue, value])

    const updateMultiplyValue = useCallback((v: string) => {
        setValue([v, screenValue], null)
    }, [screenValue, setValue])

    const finalizeMultiplyValue = useCallback((newValue: string, oldValue: string) => {
        setValue([oldValue, screenValue], null)
        if (newValue !== oldValue) {
            setValue([newValue, screenValue], `Set Multiply Color (${artMesh})`)
        }
    }, [artMesh, screenValue, setValue])

    const updateScreenValue = useCallback((v: string) => {
        setValue([multiplyValue, v], null)
    }, [multiplyValue, setValue])

    const finalizeScreenValue = useCallback((newValue: string, oldValue: string) => {
        setValue([multiplyValue, oldValue], null)
        if (newValue !== oldValue) {
            setValue([multiplyValue, newValue], `Set Screen Color (${artMesh})`)
        }
    }, [artMesh, multiplyValue, setValue])

    return <BodyField label={displayName ? `${displayName} (${artMesh})` : artMesh}>
        <Stack direction='row' spacing={1}>
            <ColorPicker group={artMesh} label='Multiply' value={multiplyValue} setValue={updateMultiplyValue} setValueCommitted={finalizeMultiplyValue} />
            <ColorPicker group={artMesh} label='Screen' value={screenValue} setValue={updateScreenValue} setValueCommitted={finalizeScreenValue} />
            <ResetButton changed={value !== undefined} onClick={resetValue} label='Remove Color Override' />
        </Stack>
    </BodyField>
})

const OtherParameter = memo(function OtherParameter({ parameter, displayName }: { parameter: string, displayName?: string }) {
    const model = useModel()
    const definition = model.parameterDefinitions[parameter]
    const maxValue = definition?.maxValue ?? 1
    const minValue = definition?.minValue ?? 0
    const initialValue = definition?.defaultValue ?? 0
    const value = useStoreSelector(s => s.preset.current.unmappedParameterValues[parameter])
    const dispatch = useStoreDispatch()
    const initialDragValueRef = useRef<number | undefined | null>(null)

    const setValue = useCallback((value: number | undefined, reason: string | null) => {
        dispatch(a => a.updateUnmappedParameterValue({ key: parameter, value, reason }))
    }, [dispatch, parameter])

    const resetValue = useCallback(() => {
        if (value === undefined) return
        setValue(undefined, null)
        setTimeout(() => {
            setValue(undefined, `Remove Override (${parameter})`)
        }, 1)
    }, [parameter, setValue, value])

    const updateSliderValue = useCallback((v: number) => {
        if (initialDragValueRef.current === null) {
            initialDragValueRef.current = value
        }
        setValue(v, null)
    }, [setValue, value])

    const finalizeSliderValue = useCallback((v: number) => {
        if (initialDragValueRef.current !== null) {
            setValue(initialDragValueRef.current, null)
            initialDragValueRef.current = null
        }
        setValue(v, `Set Slider (${parameter})`)
    }, [parameter, setValue])

    const marks = [
        { value: minValue ?? 0, label: formatNumber(minValue ?? 0) },
        { value: maxValue ?? 1, label: formatNumber(maxValue ?? 1) },
    ].concat((value ?? initialValue) !== minValue && (value ?? initialValue) !== maxValue ? [
        { value: value ?? initialValue, label: formatNumber(value ?? initialValue) },
    ] : [])

    return <BodyField label={displayName ? `${displayName} (${parameter})` : parameter}>
        <Stack direction='row' spacing={2}>
            <Slider value={value ?? initialValue} min={minValue} max={maxValue} step={0.000001} marks={marks} onChangeCommitted={(_, v) => finalizeSliderValue(Array.isArray(v) ? v[0] : v)} onChange={(_, v) => updateSliderValue(Array.isArray(v) ? v[0] : v)} />
            <ResetButton changed={value !== undefined} onClick={resetValue} label='Remove Parameter Override' />
        </Stack>
    </BodyField>
})

const OtherTab = memo(function OtherTab() {
    const tab = useStoreSelector(s => s.settings.tab)
    const model = useModel()
    const modelDefinition = useModelDefinition()

    const otherArtMeshes = useMemo(() => {
        return Object.keys(model.drawableIds)
            .filter(k => !Object.values(modelDefinition.colorArtMeshes).some(c => c.includes(k)))
    }, [model, modelDefinition])

    const otherParams = useMemo(() => {
        return Object.keys(model.parameterIds)
            .filter(k => !Object.values(modelDefinition.toggleParams).some(p => p.includes(k)))
            .filter(k => !Object.values(modelDefinition.choiceParams).some(p => p.includes(k)))
            .filter(k => !Object.values(modelDefinition.sliderParams).some(p => p.includes(k)))
            .filter(k => !Object.values(modelDefinition.clothingParams).flatMap(c => Object.values(c)).some(p => p.includes(k)))
    }, [model, modelDefinition])

    const tags = useMemo(() => new Set(model.userDataJson?.UserData?.flatMap(d => d.Value.split(' '))), [model.userDataJson?.UserData])

    const tagGroups = useMemo(() => new Map([...tags.keys()].map(t => tuple(t, model.userDataJson?.UserData.filter(d => d.Value.split(' ').includes(t)).map(d => d.Id)))), [model.userDataJson?.UserData, tags])

    const groups = useMemo(() => new Map(model.displayInfoJson?.ParameterGroups.map(g => tuple(g.Name, model.displayInfoJson?.Parameters?.filter(p => p.GroupId === g.Id).map(p => p.Id)))), [model.displayInfoJson?.ParameterGroups, model.displayInfoJson?.Parameters])

    const artMeshDisplayNames = useMemo(() => new Map(model.displayInfoJson?.Parts.map(p => tuple(p.Id, p.Name))), [model.displayInfoJson?.Parts])

    const paramDisplayNames = useMemo(() => new Map(model.displayInfoJson?.Parameters?.map(p => tuple(p.Id, p.Name))), [model.displayInfoJson?.Parameters])

    return <>
        <BodyTab hidden={tab !== CustomizerTab.Other}>
            <Alert severity='warning'>The art meshes and parameters listed here are not mapped and may be overridden or removed in future updates to the model or app. You may override their colors or values below as part of the current preset.</Alert>
            {[...tagGroups.entries()].map(([g, l]) => <BodyPart key={g} label={g}>
                {l?.filter(m => otherArtMeshes?.some(o => o === m)).map(m => <OtherArtMesh key={m} artMesh={m} displayName={artMeshDisplayNames.get(m)} />)}
            </BodyPart>)}
            <BodyPart label='Untagged Art Meshes' hidden={!tagGroups.size}>
                {otherArtMeshes?.filter(m => ![...tagGroups.values()].some(g => g?.includes(m))).map(m => <OtherArtMesh key={m} artMesh={m} displayName={artMeshDisplayNames.get(m)} />)}
            </BodyPart>
            <BodyPart label='Other Art Meshes' hidden={!!tagGroups.size}>
                {otherArtMeshes?.map(m => <OtherArtMesh key={m} artMesh={m} displayName={artMeshDisplayNames.get(m)} />)}
            </BodyPart>
            {[...groups.entries()].map(([g, l]) => <BodyPart key={g} label={g}>
                {l?.filter(p => otherParams?.some(o => o === p)).map(p => <OtherParameter key={p} parameter={p} displayName={paramDisplayNames.get(p)} />)}
            </BodyPart>)}
            <BodyPart label='Ungrouped Parameters' hidden={!groups.size}>
                {otherParams?.filter(p => ![...groups.values()].some(g => g?.includes(p))).map(p => <OtherParameter key={p} parameter={p} displayName={paramDisplayNames.get(p)} />)}
            </BodyPart>
            <BodyPart label='Other Parameters' hidden={!!groups.size}>
                {otherParams?.map(p => <OtherParameter key={p} parameter={p} displayName={paramDisplayNames.get(p)} />)}
            </BodyPart>
        </BodyTab>
    </>
})

export const ModelEditor = memo(function Model() {
    const definition = useModelDefinition()
    return <>
        {definition.tabs.map((t, i) => <ModelTab key={t.name} tab={t} sharedParts={definition.sharedParts.filter(p => !p.tabs || p.tabs.includes(t.name))} />)}
        <OtherTab />
    </>
})
