import { createModel, enumToClothingOptions, GenericModelStaticData, ModelType } from './common'

enum ArmPoseType {
    Up = 0,
    Wave = 1,
    Talk = 2,
}

export const FLYNN_MODEL: GenericModelStaticData = createModel(ModelType.Flynn_P_V5, {
    type: ModelType.Flynn_P_V5,
    colors: {},
    links: {},
    toggles: {
        expressionAngry: false,
        tongueOut: false,
        flexAll: false,
        flexPecLeft: false,
        flexPecRight: false,
        flexBicepLeft: false,
        flexBicepRight: false,
        flexAbs: false,
        thongOff: false,
        dick2On: false,
        submerge: false,
        waterOff: false,
    },
    choices: {},
    clothing: {
        armPose: ArmPoseType.Up,
    },
    sliders: {
        blush: 0,
        armWave: 0,
        muscleSize: 0,
        dickSize: 0,
        ballSize: 0,
        dickErection: 0,
        wetness: 0,
    },
    unmappedArtMeshColors: {},
    unmappedParameterValues: {},
    motions: [],
    expressions: [],
    decals: {},
}, {
    type: ModelType.Flynn_P_V5,
    possibleModelFiles: [
        'HSG Game Asset -  Flynn (Vtube Studio)/HSG Characters Live2D Set1 Flynn P v5.model3.json',
        'HSG Characters Live2D Set1 Flynn P v5.model3.json',
    ],
    sharedParts: [],
    tabs: [{
        name: 'Options',
        parts: [{
            name: 'Pose',
            fields: [{
                name: 'Submerge',
                type: 'toggle',
                key: 'submerge',
            }, {
                name: 'Water On',
                type: 'toggle',
                key: 'waterOff',
                invert: true,
            }, {
                name: 'Wetness',
                type: 'slider',
                key: 'wetness',
            }, {
                name: 'Arm Pose',
                type: 'clothing',
                key: 'armPose',
            }, {
                name: 'Wave',
                type: 'slider',
                key: 'armWave',
                conditions: [{ type: 'clothing', key: 'armPose', value: ArmPoseType.Wave }],
            }],
        }, {
            name: 'Expression',
            fields: [{
                name: 'Angry',
                type: 'toggle',
                key: 'expressionAngry',
            }, {
                name: 'Tongue Out',
                type: 'toggle',
                key: 'tongueOut',
            }, {
                name: 'Blush',
                type: 'slider',
                key: 'blush',
            }]
        }, {
            name: 'Muscles',
            fields: [{
                name: 'Muscles',
                type: 'slider',
                key: 'muscleSize',
            }, {
                name: 'Flex All',
                type: 'toggle',
                key: 'flexAll',
            }, {
                name: 'Flex Left Bicep',
                type: 'toggle',
                key: 'flexBicepLeft',
            }, {
                name: 'Flex Right Bicep',
                type: 'toggle',
                key: 'flexBicepRight',
            }, {
                name: 'Flex Left Pec',
                type: 'toggle',
                key: 'flexPecLeft',
            }, {
                name: 'Flex Right Pec',
                type: 'toggle',
                key: 'flexPecRight',
            }, {
                name: 'Flex Abs',
                type: 'toggle',
                key: 'flexAbs',
            }],
        }, {
            name: 'Dick',
            fields: [{
                name: 'Thong On',
                type: 'toggle',
                key: 'thongOff',
                invert: true,
            }, {
                name: 'Second Dick',
                type: 'toggle',
                key: 'dick2On',
                conditions: [{ type: 'toggle', key: 'thongOff', value: true }],
            }, {
                name: 'Dick Size',
                type: 'slider',
                key: 'dickSize',
                conditions: [{ type: 'toggle', key: 'thongOff', value: true }],
            }, {
                name: 'Ball Size',
                type: 'slider',
                key: 'ballSize',
                conditions: [{ type: 'toggle', key: 'thongOff', value: true }],
            }, {
                name: 'Dick Erection',
                type: 'slider',
                key: 'dickErection',
                conditions: [{ type: 'toggle', key: 'thongOff', value: true }],
            }],
        }],
    }],
    choiceOptions: {},
    clothingOptions: {
        armPose: enumToClothingOptions(ArmPoseType),
    },
    links: {},
    colorArtMeshes: {},
    toggleParams: {
        expressionAngry: ['ParamBlush2'],
        tongueOut: ['ParamBlush5'],
        flexAll: ['ParamFlexPekL6'],
        flexPecLeft: ['ParamFlexPekL4'],
        flexPecRight: ['ParamFlexPekL8'],
        flexBicepLeft: ['ParamFlexPekL10'],
        flexBicepRight: ['ParamFlexPekL9'],
        flexAbs: ['ParamFlexPekL11'],
        thongOff: ['ParamDick'],
        dick2On: ['ParamDick2'],
        submerge: ['ParamWet2'],
        waterOff: ['ParamWet3'],
    },
    choiceParams: {},
    clothingParams: {
        armPose: {
            [ArmPoseType.Up]: [],
            [ArmPoseType.Wave]: ['ParamBlush3'],
            [ArmPoseType.Talk]: ['ParamBlush4'],
        },
    },
    sliderParams: {
        blush: ['ParamBlush'],
        armWave: ['ParamBlush6'],
        muscleSize: ['ParamMSize'],
        dickSize: ['ParamPSize'],
        ballSize: ['ParamPSize2'],
        dickErection: ['ParamDickFLex'],
        wetness: ['ParamWet'],
    },
    smoothParams: {
        'ParamMSize': 5,
        'ParamPSize': 5,
        'ParamPSize2': 5,
        'ParamWet2': .25,
    },
    staticArtMeshColors: {},
    staticParameterValues: {},
    decals: {},
})
