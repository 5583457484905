import { createModel, enumToChoiceOptions, GenericModelStaticData, ModelType } from './common'

enum ColorType {
    Green = 0,
    Purple = 0.5,
    White = 1,
}

enum HairType {
    Short = 0,
    Ponytail = 0.5,
    Long = 1,
}

enum LowerType {
    Underwear = 0,
    Bulge = 0.5,
    Dick = 1,
}

enum HornType {
    None = 0,
    Short = 1,
    Long = 2,
}

export const PATH_BANDIT_MODEL: GenericModelStaticData = createModel(ModelType.PathBandit_V10, {
    type: ModelType.PathBandit_V10,
    colors: {},
    links: {},
    toggles: {
        fistArmOff: false,
        shoulderArmorOff: false,
        breastsOff: false,
        altLips: false,
    },
    choices: {
        colorType: ColorType.Green,
        hairType: HairType.Short,
        lowerType: LowerType.Underwear,
        hornType: HornType.Short,
    },
    clothing: {},
    sliders: {
        breastSize: 15,
        thighSize: 0,
        dickSize: 0,
        ballSize: 0,
        dickErection: 0,
        lipsSize: 0,
    },
    unmappedArtMeshColors: {},
    unmappedParameterValues: {},
    motions: [],
    expressions: [],
    decals: {},
}, {
    type: ModelType.PathBandit_V10,
    possibleModelFiles: [
        'Path Bandit - Hyperspace Genotype (Vtube Studio)/HSG Characters Live2D Set 2 Path Bandit v10.model3.json',
        'HSG Characters Live2D Set 2 Path Bandit v10.model3.json',
    ],
    sharedParts: [],
    tabs: [{
        name: 'Options',
        parts: [{
            name: 'Overall',
            fields: [{
                name: 'Color',
                type: 'choice',
                key: 'colorType',
            }],
        }, {
            name: 'Head',
            fields: [{
                name: 'Hair Type',
                type: 'choice',
                key: 'hairType',
            }, {
                name: 'Horns Type',
                type: 'choice',
                key: 'hornType',
            }],
        }, {
            name: 'Face',
            fields: [{
                name: 'Thick Lips',
                type: 'toggle',
                key: 'altLips',
            }, {
                name: 'Lips Size',
                type: 'slider',
                key: 'lipsSize',
                conditions: [{ type: 'toggle', key: 'altLips', value: true }],
            }],
        }, {
            name: 'Chest',
            fields: [{
                name: 'Breasts On',
                type: 'toggle',
                key: 'breastsOff',
                invert: true,
            }, {
                name: 'Breast Size',
                type: 'slider',
                key: 'breastSize',
                conditions: [{ type: 'toggle', key: 'breastsOff', value: false }],
            }],
        }, {
            name: 'Arms',
            fields: [{
                name: 'Shoulder Armor On',
                type: 'toggle',
                key: 'shoulderArmorOff',
                invert: true,
            }, {
                name: 'Power Fist On',
                type: 'toggle',
                key: 'fistArmOff',
                invert: true,
            }]
        }, {
            name: 'Hips',
            fields: [{
                name: 'Lower',
                type: 'choice',
                key: 'lowerType',
            }, {
                name: 'Dick Size',
                type: 'slider',
                key: 'dickSize',
                conditions: [{ type: 'choice', key: 'lowerType', value: LowerType.Underwear, invert: true }],
            }, {
                name: 'Balls Size',
                type: 'slider',
                key: 'ballSize',
                conditions: [{ type: 'choice', key: 'lowerType', value: LowerType.Underwear, invert: true }],
            }, {
                name: 'Dick Erection',
                type: 'slider',
                key: 'dickErection',
                conditions: [{ type: 'choice', key: 'lowerType', value: LowerType.Underwear, invert: true }],
            }],
        }, {
            name: 'Hips',
            fields: [{
                name: 'Thigh Size',
                type: 'slider',
                key: 'thighSize',
            }],
        }],
    }],
    choiceOptions: {
        colorType: enumToChoiceOptions(ColorType),
        hairType: enumToChoiceOptions(HairType),
        lowerType: enumToChoiceOptions(LowerType),
        hornType: enumToChoiceOptions(HornType),
    },
    clothingOptions: {},
    links: {},
    colorArtMeshes: {},
    toggleParams: {
        fistArmOff: ['ParamExp4'],
        shoulderArmorOff: ['ParamExp8'],
        breastsOff: ['ParamExp5'],
        altLips: ['ParamLips'],
    },
    choiceParams: {
        colorType: ['ParamExp'],
        hairType: ['ParamExp3'],
        lowerType: ['ParamExp7'],
        hornType: ['ParamExp6'],
    },
    clothingParams: {},
    sliderParams: {
        breastSize: ['ParamBSize'],
        thighSize: ['ParamHSize'],
        dickSize: ['ParamPSize'],
        ballSize: ['ParamPSize2'],
        dickErection: ['ParamPDrive'],
        lipsSize: ['ParamLSize'],
    },
    smoothParams: {
        'ParamBSize': 5,
        'ParamHSize': 5,
        'ParamPSize': 5,
        'ParamPSize2': 5,
    },
    staticArtMeshColors: {},
    staticParameterValues: {},
    decals: {},
})
