import { Typography, Button, Select, MenuItem, TextField } from '@mui/material'
import JSZip from 'jszip'
import { memo, useState, useCallback, useMemo } from 'react'
import { BodyPart, BodyField } from '../BodyField'
import { useConfirm } from '../Confirm'
import { useModel } from '../context'
import { useComputedModelParams } from '../ModelWrapper'
import { JsonFileDropZone, JsonFileDropZoneResult } from '../FileDropZone'
import { deserializeJson, generateFileTimestamp, tuple } from '../utils'
import { VTubeStudioConfigFile, DEFAULT_HOTKEY, generateHotkeyID, VTubeStudioExpressionFile } from '../vtubestudio'
import { useErrorHandler } from '../error'

enum VTubeStudioGlobalColorSetting {
    Ignore = 'ignore',
    Clear = 'clear',
    Replace = 'replace',
}

export const VTubeStudioExportPart = memo(function VTubeStudioExportPart() {
    const model = useModel()
    const addError = useErrorHandler()
    const { confirm } = useConfirm()
    const [vtubeStudioConfig, setVTubeStudioConfig] = useState<VTubeStudioConfigFile | null>(null)
    const [vtubeStudioConfigJson, setVTubeStudioConfigJson] = useState<string | null>(null)
    const [colorHotkeyId, setColorHotkeyId] = useState<string | null>(null)
    const [newColorHotkeyName, setNewColorHotkeyName] = useState<string>('CustomizerColors')
    const [expressionHotkeyId, setExpressionHotkeyId] = useState<string | null>(null)
    const [newExpressionHotkeyName, setNewExpressionHotkeyName] = useState<string>('CustomizerExpression')
    const [globalColorSetting, setGlobalColorSetting] = useState<VTubeStudioGlobalColorSetting>(VTubeStudioGlobalColorSetting.Ignore)
    const [exporting, setExporting] = useState(false)

    const NEW_VALUE = 'new'

    const onUploadFailed = useCallback((reason: any, fileName: string) => {
        addError(reason)
    }, [addError])

    const onUploadConfigJson = useCallback(({ json }: JsonFileDropZoneResult) => {
        const config = deserializeJson<VTubeStudioConfigFile>(json)
        if (!config || !('Version' in config) || !('ArtMeshDetails' in config)) {
            addError(new Error('The uploaded VTube Studio configuration file was not in the correct format'))
            setVTubeStudioConfig(null)
            setVTubeStudioConfigJson(null)
            return
        }
        if (config.FileReferences.Model !== model.modelName) {
            confirm('The uploaded VTube Studio configuration file did not match the currently loaded model. Load it anyway?', { title: 'Load configuration anyway?' }).then(confirmed => {
                if (confirmed) {
                    setVTubeStudioConfig(config)
                    setVTubeStudioConfigJson(json)
                }
            })
            setVTubeStudioConfig(null)
            setVTubeStudioConfigJson(null)
            return
        }
        setVTubeStudioConfig(config)
        setVTubeStudioConfigJson(json)
    }, [addError, confirm, model.modelName])

    const unloadConfig = useCallback(() => {
        setVTubeStudioConfig(null)
        setVTubeStudioConfigJson(null)
    }, [])

    const colorHotkeyOptions = useMemo(() => {
        if (!vtubeStudioConfig) return null
        return vtubeStudioConfig.Hotkeys.filter(h => h.Action === 'ArtMeshColorPreset').map(h => tuple(h.HotkeyID, h.Name))
    }, [vtubeStudioConfig])

    const expressionHotkeyOptions = useMemo(() => {
        if (!vtubeStudioConfig) return null
        return vtubeStudioConfig.Hotkeys.filter(h => h.Action === 'ToggleExpression').map(h => tuple(h.HotkeyID, h.Name, h.File))
    }, [vtubeStudioConfig])

    const { multiplyColors, screenColors, parameterValues } = useComputedModelParams()

    const onExportConfig = useCallback(() => {
        try {
            if (!vtubeStudioConfig || !vtubeStudioConfigJson || ((!colorHotkeyId && globalColorSetting !== VTubeStudioGlobalColorSetting.Replace) && !expressionHotkeyId)) return
            setExporting(true)

            const colorPartToHex = (n: number) => Math.round(n * 255).toString(16).padStart(2, '0').toUpperCase()
            const colorToHex = (c: { r: number, g: number, b: number } | undefined) => c ? `${colorPartToHex(c.r)}${colorPartToHex(c.g)}${colorPartToHex(c.b)}` : c

            const colors = [...new Set([...Object.keys(multiplyColors), ...Object.keys(screenColors)]).keys()].map(k => ({ ID: k, Value: `${colorToHex(multiplyColors[k]) ?? 'FFFFFF'}|${colorToHex(screenColors[k]) ?? '000000'}` }))

            const expressionFileName = expressionHotkeyId ? vtubeStudioConfig.Hotkeys.find(h => h.HotkeyID === expressionHotkeyId)?.File ?? `${newExpressionHotkeyName}.exp3.json` : ''

            const modifiedConfig: VTubeStudioConfigFile = {
                ...vtubeStudioConfig,
                ...(globalColorSetting === VTubeStudioGlobalColorSetting.Replace ? {
                    ArtMeshDetails: { ...vtubeStudioConfig.ArtMeshDetails, ArtMeshMultiplyAndScreenColors: colors },
                } : globalColorSetting === VTubeStudioGlobalColorSetting.Clear ? {
                    ArtMeshDetails: { ...vtubeStudioConfig.ArtMeshDetails, ArtMeshMultiplyAndScreenColors: [] },
                } : {}),
                Hotkeys: vtubeStudioConfig.Hotkeys
                    .filter(h => h.HotkeyID !== colorHotkeyId && h.HotkeyID !== expressionHotkeyId)
                    .concat(globalColorSetting !== VTubeStudioGlobalColorSetting.Replace && colorHotkeyId ? [
                        {
                            ...DEFAULT_HOTKEY,
                            ...vtubeStudioConfig.Hotkeys.find(h => h.HotkeyID === colorHotkeyId),
                            HotkeyID: colorHotkeyId === NEW_VALUE ? generateHotkeyID() : colorHotkeyId,
                            Action: 'ArtMeshColorPreset',
                            ColorScreenMultiplyPreset: { ArtMeshMultiplyAndScreenColors: colors },
                            ...(colorHotkeyId === NEW_VALUE ? {
                                Name: newColorHotkeyName,
                            } : {}),
                        }] : [])
                    .concat(expressionHotkeyId ? [
                        {
                            ...DEFAULT_HOTKEY,
                            ...vtubeStudioConfig.Hotkeys.find(h => h.HotkeyID === expressionHotkeyId),
                            HotkeyID: expressionHotkeyId === NEW_VALUE ? generateHotkeyID() : expressionHotkeyId,
                            Action: 'ToggleExpression',
                            ...(expressionHotkeyId === NEW_VALUE ? {
                                Name: newExpressionHotkeyName,
                                File: expressionFileName,
                            } : {}),
                        },
                    ] : [])
            }

            const configJson = JSON.stringify(modifiedConfig, null, 4)

            const zip = new JSZip()
            zip.file(`${vtubeStudioConfig.Name}.vtube.json.${generateFileTimestamp()}.bak`, vtubeStudioConfigJson)
            zip.file(`${vtubeStudioConfig.Name}.vtube.json`, configJson)

            if (expressionHotkeyId) {
                const params = Object.entries(parameterValues).map(([k, v]) => ({ Id: k, Value: String(v) as `${number}`, Blend: 'Add' as const }))

                const expression: VTubeStudioExpressionFile = {
                    Type: 'Live2D Expression',
                    Parameters: params,
                }

                const expressionJson = JSON.stringify(expression, null, 4)

                zip.file(`${expressionFileName}`, expressionJson)
            }

            zip.generateAsync({ type: 'blob' }).then(blob => {
                const url = URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                a.download = `Modified VTube Studio Configuration.zip`
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
                setExporting(false)

                setTimeout(() => {
                    URL.revokeObjectURL(url)
                }, 60 * 1000)
            }).catch(e => {
                addError(e)
                setExporting(false)
            })
        } catch (e) {
            addError(e as Error)
            setExporting(false)
        }
    }, [addError, colorHotkeyId, expressionHotkeyId, globalColorSetting, multiplyColors, newColorHotkeyName, newExpressionHotkeyName, parameterValues, screenColors, vtubeStudioConfig, vtubeStudioConfigJson])

    return <BodyPart label='Export to VTube Studio'>
        {vtubeStudioConfig ? <>
            <BodyField label='Loaded Configuration File'>
                <Typography variant='body2'>{vtubeStudioConfig.Name}</Typography>
                <Button onClick={unloadConfig}>Unload Configuration File</Button>
            </BodyField>
            <BodyField label='Existing Model Colors'>
                <Typography variant='body2'>Choose how you want the existing colors set in VTube Studio to be handled:</Typography>
                <Select size='small' value={globalColorSetting} onChange={e => setGlobalColorSetting(e.target.value as VTubeStudioGlobalColorSetting)}>
                    <MenuItem value={VTubeStudioGlobalColorSetting.Ignore}>Leave Alone</MenuItem>
                    <MenuItem value={VTubeStudioGlobalColorSetting.Clear}>Clear Out</MenuItem>
                    <MenuItem value={VTubeStudioGlobalColorSetting.Replace}>Replace</MenuItem>
                </Select>
            </BodyField>
            {globalColorSetting !== VTubeStudioGlobalColorSetting.Replace ? <>
                <BodyField label='Color Preset Hotkey'>
                    <Typography variant='body2'>Choose a hotkey to override colors for (or create a new one)</Typography>
                    <Select size='small' value={colorHotkeyId ?? ''} onChange={e => setColorHotkeyId(e.target.value ? e.target.value : null)}>
                        <MenuItem value=''>None (No Color Export)</MenuItem>
                        <MenuItem value={NEW_VALUE}>Create New...</MenuItem>
                        {colorHotkeyOptions?.map(([id, name]) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
                    </Select>
                </BodyField>
                {colorHotkeyId === NEW_VALUE ? <BodyField label='New Color Hotkey Name'>
                    <TextField size='small' value={newColorHotkeyName} onChange={e => setNewColorHotkeyName(e.target.value)} />
                </BodyField> : null}
            </> : null}
            <BodyField label='Expression Hotkey'>
                <Typography variant='body2'>Choose a hotkey to override the expression for (or create a new one)</Typography>
                <Select size='small' value={expressionHotkeyId ?? ''} onChange={e => setExpressionHotkeyId(e.target.value ? e.target.value : null)}>
                    <MenuItem value=''>None (No Expression Export)</MenuItem>
                    <MenuItem value={NEW_VALUE}>Create New...</MenuItem>
                    {expressionHotkeyOptions?.map(([id, name, file]) => <MenuItem key={id} value={id}>{name} ({file})</MenuItem>)}
                </Select>
            </BodyField>
            {expressionHotkeyId === NEW_VALUE ? <BodyField label='New Expression Hotkey Name'>
                <TextField size='small' value={newExpressionHotkeyName} onChange={e => setNewExpressionHotkeyName(e.target.value)} />
            </BodyField> : null}
            <BodyField label='Export Configuration File'>
                <Button disabled={!vtubeStudioConfig || ((!colorHotkeyId && globalColorSetting !== VTubeStudioGlobalColorSetting.Replace) && !expressionHotkeyId) || exporting} onClick={onExportConfig}>Download Modified Configuration File</Button>
            </BodyField>
        </> : <>
            <Typography variant='body2'>Select the VTube Studio .vtube.json file that you want to give a hotkey and expression for your current color and slider preset.</Typography>
            <JsonFileDropZone fileDesc='your model&apos;s .vtube.json file from VTube Studio' onUpload={onUploadConfigJson} onUploadFailed={onUploadFailed} />
        </>}
    </BodyPart>
})
