import { Button } from '@mui/material'
import { memo, useMemo } from 'react'
import { BodyField, BodyPart } from '../BodyField'
import { KeyboardShortcut, useRegisteredKeyboardShortcuts } from '../shortcuts'

export const HelpPart = memo(function HelpPart() {
    const shortcuts = useRegisteredKeyboardShortcuts()

    const byGroup = useMemo(() => {
        let groups: Record<string, KeyboardShortcut[]> = {}
        for (const s of shortcuts) {
            groups[s.group ?? ''] = [
                ...(groups[s.group ?? ''] ?? []),
                s,
            ]
        }
        return groups
    }, [shortcuts])

    return <>
        {Object.entries(byGroup).map(([group, ss]) => <BodyPart key={group} label={group ? group : 'Misc'}>
            {ss.map(s => <BodyField key={`${s.name}_${s.label}`} label={s.name}>
                <div>
                    <Button variant='outlined' onClick={() => s.callback?.()}>{s.label}</Button>
                </div>
            </BodyField>)}
        </BodyPart>)}
    </>
})