import { memo } from 'react'
import { Replay } from '@mui/icons-material'
import { Tooltip, IconButton } from '@mui/material'

export const ResetButton = memo(function ResetButton({ label, changed, onClick }: { label?: string, changed: boolean, onClick: () => void }) {
    return <Tooltip title={label ?? 'Reset to Default'}>
        <span>
            <IconButton disabled={!changed} size='small' onClick={onClick} tabIndex={0}>
                <Replay />
            </IconButton>
        </span>
    </Tooltip>
})
