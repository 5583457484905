import { Undo, Redo, LunchDining, PhotoCameraBack, Settings, EmojiPeople, Input, Output, Tune, Help } from '@mui/icons-material'
import { AppBar, Stack, Typography, Toolbar, Tooltip, IconButton, Badge, Box, Grow } from '@mui/material'
import { memo, useCallback, useMemo } from 'react'
import { useAppSettersContext, useModelContext } from './context'
import { CustomizerTabs } from './CustomizerTabs'
import { useVTubeStudio } from './parts/VTubeStudioSyncPart'
import { ShortcutIconButton, useKeyboardShortcuts } from './shortcuts'
import { useStoreSelector, useStoreDispatch, CustomizerTab } from './store'

export const CustomizerToolbar = memo(function CustomizerToolbar() {
    const enableDebug = useStoreSelector(s => s.settings.enableDebug)
    const enableVore = useStoreSelector(s => s.settings.enableVore)
    const dispatch = useStoreDispatch()
    const { model } = useModelContext()
    const { setModel } = useAppSettersContext()
    const { api: vtsApi, connecting: vtsConnecting, error: vtsError } = useVTubeStudio()

    const setTab = useCallback((t: CustomizerTab) => {
        dispatch(a => a.setAppSetting({ key: 'tab', value: t }))
    }, [dispatch])

    const onUndo = useCallback(() => {
        dispatch(a => a.undoPresetChange())
    }, [dispatch])

    const onRedo = useCallback(() => {
        dispatch(a => a.redoPresetChange())
    }, [dispatch])

    const fileShortcuts = useKeyboardShortcuts('File', useMemo(() => ({
        load: { name: 'Load Model', key: 'o', ctrl: true, callback: () => setModel(null) },
        import: { name: 'Import Preset', key: 'i', ctrl: true, callback: () => setTab(CustomizerTab.Import) },
        export: { name: 'Export Preset', key: 'e', ctrl: true, callback: () => setTab(CustomizerTab.Export) },
    }), [setModel, setTab]))

    const editShortcuts = useKeyboardShortcuts('Edit', useMemo(() => ({
        undo: { name: 'Undo', key: 'z', callback: onUndo },
        redo: { name: 'Redo', key: 'y', callback: onRedo },
        undo2: { name: 'Redo', key: 'z', ctrl: true, shift: true, callback: onRedo },
    }), [onRedo, onUndo]))

    const undoCount = useStoreSelector(s => s.preset.previous.length)
    const redoCount = useStoreSelector(s => s.preset.next.length)
    const undoReason = useStoreSelector(s => s.preset.previous.length ? s.preset.previous[s.preset.previous.length - 1].reason : '')
    const redoReason = useStoreSelector(s => s.preset.next.length ? s.preset.next[s.preset.next.length - 1].reason : '')

    return <AppBar position='sticky'>
        <Stack direction='row' alignItems='center' pl={2}>
            <Typography variant='h6'>
                Gena Model Customizer
            </Typography>
            {model ? <>
                <Toolbar variant='dense'>
                    <ShortcutIconButton shortcut={fileShortcuts.load} icon={<EmojiPeople />} />
                    <ShortcutIconButton shortcut={fileShortcuts.import} icon={<Input />} />
                    <ShortcutIconButton shortcut={fileShortcuts.export} icon={<Output />} />
                    <Box sx={{ height: 24, borderLeft: '1px solid rgba(255, 255, 255, 0.3)' }} />
                    <Tooltip title={`Undo ${undoReason} (${editShortcuts.undo.label})`}>
                        <span>
                            <IconButton disabled={!undoReason} onClick={onUndo} tabIndex={0}>
                                <Badge badgeContent={undoCount}>
                                    <Undo />
                                </Badge>
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title={`Redo ${redoReason} (${editShortcuts.redo.label})`}>
                        <span>
                            <IconButton disabled={!redoReason} onClick={onRedo} tabIndex={0}>
                                <Badge badgeContent={redoCount}>
                                    <Redo />
                                </Badge>
                            </IconButton>
                        </span>
                    </Tooltip>
                </Toolbar>
            </> : null}
            <Box sx={{ flexGrow: 1 }} />
            <Toolbar variant='dense'>
                <Grow in={enableVore} mountOnEnter unmountOnExit>
                    <Tooltip title='Vore Settings'>
                        <IconButton onClick={() => setTab(CustomizerTab.Vore)}>
                            <LunchDining />
                        </IconButton>
                    </Tooltip>
                </Grow>
                <Grow in={enableDebug} mountOnEnter unmountOnExit>
                    <Tooltip title='Model Mapping'>
                        <IconButton onClick={() => setTab(CustomizerTab.Definition)}>
                            <Tune />
                        </IconButton>
                    </Tooltip>
                </Grow>
                <Tooltip title='Backdrop Settings'>
                    <IconButton onClick={() => setTab(CustomizerTab.Backdrop)}>
                        <PhotoCameraBack />
                    </IconButton>
                </Tooltip>
                <Tooltip title='VTube Studio Integration'>
                    <IconButton onClick={() => setTab(CustomizerTab.VTubeStudio)}>
                        <Badge variant='dot' color={vtsApi ? 'success' : vtsConnecting ? 'warning' : vtsError ? 'error' : 'default'}>
                            <img src="/vts_logo_transparent.png" alt='VTube Studio Integration' height={20} />
                        </Badge>
                    </IconButton>
                </Tooltip>
                <Tooltip title='App Settings'>
                    <IconButton onClick={() => setTab(CustomizerTab.Settings)}>
                        <Settings />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Shortcuts/Controls'>
                    <IconButton onClick={() => setTab(CustomizerTab.Help)}>
                        <Help />
                    </IconButton>
                </Tooltip>
            </Toolbar>
        </Stack>
        {model ? <CustomizerTabs /> : null}
    </AppBar>
})
