import { Button, MenuItem, Select, Slider, Stack, Switch, TextField } from '@mui/material'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { BodyField, BodyPart } from '../BodyField'
import { useModelDefinition } from '../context'
import { useAnimation } from '../hooks'
import { GenericModelCustomizationOverrides, ModelType } from '../models'
import { BellyButtonType, BellyType } from '../models/gena'
import { storeSelector, useStoreDispatch, useStoreSelector, VoreType, WeightGainBodyPart } from '../store'
import { generateID, tuple } from '../utils'

const voreTypes = ['stomach', 'womb', 'ass', 'dick', 'ball'] as const
const bodyParts = ['fat', 'breast', 'thigh', 'dick', 'ball'] as const
const voreTypeLabels: Record<VoreType, string> = {
    stomach: 'Stomach',
    womb: 'Womb',
    ass: 'Ass',
    dick: 'Cock',
    ball: 'Balls',
}
const bodyPartLabels: Record<WeightGainBodyPart, string> = {
    fat: 'Fat',
    breast: 'Breast',
    thigh: 'Thigh',
    dick: 'Dick',
    ball: 'Balls',
}

export function useVoreSimulation() {
    const modelDefinition = useModelDefinition()
    const dispatch = useStoreDispatch()
    const updateTimeRef = useRef<number>(0)

    useAnimation(useCallback((dt: number) => {
        const { sliders } = storeSelector(s => s.preset.current)
        const { settings: stg, simulation: sim } = storeSelector(s => s.vore)
        if (!stg.enableSim) return
        if (modelDefinition.type !== ModelType.GenaTypeI_HSGBaseModelR1_P_V1) {
            dispatch(s => s.setVoreSimActive(false))
            return
        }
        updateTimeRef.current += dt
        if (updateTimeRef.current > 1 / 10) {
            dt = updateTimeRef.current
            updateTimeRef.current = 0

            const prey = sim.prey.map(p => {
                const type = stg.types[p.location]
                const damage = p.health > 0 ? Math.min(p.health, type.deathRate * dt) : 0
                const digestion = p.health === 0 && p.size > 0 ? Math.min(p.size, type.digestRate * dt) : 0
                return {
                    id: p.id,
                    health: p.health - damage,
                    size: p.size - digestion,
                    location: p.location,
                }
            }).map(p => stg.types[p.location].target !== 'none' && p.size === 0 ? {
                id: p.id,
                health: 1,
                size: 1,
                location: stg.types[p.location].target as VoreType,
            } : p).filter(p => p.size > 0)

            const sizes = Object.fromEntries(voreTypes.map(t => tuple(t, sim.prey.filter(p => p.location === t || stg.types[p.location].target === t).reduce((p, c) => p + (stg.types[c.location].target === t ? 1 - c.size : c.size), 0)))) as Record<VoreType, number>

            const struggles = Object.fromEntries(voreTypes.map(t => tuple(t, prey.filter(p => p.location === t).reduce((p, c) => Math.max(p, c.health), 0)))) as Record<VoreType, number>

            const digestingCounts = Object.fromEntries(voreTypes.map(t => tuple(t, sim.prey.filter(p => p.location === t && stg.types[p.location].target === 'none' && p.health === 0).length))) as Record<VoreType, number>

            const gains = Object.fromEntries(bodyParts.map(b => tuple(b, voreTypes.reduce((p, t) => p + digestingCounts[t] * stg.types[t].digestRate * stg.types[t].weightGainRates[b] * dt, 0)))) as Record<WeightGainBodyPart, number>

            const losses = Object.fromEntries(bodyParts.map(b => tuple(b, stg.weightLossRates[b] * dt))) as Record<WeightGainBodyPart, number>

            const weights = Object.fromEntries(bodyParts.map(b => tuple(b, Math.min(1, Math.max(0, sim.weights[b] + gains[b] - losses[b]))))) as Record<WeightGainBodyPart, number>

            const lerp = (min: number, max: number, t: number) => {
                return min + (max - min) * t
            }

            const bellyType = sizes.womb > sizes.stomach ? BellyType.Pregnant : BellyType.Vore

            const bellyButtonType = sizes.womb > sizes.stomach ? BellyButtonType.Outie : struggles.stomach > 0 ? BellyButtonType.Thin : BellyButtonType.Wide

            const presetOverrides: GenericModelCustomizationOverrides = {
                choices: {
                    bellyType,
                    bellyButtonType,
                },
                sliders: {
                    bellyBulges: -1 + struggles.stomach * 2,
                    weightGainSize: sliders.weightGainSize + 50 * lerp(0, stg.weightGainScales.fat, weights.fat),
                    breastSize: sliders.breastSize + 10 * lerp(0, stg.weightGainScales.breast, weights.breast),
                    thighSize: sliders.thighSize + 50 * (lerp(0, stg.types.ass.scale, sizes.ass) + lerp(0, stg.weightGainScales.thigh, weights.thigh)),
                    dickSize: sliders.dickSize + 50 * (lerp(0, stg.types.dick.scale, sizes.dick) + lerp(0, stg.weightGainScales.dick, weights.dick)),
                    bellySize: sliders.bellySize + 50 * (lerp(0, stg.types.stomach.scale, sizes.stomach) + lerp(0, stg.types.womb.scale, sizes.womb)),
                    ballSize: sliders.ballSize + 50 * (lerp(0, stg.types.ball.scale, sizes.ball) + lerp(0, stg.weightGainScales.ball, weights.ball)),
                },
            }

            dispatch(s => s.updateVoreSimulation({ weights, prey, presetOverrides }))
        }
    }, [dispatch, modelDefinition.type]))
}

const UnclampedSlider = memo(function UnclampedSlider({ value, min, max, setValue }: { value: number, min: number, max: number, setValue: (v: number) => void }) {
    const [textValue, setTextValueDirect] = useState<string>(String(value))

    const transform = (n: number) => Math.sqrt(n)
    const inverseTransform = (n: number) => n * n

    useEffect(() => {
        setTextValueDirect(String(value))
    }, [value])

    const onChangeSlider = useCallback((_: any, v: number[] | number) => {
        setValue(inverseTransform(Array.isArray(v) ? v[0] : v))
    }, [setValue])

    const onChangeTextField = useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const v = e.target.value
        let num = parseFloat(v)
        if (!Number.isNaN(num)) {
            setValue(num)
        }
        setTextValueDirect(v)
    }, [setValue])

    return <Stack direction='row' spacing={1}>
        <Slider value={transform(value)} step={0.00001} min={Math.min(min, value)} max={Math.max(max, value)} onChange={onChangeSlider} />
        <TextField size='small' value={textValue} onChange={onChangeTextField} />
    </Stack>
})

export const VorePart = memo(function VorePart() {
    const stg = useStoreSelector(s => s.vore.settings)
    const dispatch = useStoreDispatch()

    return <>
        <BodyPart label='Vore Simulation Settings'>
            <BodyField label='Simulation Active'>
                <Switch checked={stg.enableSim} onChange={(_, v) => dispatch(a => a.setVoreSimActive(v))} />
            </BodyField>
            {voreTypes.map(t => <BodyField key={t} label='' hidden={!stg.enableSim}>
                <Button onClick={() => dispatch(a => a.addVoreSimulationPrey({ id: generateID(), size: 1, health: 1, location: t }))}>Add Prey to {voreTypeLabels[t]}</Button>
            </BodyField>)}
            <BodyField label='' hidden={!stg.enableSim}>
                <Button onClick={() => dispatch(a => a.updateVoreSimulation({ prey: [], weights: Object.fromEntries(bodyParts.map(b => tuple(b, 0))) as Record<WeightGainBodyPart, number>, presetOverrides: {} }))}>Reset Simulation</Button>
            </BodyField>
        </BodyPart>
        {voreTypes.map(t => <BodyPart key={t} label={`${voreTypeLabels[t]} Vore Settings`}>
            <BodyField label={`${voreTypeLabels[t]} Per-Prey Scale`}>
                <UnclampedSlider value={stg.types[t].scale} min={0} max={1} setValue={v => dispatch(a => a.setVoreTypeScale({ type: t, value: Array.isArray(v) ? v[0] : v }))} />
            </BodyField>
            <BodyField label='Prey Relaxation Rate'>
                <UnclampedSlider value={stg.types[t].deathRate} min={0} max={1} setValue={v => dispatch(a => a.setVoreTypeDeathRate({ type: t, value: Array.isArray(v) ? v[0] : v }))} />
            </BodyField>
            <BodyField label={`Prey Transition Rate`}>
                <UnclampedSlider value={stg.types[t].digestRate} min={0} max={1} setValue={v => dispatch(a => a.setVoreTypeDigestRate({ type: t, value: Array.isArray(v) ? v[0] : v }))} />
            </BodyField>
            <BodyField label='Transition To'>
                <Select size='small' value={stg.types[t].target} onChange={e => dispatch(a => a.setVoreTypeTarget({ type: t, value: e.target.value as VoreType }))}>
                    <MenuItem value='none'>None (Digestion)</MenuItem>
                    {voreTypes.filter(o => o !== t).map(o => <MenuItem key={o} value={o}>{voreTypeLabels[o]}</MenuItem>)}
                </Select>
            </BodyField>
        </BodyPart>)}
        {bodyParts.map(b => <BodyPart key={b} label={`${bodyPartLabels[b]} Weight Settings`}>
            <BodyField label={`${bodyPartLabels[b]} Weight Gain Scale`}>
                <UnclampedSlider value={stg.weightGainScales[b]} min={0} max={1} setValue={v => dispatch(a => a.setVoreWeightGainScale({ part: b, value: Array.isArray(v) ? v[0] : v }))} />
            </BodyField>
            <BodyField label='Weight Loss Rate'>
                <UnclampedSlider value={stg.weightLossRates[b]} min={0} max={1} setValue={v => dispatch(a => a.setVoreWeightLossRate({ part: b, value: Array.isArray(v) ? v[0] : v }))} />
            </BodyField>
            {voreTypes.filter(t => stg.types[t].target === 'none').map(t => <BodyField key={t} label={`Weight Gain from ${voreTypeLabels[t]} Digestion`}>
                <UnclampedSlider value={stg.types[t].weightGainRates[b]} min={0} max={1} setValue={v => dispatch(a => a.setVoreTypeWeightGainRate({ type: t, part: b, value: Array.isArray(v) ? v[0] : v }))} />
            </BodyField>)}
        </BodyPart>)}
    </>
})
