import { Typography, Button, Select, MenuItem } from '@mui/material'
import { memo, useState, useCallback, useMemo } from 'react'
import { BodyPart, BodyField } from '../BodyField'
import { useModel, useModelDefaults, useModelDefinition } from '../context'
import { useStoreDispatch, useStoreSelector } from '../store'
import { JsonFileDropZone, JsonFileDropZoneResult } from '../FileDropZone'
import { tuple } from '../utils'
import { VTubeStudioConfigFile, VTubeStudioExpressionFile } from '../vtubestudio'
import { safeMergePreset } from '../models'
import { useErrorHandler } from '../error'

export const VTubeStudioImportPart = memo(function VTubeStudioExportPart() {
    const model = useModel()
    const modelDefaults = useModelDefaults()
    const modelDefinition = useModelDefinition()
    const addError = useErrorHandler()
    const [vtubeStudioConfig, setVTubeStudioConfig] = useState<VTubeStudioConfigFile | null>(null)
    const [vtubeExpression, setVTubeExpression] = useState<VTubeStudioExpressionFile | null>(null)
    const [vtubeExpressionName, setVTubeExpressionName] = useState('')
    const [colorHotkeyId, setColorHotkeyId] = useState<string | null>(null)
    const preset = useStoreSelector(s => s.preset.current)
    const dispatch = useStoreDispatch()

    const GLOBAL_COLOR = '$GLOBAL'

    const onUploadFailed = useCallback((reason: any, fileName: string) => {
        addError(reason)
    }, [addError])

    const onUploadConfigJson = useCallback(({ json, fileName }: JsonFileDropZoneResult) => {
        try {
            const config: VTubeStudioConfigFile = JSON.parse(json)
            if (!('Version' in config) || !('ArtMeshDetails' in config)) throw new Error('The uploaded VTube Studio configuration file was not in the correct format')
            if (config.FileReferences.Model !== model.modelName) throw new Error('The uploaded VTube Studio configuration file did not match the currently loaded model')
            setVTubeStudioConfig(config)
        } catch (e) {
            addError(e as Error)
            setVTubeStudioConfig(null)
        }
    }, [addError, model.modelName])

    const onUploadExpressionJson = useCallback(({ json, fileName }: { json: string, fileName: string }) => {
        try {
            const expression: VTubeStudioExpressionFile = JSON.parse(json)
            if (!('Type' in expression) || expression.Type !== 'Live2D Expression' || !('Parameters' in expression)) throw new Error('The uploaded VTube Studio expression file was not in the correct format')
            setVTubeExpression(expression)
            setVTubeExpressionName(fileName)
        } catch (e) {
            addError(e as Error)
            setVTubeExpression(null)
        }
    }, [addError])

    const unloadConfig = useCallback(() => {
        setVTubeStudioConfig(null)
    }, [])

    const unloadExpression = useCallback(() => {
        setVTubeExpression(null)
    }, [])

    const colorHotkeyOptions = useMemo(() => {
        if (!vtubeStudioConfig) return null
        return vtubeStudioConfig.Hotkeys.filter(h => h.Action === 'ArtMeshColorPreset').map(h => tuple(h.HotkeyID, h.Name))
    }, [vtubeStudioConfig])

    const onImportColors = useCallback(() => {
        if (!vtubeStudioConfig || !colorHotkeyId || !modelDefinition || !modelDefaults) return
        const colors = Object.fromEntries((colorHotkeyId === GLOBAL_COLOR ? vtubeStudioConfig.ArtMeshDetails.ArtMeshMultiplyAndScreenColors : vtubeStudioConfig.Hotkeys.find(h => h.HotkeyID === colorHotkeyId)?.ColorScreenMultiplyPreset.ArtMeshMultiplyAndScreenColors)?.flatMap(({ ID, Value }) => {
            const key = Object.entries(modelDefinition.colorArtMeshes).find(([k, v]) => v.includes(ID))?.[0]
            if (key) return [tuple(key, Value.split('|') as [string, string])]
            return []
        }) ?? [])
        dispatch(a => a.loadPreset({
            value: {
                ...safeMergePreset(modelDefaults, preset),
                colors: { ...modelDefaults.colors, ...colors },
            },
            reason: 'Imported Colors',
        }))
    }, [colorHotkeyId, preset, dispatch, modelDefaults, modelDefinition, vtubeStudioConfig])

    const onImportParams = useCallback(() => {
        if (!vtubeExpression || !modelDefinition || !modelDefaults) return
        const exprValues = Object.fromEntries(vtubeExpression.Parameters.map(p => tuple(p.Id, parseFloat(p.Value))))
        const toggles = Object.fromEntries(Object.entries(modelDefinition.toggleParams).map(([k, v]) => {
            return tuple(k, v.reduce((p, c) => Math.max(p, exprValues[c] ?? 0), 0) > 0.5)
        }))
        const choices = Object.fromEntries(Object.entries(modelDefinition.choiceParams).map(([k, v]) => {
            return tuple(k, v.reduce((p, c) => Math.max(p, Math.round(exprValues[c] ?? 0)), 0))
        }))
        const clothing = Object.fromEntries(Object.entries(modelDefinition.clothingParams).map(([k, v]) => {
            return tuple(k, Math.max(0, Object.values(v).findIndex(l => l.some(p => (exprValues[p] ?? 0) > 0.5))))
        }))
        const sliders = Object.fromEntries(Object.entries(modelDefinition.sliderParams).map(([k, v]) => {
            return tuple(k, v.reduce((p, c) => Math.max(p, exprValues[c] ?? 0), 0))
        }))
        dispatch(a => a.loadPreset({
            value: {
                ...preset,
                toggles: { ...preset.toggles, ...toggles },
                choices: { ...preset.choices, ...choices },
                clothing: { ...preset.clothing, ...clothing },
                sliders: { ...preset.sliders, ...sliders },
            },
            reason: 'Imported Parameters',
        }))
    }, [preset, dispatch, modelDefaults, modelDefinition, vtubeExpression])

    return <BodyPart label='Import from VTube Studio'>
        {vtubeStudioConfig ? <>
            <BodyField label='Loaded Configuration File'>
                <Typography variant='body2'>{vtubeStudioConfig.Name}</Typography>
                <Button onClick={unloadConfig}>Unload Configuration File</Button>
            </BodyField>
            <BodyField label='Color Source'>
                <Typography variant='body2'>Choose a source to load colors from:</Typography>
                <Select size='small' value={colorHotkeyId ?? ''} onChange={e => setColorHotkeyId(e.target.value ? e.target.value : null)}>
                    <MenuItem value='' />
                    <MenuItem value={GLOBAL_COLOR}>Model Colors</MenuItem>
                    {colorHotkeyOptions?.map(([id, name]) => <MenuItem key={id} value={id}>{name} (Hotkey)</MenuItem>)}
                </Select>
            </BodyField>
            <BodyField label='Import Colors'>
                <Button size='small' onClick={onImportColors}>Import Colors</Button>
            </BodyField>
        </> : <>
            <Typography variant='body2'>Select the VTube Studio .vtube.json file that you want to load colors from.</Typography>
            <JsonFileDropZone fileDesc='your model&apos;s .vtube.json file from VTube Studio' onUpload={onUploadConfigJson} onUploadFailed={onUploadFailed} />
        </>}
        {vtubeExpression ? <>
            <BodyField label='Loaded Expression File'>
                <Typography variant='body2'>{vtubeExpressionName}</Typography>
                <Button onClick={unloadExpression}>Unload Expression File</Button>
            </BodyField>
            <BodyField label='Import Parameters'>
                <Button size='small' onClick={onImportParams}>Import Parameter Values</Button>
            </BodyField>
        </> : <>
            <Typography variant='body2'>Select the VTube Studio .exp3.json file that you want to load parameter values from.</Typography>
            <JsonFileDropZone fileDesc='your expression&apos;s .exp3.json file from VTube Studio' onUpload={onUploadExpressionJson} onUploadFailed={onUploadFailed} />
        </>}
    </BodyPart>
})
