import { createModel, GenericModelStaticData, ModelType } from './common'

export const ELLIE_MODEL: GenericModelStaticData = createModel(ModelType.Ellie_P_V7, {
    type: ModelType.Ellie_P_V7,
    colors: {},
    links: {},
    toggles: {
        dickOn: false,
        handOn: false,
        breastsOut: false,
        grounded: false,
    },
    choices: {},
    clothing: {},
    sliders: {
        blush: 0,
        breastSize: 0,
        thighSize: 0,
        ballSize: 0,
        dickSize: 0,
        bellySize: 0,
    },
    unmappedArtMeshColors: {},
    unmappedParameterValues: {},
    motions: [],
    expressions: [],
    decals: {},
}, {
    type: ModelType.Ellie_P_V7,
    possibleModelFiles: [
        'HSG Game Asset -  Ellie (Vtube Studio)/HSG Characters Live2D Set1 Ellie P v7.model3.json',
        'HSG Characters Live2D Set1 Ellie P v7.model3.json',
    ],
    sharedParts: [],
    tabs: [{
        name: 'Options',
        parts: [{
            name: 'Body',
            fields: [{
                name: 'Grounded',
                type: 'toggle',
                key: 'grounded',
            }, {
                name: 'Hand On',
                type: 'toggle',
                key: 'handOn',
            }],
        }, {
            name: 'Face',
            fields: [{
                name: 'Blush',
                type: 'slider',
                key: 'blush',
            }],
        }, {
            name: 'Chest',
            fields: [{
                name: 'Breast Size',
                type: 'slider',
                key: 'breastSize',
            }, {
                name: 'Breasts Uncovered',
                type: 'toggle',
                key: 'breastsOut',
            }],
        }, {
            name: 'Belly',
            fields: [{
                name: 'Belly Size',
                type: 'slider',
                key: 'bellySize',
            }],
        }, {
            name: 'Dick',
            fields: [{
                name: 'Dick On',
                type: 'toggle',
                key: 'dickOn',
            }, {
                name: 'Dick Size',
                type: 'slider',
                key: 'dickSize',
                conditions: [{ type: 'toggle', key: 'dickOn', value: true }],
            }, {
                name: 'Balls Size',
                type: 'slider',
                key: 'ballSize',
                conditions: [{ type: 'toggle', key: 'dickOn', value: true }],
            }],
        }, {
            name: 'Hips',
            fields: [{
                name: 'Thigh Size',
                type: 'slider',
                key: 'thighSize',
            }],
        }],
    }],
    choiceOptions: {},
    clothingOptions: {},
    links: {},
    colorArtMeshes: {},
    toggleParams: {
        dickOn: ['ParamDickOn'],
        handOn: ['ParamDickOn2'],
        breastsOut: ['ParamPSize5'],
        grounded: ['ParamPSize7'],
    },
    choiceParams: {},
    clothingParams: {},
    sliderParams: {
        blush: ['ParamBlush'],
        breastSize: ['ParamPSize6'],
        thighSize: ['ParamPSize4'],
        ballSize: ['ParamBSize'],
        dickSize: ['ParamPSize'],
        bellySize: ['ParamPSize3'],
    },
    smoothParams: {
        'ParamPSize6': 5,
        'ParamPSize4': 5,
        'ParamBSize': 5,
        'ParamPSize': 5,
        'ParamPSize3': 5,
        'ParamPSize5': 2,
        'ParamDickOn': 2,
        'ParamDickOn2': 2,
        'ParamDickOn3': 5,
        'ParamDickOn4': 5,
    },
    staticArtMeshColors: {},
    staticParameterValues: {},
    decals: {},
})
