import { createModel, enumToChoiceOptions, enumToClothingOptions, GenericModelStaticData, ModelType } from './common'

enum BodyPoseType {
    Standing = 0,
    Leaning = 1,
    LyingDown = 2,
}

enum ArmPoseType {
    ArmsDown = 0,
    ArmUp = 1,
    ArmsCrossed = 2,
}

enum BreastType {
    None = 0,
    OnePair = 1,
    TwoPairs = 2,
    ThreePairs = 3,
}

enum ClothingTopType {
    None = 0,
    Jumper = 1,
    Bra = 2,
}

enum ClothingBottomType {
    None = 0,
    Jeans = 1,
    Chaps = 2,
    Shorts = 3,
    Underwear = 4,
}

export const MAZE_MODEL: GenericModelStaticData = createModel(ModelType.Maze_A_V40, {
    type: ModelType.Maze_A_V40,
    colors: {},
    links: {},
    toggles: {
        udderOn: false,
        bellyOn: false,
        hairBack: false,
        cowAll: false,
        trayOn: false,

    },
    choices: {
        bodyPose: BodyPoseType.Standing,
        breastType: BreastType.OnePair,
    },
    clothing: {
        armPose: ArmPoseType.ArmsDown,
        top: ClothingTopType.Jumper,
        bottom: ClothingBottomType.Shorts,
    },
    sliders: {
        breastSize: 0,
        udderSize: 0,
        thighSize: 0,
        bellySize: 0,
    },
    unmappedArtMeshColors: {},
    unmappedParameterValues: {},
    motions: [],
    expressions: [],
    decals: {},
}, {
    type: ModelType.Maze_A_V40,
    possibleModelFiles: [
        'HSG Game Asset -  Maze (Vtube Studio)/HSG Characters Live2D Set 3 Maze A v40.model3.json',
        'HSG Characters Live2D Set 3 Maze A v40.model3.json',
    ],
    sharedParts: [],
    tabs: [{
        name: 'Options',
        parts: [{
            name: "Body",
            fields: [{
                name: 'Body Pose',
                type: 'choice',
                key: 'bodyPose',
            }, {
                name: 'Arm Pose',
                type: 'clothing',
                key: 'armPose',
                conditions: [{ key: 'bodyPose', type: 'choice', value: BodyPoseType.Standing }],
            }, {
                name: 'Cow',
                type: 'toggle',
                key: 'cowAll',
            }, {
                name: 'Tray',
                type: 'toggle',
                key: 'trayOn',
                conditions: [{ key: 'bodyPose', type: 'choice', value: BodyPoseType.LyingDown, invert: true }],
            }],
        }, {
            name: 'Clothing',
            fields: [{
                name: 'Top',
                type: 'clothing',
                key: 'top',
            }, {
                name: 'Bottom',
                type: 'clothing',
                key: 'bottom',
            }],
        }, {
            name: 'Head',
            fields: [{
                name: 'Hair Back',
                type: 'toggle',
                key: 'hairBack',
            }]
        }, {
            name: 'Chest',
            fields: [{
                name: 'Number of Breasts',
                type: 'choice',
                key: 'breastType',
            }, {
                name: 'Breast Size',
                type: 'slider',
                key: 'breastSize',
                conditions: [{ key: 'breastType', type: 'choice', value: BreastType.None, invert: true }],
            }],
        }, {
            name: 'Torso',
            fields: [{
                name: 'Udders On',
                type: 'toggle',
                key: 'udderOn',
            }, /*{
                name: 'Udder Size',
                type: 'slider',
                key: 'udderSize',
                conditions: [{ key: 'udderOn', type: 'toggle', value: true }],
            },*/ {
                name: 'Belly On',
                type: 'toggle',
                key: 'bellyOn',
            }, {
                name: 'Belly Size',
                type: 'slider',
                key: 'bellySize',
                conditions: [{ key: 'bellyOn', type: 'toggle', value: true }],
            }, {
                name: 'Thigh Size',
                type: 'slider',
                key: 'thighSize',
            }],
        }]
    }],
    choiceOptions: {
        bodyPose: enumToChoiceOptions(BodyPoseType, false),
        breastType: enumToChoiceOptions(BreastType, false),
    },
    clothingOptions: {
        armPose: enumToClothingOptions(ArmPoseType),
        top: enumToClothingOptions(ClothingTopType),
        bottom: enumToClothingOptions(ClothingBottomType),
    },
    links: {},
    colorArtMeshes: {},
    toggleParams: {
        udderOn: ['Size13'],
        bellyOn: ['Size3'],
        hairBack: ['CowEars4'],
        cowAll: ['CowEars2', 'CowLegs', 'CowHorns', 'CowEars', 'CowEars3'],
        trayOn: ['Size4'],
    },
    choiceParams: {
        bodyPose: ['ParamPoses'],
        breastType: ['Size12'],
    },
    clothingParams: {
        armPose: {
            [ArmPoseType.ArmsDown]: [],
            [ArmPoseType.ArmUp]: ['ParamPoses3'],
            [ArmPoseType.ArmsCrossed]: ['ParamPoses5'],
        },
        top: {
            [ClothingTopType.None]: [],
            [ClothingTopType.Jumper]: ['Size5', 'Size8'],
            [ClothingTopType.Bra]: ['Size8'],
        },
        bottom: {
            [ClothingBottomType.None]: [],
            [ClothingBottomType.Jeans]: ['Size9', 'Size16'],
            [ClothingBottomType.Chaps]: ['Size15'],
            [ClothingBottomType.Shorts]: ['Size14', 'Size16'],
            [ClothingBottomType.Underwear]: ['Size16'],
        },
    },
    sliderParams: {
        breastSize: ['Size'],
        udderSize: ['Size18'],
        thighSize: ['Size20'],
        bellySize: ['Size2'],
    },
    smoothParams: {},
    staticArtMeshColors: {},
    staticParameterValues: {},
    decals: {},
})
