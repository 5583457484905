import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { generateID, tuple } from './utils'

export function useLatest<T>(value: T) {
    const ref = useRef(value)
    ref.current = value
    return useCallback(() => ref.current, [])
}

export function useLatestCallback<T extends (...args: any[]) => any>(callback: T | undefined) {
    const ref = useRef(callback)
    ref.current = callback
    return useCallback((...args: Parameters<T>) => ref.current?.(...args), [])
}

export function useDelta<T>(value: T, defaultValue: T) {
    const beforeRef = useRef(defaultValue)
    const before = beforeRef.current
    const after = value
    beforeRef.current = value
    return tuple(before, after)
}

export function createCustomContext<T>(name: string) {
    const context = createContext<T | null>(null)
    context.displayName = name
    const useCustomContext = () => {
        const ctx = useContext(context)
        if (!ctx) throw new Error(`${name} not provided`)
        return ctx
    }
    const Provider = context.Provider
    return tuple(useCustomContext, Provider, context)
}

export function useRefresh() {
    const [key, setKey] = useState(() => generateID())
    const refresh = useCallback(() => setKey(() => generateID()), [])
    return tuple(refresh, key)
}

export function useAnimation(callback: (dt: number) => void) {
    const latestCallback = useLatestCallback(callback)
    const updateStateRef = useRef<{ lastTime: number | null, handle: number | null }>({ lastTime: null, handle: null })

    const scheduleUpdateCallback = useCallback((callback: (time: number) => void) => {
        const currentState = updateStateRef.current
        currentState.handle = requestAnimationFrame((time) => {
            if (updateStateRef.current === currentState) callback(time)
        })
    }, [])

    const onUpdate = useCallback(async (time: number) => {
        const state = updateStateRef.current
        if (state.lastTime === null) state.lastTime = time
        const dt = (time - state.lastTime) / 1000
        state.lastTime = time
        await latestCallback(dt)
        scheduleUpdateCallback(onUpdate)
    }, [latestCallback, scheduleUpdateCallback])

    useEffect(() => {
        const state = updateStateRef.current
        scheduleUpdateCallback(onUpdate)
        return () => {
            if (state.handle) {
                cancelAnimationFrame(state.handle)
            }
        }
    }, [onUpdate, scheduleUpdateCallback])
}
