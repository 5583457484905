import { Button, Link } from '@mui/material'
import { ModelType } from './models'
import { storeDispatch, storeSelector, useStoreDispatch } from './store'

const url = new URL(window.location.href)
export const PATREON_ENABLED = url.searchParams.has('patreon')

const newPatreonKey = url.searchParams.get('patreonKey')
let patreonKey = storeSelector(s => s.patreon.patreonKey)
let patreonKeyExpiration = storeSelector(s => s.patreon.patreonKeyExpiration)

if (patreonKey && patreonKeyExpiration < Date.now()) {
    patreonKey = null
    patreonKeyExpiration = -1
    storeDispatch(a => a.setPatreonKey({ patreonKey, patreonKeyExpiration }))
}

if (newPatreonKey) {
    patreonKey = newPatreonKey
    patreonKeyExpiration = Date.now() + 24 * 60 * 60 * 1000
    storeDispatch(a => a.setPatreonKey({ patreonKey, patreonKeyExpiration }))
    url.searchParams.delete('patreonKey')
    window.history.replaceState({}, '', url.href)
}

export function isLoggedInToPatreon() {
    return !!patreonKey
}

export function getPatreonKey() {
    return patreonKey
}

export function PatreonSignOutLink() {
    const dispatch = useStoreDispatch()
    const onSignOut = () => {
        dispatch(a => a.setPatreonKey({ patreonKey: null, patreonKeyExpiration: 0 }))
        window.location.reload()
    }
    return <div>
        <Button onClick={onSignOut}>Sign Out of Patreon</Button>
    </div>
}

export function PatreonSignInLink() {
    return <Link href={`https://hsg-customizer.hawkbar.workers.dev/patreon/login?${new URLSearchParams({ origin: url.origin })}`
    }>Sign In to Patreon</Link>
}

export const fetchPatreonModels = async () => {
    if (!patreonKey) return null
    const res = await fetch(`https://hsg-customizer.hawkbar.workers.dev/patreon/models?${new URLSearchParams({ patreonKey })}`)
    if (res.ok) {
        const data = await res.json()
        return data as PatreonModelsResponse
    }
    throw new Error(await res.text())
}

export const downloadPatreonModel = async (modelType: ModelType, setProgress: (p: number) => void) => {
    if (!patreonKey) throw new Error('Cannot download a Patreon model as you are not logged in to Patreon')
    const res = await fetch(`https://hsg-customizer.hawkbar.workers.dev/patreon/download?${new URLSearchParams({ patreonKey, modelType })}`)
    if (res.ok && res.body) {
        const reader = res.body.getReader()
        const contentLength = parseInt(res.headers.get('Content-Length') ?? '', 10)
        let receivedLength = 0
        let chunks: Uint8Array[] = []
        while (true) {
            const { done, value } = await reader.read()
            if (done) break
            chunks.push(value)
            receivedLength += value.length
            setProgress(receivedLength / contentLength)
        }
        const mergedChunks = new Uint8Array(receivedLength)
        let chunkPointer = 0
        for (const chunk of chunks) {
            mergedChunks.set(chunk, chunkPointer)
            chunkPointer += chunk.length
        }
        const buffer = mergedChunks.buffer
        return buffer
    }
    throw new Error(await res.text())
}

export const PATREON_TIERS = [
    'Kindled Flame',
    'Brilliant Flame',
    'Dazzling Flame',
    'Hyper Flame',
    'Hyper Space',
] as const

export type PatreonTier = typeof PATREON_TIERS[number]

export interface PatreonModel {
    type: ModelType
    fileName: string
    patreonTier: string
}

export interface PatreonModelsResponse {
    patreonName: string
    patreonTiers: string[]
    models: PatreonModel[]
}
