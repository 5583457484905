import { Tooltip, IconButton } from '@mui/material'
import { memo, ReactNode, useCallback, useEffect, useMemo, useSyncExternalStore } from 'react'
import { tuple } from './utils'

export interface KeyboardShortcutInput {
    name: string
    key: string
    ctrl?: boolean
    shift?: boolean
    alt?: boolean
    callback?: () => void
}

export interface KeyboardShortcut {
    name: string
    key: string
    group: string
    label: string
    ctrl?: boolean
    shift?: boolean
    alt?: boolean
    callback?: () => void
}

function checkKeyboardShortcut(e: KeyboardEvent, shortcut: KeyboardShortcut) {
    const { key, ctrl, shift, alt } = shortcut
    return e.key.toLowerCase() === key.toLowerCase() && (e.ctrlKey === !!ctrl || e.metaKey === !!ctrl) && e.shiftKey === !!shift && e.altKey === !!alt
}

export function formatShortcut(shortcut: KeyboardShortcut | KeyboardShortcutInput) {
    const { key, ctrl, shift, alt } = shortcut
    return `${ctrl ? 'Ctrl+' : ''}${alt ? 'Alt+' : ''}${shift ? 'Shift+' : ''}${key === ' ' ? 'Space' : key.toUpperCase()}`
}

export function useKeyboardShortcutHandler() {
    const onKeyDown = useCallback((e: KeyboardEvent) => {
        const target: HTMLElement | null = e.target as HTMLElement | null
        if (!target || !['INPUT', 'TEXTAREA'].includes(target.tagName)) {
            const matchingShortcuts = [...registeredShortcuts.values()].filter(s => checkKeyboardShortcut(e, s))
            if (matchingShortcuts.length) {
                e.preventDefault()
                for (const s of matchingShortcuts) s.callback?.()
            }
        }
    }, [])

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown)
        return () => {
            window.removeEventListener('keydown', onKeyDown)
        }
    }, [onKeyDown])
}

const registeredShortcuts = new Set<KeyboardShortcut>()
const subscribeCallbacks: (() => void)[] = []
let cachedShortcuts: KeyboardShortcut[] = []

const subscribe = (callback: () => void) => {
    subscribeCallbacks.push(callback)
    return () => {
        subscribeCallbacks.splice(subscribeCallbacks.indexOf(callback), 1)
    }
}

const snapshot = () => cachedShortcuts

const notify = () => {
    cachedShortcuts = [...registeredShortcuts.values()]
    subscribeCallbacks.forEach(cb => cb())
}

export function useRegisteredKeyboardShortcuts() {
    const state = useSyncExternalStore(subscribe, snapshot)
    return state
}

export function useKeyboardShortcuts<T extends Record<string, KeyboardShortcutInput>>(group: string, shortcuts: T): Record<keyof T, KeyboardShortcut> {
    useEffect(() => {
        const shortcutsList = Object.values(shortcuts).map(s => ({ ...s, group, label: formatShortcut(s) }))
        for (const s of shortcutsList) registeredShortcuts.add(s)
        notify()
        return () => {
            for (const s of shortcutsList) registeredShortcuts.delete(s)
            notify()
        }
    }, [group, shortcuts])

    const labeledShortcuts = useMemo(() => Object.fromEntries(Object.entries(shortcuts).map(([k, v]) => tuple(k, { ...v, group, label: formatShortcut(v) }))), [group, shortcuts]) as Record<keyof T, KeyboardShortcut & { label: string }>

    return labeledShortcuts
}

export const ShortcutIconButton = memo(function ShortcutIconButton({ shortcut, icon }: { shortcut: KeyboardShortcut, icon: ReactNode }) {
    return <Tooltip title={`${shortcut.name} (${shortcut.label})`}>
        <IconButton onClick={() => shortcut.callback?.()} tabIndex={0} >
            {icon}
        </IconButton>
    </Tooltip>
})
