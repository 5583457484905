import { ArrowDropDown, ArrowRight } from '@mui/icons-material'
import { Autocomplete, Collapse, IconButton, Radio, Stack, Switch, TextField, Tooltip } from '@mui/material'
import { memo, useState } from 'react'
import { BodyField, BodyPart, BodyTab } from './BodyField'
import { ColorPicker } from './ColorPicker'
import { useModel, useModelDefaults, useModelDefinition } from './context'
import { CustomizerTab, useStoreSelector } from './store'

function ModelChoice({ k }: { k: string }) {
    const model = useModel()
    const defaults = useModelDefaults()
    const definition = useModelDefinition()
    const [expanded, setExpanded] = useState(false)

    return <BodyField label=''>
        <Stack direction='row' spacing={1}>
            <IconButton onClick={() => setExpanded(e => !e)}>
                {expanded ? <ArrowDropDown /> : <ArrowRight />}
            </IconButton>
            <TextField size='small' label='Choice Identifier' value={k} />
        </Stack>
        <Collapse in={expanded} mountOnEnter unmountOnExit>
            <Stack ml={2} p={1} spacing={1} sx={{ background: 'rgba(0, 0, 0, 0.2)' }}>
                <Autocomplete value={(definition.choiceParams[k] ?? [])} multiple autoComplete size='small' options={Object.keys(model.parameterDefinitions ?? {})} sx={{ width: '100%' }} renderInput={(params) => <TextField {...params} variant='standard' size='small' label='Params' />} />
                {Object.entries(definition.choiceOptions[k] ?? {}).map(([l, v]) => <Stack key={`${l}_${v}`} direction='row' p={1} spacing={1} sx={{ background: 'rgba(0, 0, 0, 0.2)' }}>
                    <TextField size='small' value={l} label='Option Label' />
                    <TextField size='small' value={v} label='Value' />
                    <Tooltip title='Default Value'>
                        <Radio checked={defaults.choices[k] === v} />
                    </Tooltip>
                </Stack>)}
            </Stack>
        </Collapse>
    </BodyField>
}

function ModelClothing({ k }: { k: string }) {
    const model = useModel()
    const defaults = useModelDefaults()
    const definition = useModelDefinition()
    const [expanded, setExpanded] = useState(false)

    return <BodyField label=''>
        <Stack direction='row' spacing={1}>
            <IconButton onClick={() => setExpanded(e => !e)}>
                {expanded ? <ArrowDropDown /> : <ArrowRight />}
            </IconButton>
            <TextField size='small' label='Clothing Identifier' value={k} />
        </Stack>
        <Collapse in={expanded} mountOnEnter unmountOnExit>
            <Stack ml={3} p={1} spacing={1} sx={{ background: 'rgba(0, 0, 0, 0.2)' }}>
                {(definition.clothingOptions[k] ?? []).map((l, v) => <Stack key={`${l}_${v}`} p={1} spacing={1} sx={{ background: 'rgba(0, 0, 0, 0.2)' }}>
                    <Stack direction='row' spacing={1}>
                        <TextField size='small' value={l} label='Option Label' />
                        <Tooltip title='Default Value'>
                            <Radio checked={defaults.clothing[k] === v} />
                        </Tooltip>
                    </Stack>
                    <Autocomplete value={(definition.clothingParams[k]?.[v] ?? [])} multiple autoComplete size='small' options={Object.keys(model.parameterDefinitions ?? {})} sx={{ width: '100%' }} renderInput={(params) => <TextField {...params} variant='standard' size='small' label='Option Params' />} />
                </Stack>)}
            </Stack>
        </Collapse>
    </BodyField>
}

function ModelColor({ k }: { k: string }) {
    const model = useModel()
    const defaults = useModelDefaults()
    const definition = useModelDefinition()
    const [expanded, setExpanded] = useState(false)

    return <BodyField label=''>
        <Stack direction='row' spacing={1}>
            <IconButton onClick={() => setExpanded(e => !e)}>
                {expanded ? <ArrowDropDown /> : <ArrowRight />}
            </IconButton>
            <TextField size='small' label='Color Identifier' value={k} />
            <ColorPicker group={k} label='Default Multiply' value={defaults.colors[k][0]} setValue={() => { }} setValueCommitted={() => { }} />
            <ColorPicker group={k} label='Default Screen' value={defaults.colors[k][1]} setValue={() => { }} setValueCommitted={() => { }} />
        </Stack>
        <Collapse in={expanded} mountOnEnter unmountOnExit>
            <Stack ml={3} p={1} spacing={1} sx={{ background: 'rgba(0, 0, 0, 0.2)' }}>
                <Autocomplete value={(definition.colorArtMeshes[k] ?? [])} multiple autoComplete size='small' options={Object.keys(model.drawableIds ?? {})} sx={{ width: '100%' }} renderInput={(params) => <TextField {...params} variant='standard' size='small' label='ArtMeshes' />} />
            </Stack>
        </Collapse>
    </BodyField>
}

function ModelLink({ k }: { k: string }) {
    const defaults = useModelDefaults()
    const definition = useModelDefinition()
    const [expanded, setExpanded] = useState(false)

    return <BodyField label=''>
        <Stack direction='row' spacing={1}>
            <IconButton onClick={() => setExpanded(e => !e)}>
                {expanded ? <ArrowDropDown /> : <ArrowRight />}
            </IconButton>
            <TextField size='small' label='Link Identifier' value={k} />
            <Tooltip title='Default Value'>
                <Switch checked={defaults.links[k]} />
            </Tooltip>
        </Stack>
        <Collapse in={expanded} mountOnEnter unmountOnExit>
            <Stack ml={2} p={1} spacing={1} sx={{ background: 'rgba(0, 0, 0, 0.2)' }}>
                {Object.entries(definition.links[k]?.colors ?? {}).map(([a, b]) => <Stack key={a} direction='row' spacing={1}>
                    <Autocomplete value={a} autoComplete size='small' options={Object.keys(defaults.colors)} sx={{ width: '100%' }} renderInput={(params) => <TextField {...params} variant='standard' size='small' label='Color Target' />} />
                    <Autocomplete value={b} autoComplete size='small' options={Object.keys(defaults.colors)} sx={{ width: '100%' }} renderInput={(params) => <TextField {...params} variant='standard' size='small' label='Color Source' />} />
                </Stack>)}
            </Stack>
        </Collapse>
    </BodyField>
}

function ModelSlider({ k }: { k: string }) {
    const model = useModel()
    const defaults = useModelDefaults()
    const definition = useModelDefinition()
    const [expanded, setExpanded] = useState(false)

    return <BodyField label=''>
        <Stack direction='row' spacing={1}>
            <IconButton onClick={() => setExpanded(e => !e)}>
                {expanded ? <ArrowDropDown /> : <ArrowRight />}
            </IconButton>
            <TextField size='small' label='Slider Identifier' value={k} />
            <TextField size='small' label='Default Value' value={defaults.sliders[k]} sx={{ maxWidth: 100 }} />
        </Stack>
        <Collapse in={expanded} mountOnEnter unmountOnExit>
            <Autocomplete value={(definition.sliderParams[k] ?? [])} multiple autoComplete size='small' options={Object.keys(model.parameterDefinitions ?? {})} sx={{ width: '100%' }} renderInput={(params) => <TextField {...params} variant='standard' size='small' label='Params' />} />
        </Collapse>
    </BodyField>
}

function ModelToggle({ k }: { k: string }) {
    const model = useModel()
    const defaults = useModelDefaults()
    const definition = useModelDefinition()
    const [expanded, setExpanded] = useState(false)

    return <BodyField label=''>
        <Stack direction='row' spacing={1}>
            <IconButton onClick={() => setExpanded(e => !e)}>
                {expanded ? <ArrowDropDown /> : <ArrowRight />}
            </IconButton>
            <TextField size='small' label='Toggle Identifier' value={k} />
            <Tooltip title='Default Value'>
                <Switch checked={defaults.toggles[k]} />
            </Tooltip>
        </Stack>
        <Collapse in={expanded} mountOnEnter unmountOnExit>
            <Autocomplete value={(definition.toggleParams[k] ?? [])} multiple autoComplete size='small' options={Object.keys(model.parameterDefinitions ?? {})} sx={{ width: '100%' }} renderInput={(params) => <TextField {...params} variant='standard' size='small' label='Params' />} />
        </Collapse>
    </BodyField>
}

function ModelSmoothParam({ k }: { k: string }) {
    const model = useModel()
    const definition = useModelDefinition()

    return <BodyField label=''>
        <Stack direction='row' spacing={1}>
            <Autocomplete value={k} autoComplete size='small' options={Object.keys(model.parameterDefinitions ?? {})} sx={{ width: '100%' }} renderInput={(params) => <TextField {...params} variant='standard' size='small' label='Parameter' />} />
            <TextField size='small' label='Speed' value={definition.smoothParams[k]} sx={{ maxWidth: 100 }} />
        </Stack>
    </BodyField>
}

const FIELD_TYPES = ['link', 'color', 'clothing', 'slider', 'toggle', 'choice'] as const

function ModelFieldCondition({ i, j, k, l }: { i: number, j: number, k: number, l: number }) {
    const defaults = useModelDefaults()
    const definition = useModelDefinition()

    const condition = definition.tabs[i].parts[j].fields[k].conditions![l]

    const identiferOptions = Object.keys(defaults[condition.type === 'clothing' ? 'clothing' as const : `${condition.type}s` as const])

    return <>
        <Autocomplete value={condition.type} autoComplete size='small' options={FIELD_TYPES} sx={{ width: '100%' }} renderInput={(params) => <TextField {...params} variant='standard' size='small' label='Condition Type' />} />
        <Autocomplete value={condition.key} autoComplete size='small' options={identiferOptions} sx={{ width: '100%' }} renderInput={(params) => <TextField {...params} variant='standard' size='small' label='Identifier' />} />
        <TextField size='small' label='Value' value={condition.value} />
        <Tooltip title='Invert Condition'>
            <Switch checked={!!condition.invert} />
        </Tooltip>
    </>
}

function ModelField({ i, j, k }: { i: number, j: number, k: number }) {
    const defaults = useModelDefaults()
    const definition = useModelDefinition()
    const [expanded, setExpanded] = useState(false)

    const field = definition.tabs[i].parts[j].fields[k]

    const identiferOptions = Object.keys(defaults[field.type === 'clothing' ? 'clothing' as const : `${field.type}s` as const])

    return <Stack>
        <Stack direction='row' spacing={1}>
            <IconButton onClick={() => setExpanded(e => !e)}>
                {expanded ? <ArrowDropDown /> : <ArrowRight />}
            </IconButton>
            <TextField size='small' label='Field Name' value={field.name} />
        </Stack>
        <Collapse in={expanded} mountOnEnter unmountOnExit>
            <Stack ml={2} p={1} spacing={1} sx={{ background: 'rgba(0, 0, 0, 0.2)' }}>
                <Stack spacing={1}>
                    <Autocomplete value={field.type} autoComplete size='small' options={FIELD_TYPES} sx={{ width: '100%' }} renderInput={(params) => <TextField {...params} variant='standard' size='small' label='Field Type' />} />
                    <Autocomplete value={field.key} autoComplete size='small' options={identiferOptions} sx={{ width: '100%' }} renderInput={(params) => <TextField {...params} variant='standard' size='small' label='Identifier' />} />
                    <Stack ml={2} p={1} spacing={1} sx={{ background: 'rgba(0, 0, 0, 0.2)' }}>
                        {field.conditions?.map((_, l) => <ModelFieldCondition key={l} i={i} j={j} k={k} l={l} />)}
                    </Stack>
                </Stack>
            </Stack>
        </Collapse>
    </Stack>
}

function ModelPart({ i, j }: { i: number, j: number }) {
    const definition = useModelDefinition()
    const [expanded, setExpanded] = useState(false)

    const part = definition.tabs[i].parts[j]

    return <Stack>
        <Stack direction='row' spacing={1}>
            <IconButton onClick={() => setExpanded(e => !e)}>
                {expanded ? <ArrowDropDown /> : <ArrowRight />}
            </IconButton>
            <TextField size='small' label='Part Name' value={part.name} />
        </Stack>
        <Collapse in={expanded} mountOnEnter unmountOnExit>
            <Stack ml={2} p={1} spacing={1} sx={{ background: 'rgba(0, 0, 0, 0.2)' }}>
                {part.fields.map((_, k) => <ModelField key={k} i={i} j={j} k={k} />)}
            </Stack>
        </Collapse>
    </Stack>
}

function ModelTab({ i }: { i: number }) {
    const definition = useModelDefinition()
    const [expanded, setExpanded] = useState(false)

    const tab = definition.tabs[i]

    return <BodyField label=''>
        <Stack direction='row' spacing={1}>
            <IconButton onClick={() => setExpanded(e => !e)}>
                {expanded ? <ArrowDropDown /> : <ArrowRight />}
            </IconButton>
            <TextField size='small' label='Tab Name' value={tab.name} />
        </Stack>
        <Collapse in={expanded} mountOnEnter unmountOnExit>
            <Stack ml={2} p={1} spacing={1} sx={{ background: 'rgba(0, 0, 0, 0.2)' }}>
                {tab.parts.map((_, j) => <ModelPart key={j} i={i} j={j} />)}
            </Stack>
        </Collapse>
    </BodyField>
}

export const ModelDefinitionEditor = memo(function ModelDefinitionEditor() {
    const tab = useStoreSelector(s => s.settings.tab)
    const defaults = useModelDefaults()
    const definition = useModelDefinition()

    return <BodyTab hidden={tab !== CustomizerTab.Definition}>
        <BodyPart label='Choices'>
            {Object.keys(defaults.choices).map(k => <ModelChoice key={k} k={k} />)}
        </BodyPart>
        <BodyPart label='Clothing'>
            {Object.keys(defaults.clothing).map(k => <ModelClothing key={k} k={k} />)}
        </BodyPart>
        <BodyPart label='Colors'>
            {Object.keys(defaults.colors).map(k => <ModelColor key={k} k={k} />)}
        </BodyPart>
        <BodyPart label='Links'>
            {Object.keys(defaults.links).map(k => <ModelLink key={k} k={k} />)}
        </BodyPart>
        <BodyPart label='Sliders'>
            {Object.keys(defaults.sliders).map(k => <ModelSlider key={k} k={k} />)}
        </BodyPart>
        <BodyPart label='Toggles'>
            {Object.keys(defaults.toggles).map(k => <ModelToggle key={k} k={k} />)}
        </BodyPart>
        <BodyPart label='Parameter Smoothing'>
            {Object.keys(definition.smoothParams).map(k => <ModelSmoothParam key={k} k={k} />)}
        </BodyPart>
        <BodyPart label='Editor'>
            {definition.tabs.map((_, i) => <ModelTab key={i} i={i} />)}
        </BodyPart>
    </BodyTab>
})