import { Typography, Stack, TextField, Button } from '@mui/material'
import { memo, useState, useMemo, useCallback } from 'react'
import { BodyPart } from '../BodyField'
import { useModelDefinition } from '../context'
import { GenericModelPreset, MODELS, ModelType, safeMergePreset } from '../models'
import { useStoreDispatch } from '../store'
import { JsonFileDropZone, JsonFileDropZoneResult } from '../FileDropZone'
import { deserializeJson } from '../utils'
import { useErrorHandler } from '../error'

function tryParseValidCustomizationJson(str: string): GenericModelPreset | null {
    const value = deserializeJson<GenericModelPreset>(str)
    if (!value || typeof value !== 'object' || !('type' in value) || typeof value.type !== 'string') return null
    return value as GenericModelPreset
}

export const PresetImportPart = memo(function PresetImportPart() {
    const modelDefinition = useModelDefinition()
    const addError = useErrorHandler()
    const dispatch = useStoreDispatch()
    const [importJson, setImportJson] = useState('')

    const isImportJsonValid = useMemo(() => tryParseValidCustomizationJson(importJson), [importJson])

    const onUploadFailed = useCallback((reason: any, fileName: string) => {
        addError(reason)
    }, [addError])

    const onApplyImportJson = useCallback((json: string) => {
        const value = tryParseValidCustomizationJson(json)
        if (value) {
            if (value.type === modelDefinition.type) {
                dispatch(a => a.loadPreset({ value: safeMergePreset(MODELS[value.type as ModelType]!.defaults, value), reason: 'Import Preset' }))
                setImportJson('')
            } else {
                addError(new Error('Failed to import customization preset. The preset was for a different model.'))
            }
        } else {
            addError(new Error('Failed to import customization preset. Invalid format.'))
        }
    }, [modelDefinition.type, dispatch, addError])

    const onUpload = useCallback(({ json }: JsonFileDropZoneResult) => {
        onApplyImportJson(json)
    }, [onApplyImportJson])

    return <BodyPart label='Import Customizer Preset'>
        <JsonFileDropZone fileDesc='customization preset file' onUpload={onUpload} onUploadFailed={onUploadFailed} />
        <Typography>or</Typography>
        <Stack direction="row">
            <TextField value={importJson} onChange={e => setImportJson(e.target.value)} label='Paste Customization Preset' size='small' error={!!importJson && !isImportJsonValid} sx={{ flex: 1 }} />
            <Button size='small' variant='outlined' disabled={!isImportJsonValid} onClick={() => onApplyImportJson(importJson)}>Apply</Button>
        </Stack>
    </BodyPart>
})