import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { Backdrop, Stack, Typography, CircularProgress, Toolbar, Container, ButtonGroup, Button, Tooltip } from '@mui/material'
import { DataGrid, GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useLatest } from './hooks'
import { ModelType } from './models'
import { getPatreonKey } from './patreon'
import { generateID, isPresent } from './utils'

interface AnalyticsSummary {
    months: Partial<Record<string, {
        year: number
        month: number
        totals: Partial<Record<AnyAnalyticsEvent['type'], number>>
    }>>
    gumroadKeys: Partial<Record<string, {
        key: string
        email: string
        product: string
        uses: number
    }>>
}

interface BaseAnalyticsEvent<T extends string> {
    id: string
    when: number
    type: T
    ip: string
}

interface AnalyticsLoadEvent extends BaseAnalyticsEvent<'load'> {

}

interface AnalyticsPatreonLoginEvent extends BaseAnalyticsEvent<'patreonLogin'> {
    patreonUser: string
    patreonEmail: string
}

interface AnalyticsPatreonDownloadEvent extends BaseAnalyticsEvent<'patreonDownload'> {
    model: ModelType
    patreonUser: string
    patreonEmail: string
}

interface AnalyticsGumroadDownloadEvent extends BaseAnalyticsEvent<'gumroadDownload'> {
    model: ModelType
    gumroadEmail: string
    keyInfo?: {
        key: string
        email: string
        product: string
        uses: number
    }
}

interface AnalyticsErrorEvent extends BaseAnalyticsEvent<'error'> {
    error: string
}

type AnyAnalyticsEvent =
    | AnalyticsLoadEvent
    | AnalyticsPatreonLoginEvent
    | AnalyticsPatreonDownloadEvent
    | AnalyticsGumroadDownloadEvent
    | AnalyticsErrorEvent

interface AnalyticsMonth {
    year: number
    month: number
    events: AnyAnalyticsEvent[]
}

const patreonKey = getPatreonKey() ?? ''

export const AnalyticsDashboard = memo(function AnalyticsDashboard() {
    const [month, setMonth] = useState(new Date().getUTCMonth())
    const [year, setYear] = useState(new Date().getUTCFullYear())
    const [summaryData, setSummaryData] = useState<AnalyticsSummary | null>(null)
    const [monthData, setMonthData] = useState<AnalyticsMonth | null>(null)

    const latestMonth = useLatest(month)
    const latestYear = useLatest(year)

    useEffect(() => {
        (async () => {
            setSummaryData(null)
            const res = await fetch(`https://hsg-customizer.hawkbar.workers.dev/analytics/summary?${new URLSearchParams({ patreonKey })}`)
            if (res.ok && res.body) {
                const data = await res.json()
                setSummaryData(data.summary)
            }
        })()
    }, [])

    useEffect(() => {
        (async () => {
            setMonthData(null)
            const res = await fetch(`https://hsg-customizer.hawkbar.workers.dev/analytics/month?${new URLSearchParams({ y: String(year), m: String(month), patreonKey })}`)
            if (res.ok && res.body) {
                const data = await res.json()
                if (year === latestYear() && month === latestMonth()) {
                    setMonthData(data.month)
                }
            }
        })()
    }, [latestMonth, latestYear, month, year])

    const goToPreviousMonth = useCallback(() => {
        if (month === 0) {
            setMonth(11)
            setYear(y => y - 1)
        } else {
            setMonth(m => m - 1)
        }
    }, [month])

    const goToCurrentMonth = useCallback(() => {
        setMonth(new Date().getUTCMonth())
        setYear(new Date().getUTCFullYear())
    }, [])

    const goToNextMonth = useCallback(() => {
        if (month === 11) {
            setMonth(0)
            setYear(y => y + 1)
        } else {
            setMonth(m => m + 1)
        }
    }, [month])

    const monthName = useMemo(() => new Date(year, month).toLocaleString('default', { year: 'numeric', month: 'long' }), [month, year])

    const renderCell = useCallback((params: GridRenderCellParams) => {
        return <Tooltip title={params.value}>
            <span>
                {params.value}
            </span>
        </Tooltip>
    }, [])

    const summaryColumns = useMemo<GridColDef[]>(() => [
        { field: 'date', headerName: 'Month', flex: 1, renderCell: params => <Button onClick={() => { setMonth(params.row.month); setYear(params.row.year) }}>{params.value.toLocaleString('default', { year: 'numeric', month: 'long' })}</Button> },
        { field: 'load', headerName: 'Page Loads', minWidth: 150, align: 'right', renderCell },
        { field: 'patreonLogin', headerName: 'Patreon Logins', minWidth: 150, align: 'right', renderCell },
        { field: 'patreonDownload', headerName: 'Patreon Downloads', minWidth: 150, align: 'right', renderCell },
        { field: 'gumroadDownload', headerName: 'Gumroad Downloads', minWidth: 150, align: 'right', renderCell },
        { field: 'error', headerName: 'Errors', minWidth: 150, align: 'right', renderCell },
        { field: 'total', headerName: 'Total Events', minWidth: 150, align: 'right', renderCell },
    ], [renderCell])

    const summaryRows = useMemo<GridRowsProp>(() => summaryData ? Object.values(summaryData.months).filter(isPresent).map(m => ({ id: `${m.year}-${m.month}`, date: new Date(m.year, m.month), year: m.year, month: m.month, total: Object.values(m.totals).filter(isPresent).reduce((p, c) => p + c, 0), ...m.totals })) : [], [summaryData])

    const monthColumns = useMemo<GridColDef[]>(() => [
        { field: 'when', headerName: 'When', flex: 1, renderCell: params => <Tooltip title={params.value.toLocaleString()}><div>{params.value.toLocaleString()}</div></Tooltip> },
        { field: 'ip', headerName: 'IP', minWidth: 300, renderCell },
        { field: 'type', headerName: 'Type', minWidth: 150, renderCell },
        { field: 'email', headerName: 'Email', minWidth: 250, renderCell },
        { field: 'user', headerName: 'User Name', minWidth: 150, renderCell },
        { field: 'model', headerName: 'Model/Error', minWidth: 300, renderCell },
    ], [renderCell])

    const monthRows = useMemo<GridRowsProp>(() => monthData?.events.map(e => ({ id: e.id ?? generateID(), ip: e.ip, type: e.type, when: new Date(e.when), email: (e.type === 'patreonDownload' || e.type === 'patreonLogin' ? e.patreonEmail : e.type === 'gumroadDownload' ? e.gumroadEmail : ''), user: (e.type === 'patreonDownload' || e.type === 'patreonLogin' ? e.patreonUser : ''), model: (e.type === 'patreonDownload' || e.type === 'gumroadDownload' ? e.model : e.type === 'error' ? e.error : '') })) ?? [], [monthData])

    const gumroadKeyColumns = useMemo<GridColDef[]>(() => [
        { field: 'key', headerName: 'License Key', flex: 1, renderCell },
        { field: 'email', headerName: 'User', minWidth: 250, renderCell },
        { field: 'product', headerName: 'Product', minWidth: 300, renderCell },
        { field: 'uses', headerName: 'Times Used', minWidth: 150, align: 'right', renderCell },
    ], [renderCell])

    const gumroadKeyRows = useMemo<GridRowsProp>(() => summaryData ? Object.values(summaryData.gumroadKeys).filter(isPresent).map(k => ({ id: k.key, key: k.key, email: k.email, product: k.product, uses: k.uses })) : [], [summaryData])

    return <Container sx={{ position: 'relative', overflow: 'auto' }}>
        <Stack sx={{ padding: 1 }}>
            {summaryData ? <>
                <Typography variant='h5' sx={{ marginTop: 2, marginBottom: 1 }}>Monthly Totals</Typography>
                <DataGrid initialState={{ sorting: { sortModel: [{ field: 'date', sort: 'desc' }] } }} disableSelectionOnClick pageSize={10} rowsPerPageOptions={[10, 25, 50, 100]} autoHeight columns={summaryColumns} rows={summaryRows} />
            </> : null}
            {monthData ? <>
                <Typography variant='h5' sx={{ marginTop: 2, marginBottom: 1 }}>Events</Typography>
                <Toolbar>
                    <ButtonGroup sx={{ marginRight: 1 }}>
                        <Button onClick={goToPreviousMonth}><ArrowBack /></Button>
                        <Button onClick={goToCurrentMonth}>Current</Button>
                        <Button onClick={goToNextMonth}><ArrowForward /></Button>
                    </ButtonGroup>
                    <Typography variant='h6'>{monthName}</Typography>
                </Toolbar>
                <DataGrid initialState={{ sorting: { sortModel: [{ field: 'when', sort: 'desc' }] } }} disableSelectionOnClick pageSize={10} rowsPerPageOptions={[10, 25, 50, 100]} autoHeight columns={monthColumns} rows={monthRows} />
            </> : null}
            {summaryData ? <>
                <Typography variant='h5' sx={{ marginTop: 2, marginBottom: 1 }}>Gumroad License Keys</Typography>
                <DataGrid initialState={{ sorting: { sortModel: [{ field: 'uses', sort: 'desc' }] } }} disableSelectionOnClick pageSize={10} rowsPerPageOptions={[10, 25, 50, 100]} autoHeight columns={gumroadKeyColumns} rows={gumroadKeyRows} />
            </> : null}
            {!summaryData || !monthData ? <>
                <Backdrop open={!summaryData || !monthData}>
                    <Stack justifyContent='center' alignItems='center'>
                        <Typography>Loading analytics data...</Typography>
                        <CircularProgress />
                    </Stack>
                </Backdrop>
            </> : null}
        </Stack>
    </Container>
})
