import { createModel, enumToChoiceOptions, enumToClothingOptions, GenericModelStaticData, ModelType } from './common'

enum GlobsType {
    All = 0,
    Some = 1,
    Few = 2,
    None = 3,
}

enum BellyLiquidType {
    None = 0,
    Slime = 1,
    Cum = 2,
}

enum TailType {
    Heart = 0,
    Ball = 1,
}

enum DickType {
    None = 0,
    Normal = 1,
    Hyper = 2,
}

enum HyperDickType {
    Flaccid = 0,
    OneErect = 1,
    TwoErect = 2,
    ThreeErect = 3,
    FourErect = 4,
}

export const SLIME_MODEL: GenericModelStaticData = createModel(ModelType.Slime_V27, {
    type: ModelType.Slime_V27,
    colors: {},
    links: {},
    toggles: {
        blobForm: false,
        altPose: false,
        expressionCry: false,
        expressionConcern: false,
        expressionAnxious: false,
        expressionHungry: false,
        expressionSmile: false,
        breastsOff: false,
    },
    choices: {
        globsType: GlobsType.Some,
        hyperBellyLiquidType: BellyLiquidType.None,
        tailType: TailType.Heart,
        hyperDickType: HyperDickType.Flaccid,
    },
    clothing: {
        dickType: DickType.None,
    },
    sliders: {
        bodySize: 0,
        blush: 0,
        breastSize: 0,
        bellySize: 0,
        thighSize: 0,
        dickSize: 0,
        lipsSize: 0,
        dickErection: 0,
        hyperBreastSize: 0,
        hyperBellySize: 0,
        hyperBellyTransparency: 0,
        hyperBellyLiquidFill: 0,
        hyperDickSize: 0,
        hyperBallSize: 0,
    },
    unmappedArtMeshColors: {},
    unmappedParameterValues: {},
    motions: [],
    expressions: [],
    decals: {},
}, {
    type: ModelType.Slime_V27,
    possibleModelFiles: [
        'HSG Game Asset -  Slimes (Vtube Studio)/HSG Characters Live2D Set 3 Slime v27 Patreon v2.model3.json',
        'HSG Characters Live2D Set 3 Slime v27 Patreon v2.model3.json',
    ],
    sharedParts: [{
        name: 'Pose',
        fields: [{
            name: 'Alt Pose',
            type: 'toggle',
            key: 'altPose',
        }, {
            name: 'Blob Form',
            type: 'toggle',
            key: 'blobForm',
        }],
    }],
    tabs: [{
        name: 'Body',
        parts: [{
            name: 'Body',
            fields: [{
                name: 'Body Size',
                type: 'slider',
                key: 'bodySize',
            }, {
                name: 'Globs',
                type: 'choice',
                key: 'globsType',
            }],
        }, {
            name: 'Face',
            fields: [{
                name: 'Lips Size',
                type: 'slider',
                key: 'lipsSize',
            }],
        }, {
            name: 'Chest',
            fields: [{
                name: 'Breasts On',
                type: 'toggle',
                key: 'breastsOff',
                invert: true,
            }, {
                name: 'Breast Size',
                type: 'slider',
                key: 'breastSize',
                conditions: [{ type: 'toggle', key: 'breastsOff', value: false }],
            }, {
                name: 'Hyper Breasts',
                type: 'slider',
                key: 'hyperBreastSize',
            }],
        }, {
            name: 'Belly',
            fields: [{
                name: 'Belly Size',
                type: 'slider',
                key: 'bellySize',
            }, {
                name: 'Hyper Belly',
                type: 'slider',
                key: 'hyperBellySize',
            }, {
                name: 'Hyper Belly Transparency',
                type: 'slider',
                key: 'hyperBellyTransparency',
            }, {
                name: 'Hyper Belly Liquid Type',
                type: 'choice',
                key: 'hyperBellyLiquidType',
            }, {
                name: 'Hyper Belly Liquid Amount',
                type: 'slider',
                key: 'hyperBellyLiquidFill',
                conditions: [{ type: 'choice', key: 'hyperBellyLiquidType', value: BellyLiquidType.None, invert: true }],
            }],
        }, {
            name: 'Dick',
            fields: [{
                name: 'Dick',
                type: 'clothing',
                key: 'dickType',
            }, {
                name: 'Dick Size',
                type: 'slider',
                key: 'dickSize',
                conditions: [{ type: 'clothing', key: 'dickType', value: DickType.Normal }],
            }, {
                name: 'Dick Erection',
                type: 'slider',
                key: 'dickErection',
            }, {
                name: 'Hyper Dick Type',
                type: 'choice',
                key: 'hyperDickType',
                conditions: [{ type: 'clothing', key: 'dickType', value: DickType.Hyper }],
            }, {
                name: 'Hyper Dick Size',
                type: 'slider',
                key: 'hyperDickSize',
                conditions: [{ type: 'clothing', key: 'dickType', value: DickType.Hyper }],
            }, {
                name: 'Hyper Balls Size',
                type: 'slider',
                key: 'hyperBallSize',
                conditions: [{ type: 'clothing', key: 'dickType', value: DickType.Hyper }],
            }],
        }, {
            name: 'Hips',
            fields: [{
                name: 'Thigh Size',
                type: 'slider',
                key: 'thighSize',
            }, {
                name: 'Tail Type',
                type: 'choice',
                key: 'tailType',
            }],
        }],
    }, {
        name: 'Pose',
        parts: [{
            name: 'Expression',
            fields: [{
                name: 'Blush',
                type: 'slider',
                key: 'blush',
            }, {
                name: 'Crying',
                type: 'toggle',
                key: 'expressionCry',
            }, {
                name: 'Concerned',
                type: 'toggle',
                key: 'expressionConcern',
            }, {
                name: 'Anxious',
                type: 'toggle',
                key: 'expressionAnxious',
            }, {
                name: 'Hungry',
                type: 'toggle',
                key: 'expressionHungry',
            }, {
                name: 'Smiling',
                type: 'toggle',
                key: 'expressionSmile',
            }],
        }],
    }],
    choiceOptions: {
        globsType: enumToChoiceOptions(GlobsType, false),
        hyperBellyLiquidType: enumToChoiceOptions(BellyLiquidType, false),
        tailType: enumToChoiceOptions(TailType),
        hyperDickType: enumToChoiceOptions(HyperDickType, false),
    },
    clothingOptions: {
        dickType: enumToClothingOptions(DickType),
    },
    links: {},
    colorArtMeshes: {},
    toggleParams: {
        blobForm: ['ParamBlob'],
        altPose: ['ParamShork2'],
        expressionCry: ['ParamBlus4'],
        expressionConcern: ['ParamBlus5'],
        expressionAnxious: ['ParamBlus6'],
        expressionHungry: ['ParamBlus7'],
        expressionSmile: ['ParamBlus8'],
        breastsOff: ['ParamShork3'],
    },
    choiceParams: {
        globsType: ['ParamBlob3'],
        hyperBellyLiquidType: ['Param14'],
        tailType: ['ParamShork'],
        hyperDickType: ['Param10'],
    },
    clothingParams: {
        dickType: {
            [DickType.None]: [],
            [DickType.Normal]: ['Param12'],
            [DickType.Hyper]: ['Param6'],
        },
    },
    sliderParams: {
        bodySize: ['ParamBlus9'],
        blush: ['ParamBlus'],
        breastSize: ['ParamBsize'],
        bellySize: ['ParamSsize'],
        thighSize: ['ParamHsize'],
        dickSize: ['ParamPsize'],
        lipsSize: ['ParamPsize2'],
        dickErection: ['Param11'],
        hyperBreastSize: ['Param5'],
        hyperBellySize: ['Param7'],
        hyperBellyTransparency: ['Param13'],
        hyperBellyLiquidFill: ['Param15'],
        hyperDickSize: ['Param8'],
        hyperBallSize: ['Param9'],
    },
    smoothParams: {
        'ParamBlob': 2,
        'Param15': 2,
        'ParamBsize': 5,
        'ParamSsize': 5,
        'ParamHsize': 5,
        'ParamPsize': 5,
        'Param5': 5,
        'Param7': 5,
        'Param8': 5,
        'Param9': 5,
    },
    staticArtMeshColors: {},
    staticParameterValues: {},
    decals: {},
})
