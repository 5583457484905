import { Alert, Button, Switch, TextField } from '@mui/material'
import { useCallback, useState, memo, useEffect, useMemo, ReactNode } from 'react'
import { ApiClient } from 'vtubestudio'
import { BodyField, BodyPart } from '../BodyField'
import { createCustomContext, useAnimation } from '../hooks'
import { GenericModelCustomizationOverrides } from '../models'
import { useStoreSelector, useStoreDispatch, storeSelector, CustomizerTab } from '../store'
import { tuple } from '../utils'

const pluginName = 'Gena Model Customizer'
const pluginDeveloper = 'Hawker'

const trackingParams = new Set(['ParamAngleX', 'ParamAngleY', 'ParamAngleZ', 'ParamBodyAngleX', 'ParamBodyAngleY', 'ParamBodyAngleX2', 'ParamBodyAngleY2', 'ParamBodyAngleZ', 'ParamHipZ', 'ParamHipX', 'ParamCheek', 'ParamEyeLOpen2', 'ParamEyeLOpen', 'ParamEyeLSmile', 'ParamEyeROpen', 'ParamEyeRSmile', 'ParamEyeBallX', 'ParamEyeBallY', 'ParamBrowLY', 'ParamBrowRY', 'ParamBrowLAngle', 'ParamBrowRAngle', 'ParamBrowLForm', 'ParamBrowRForm', 'ParamMouthForm', 'ParamMouthOpenY', 'ParamMouthX'])

const [useVTubeStudio, VTubeStudioProviderInternal] = createCustomContext<{
    api: ApiClient | null
    error: string
    connecting: boolean
    connect: () => void
    disconnect: () => void
}>('VTubeStudioContext')

export { useVTubeStudio }

export function VTubeStudioProvider({ children }: { children: ReactNode }) {
    const port = useStoreSelector(s => s.vtubeStudio.port)
    const dispatch = useStoreDispatch()
    const [sock, setSock] = useState<WebSocket | null>(null)
    const [api, setApi] = useState<ApiClient | null>(null)
    const [error, setError] = useState<string>('')
    const [connecting, setConnecting] = useState(false)

    const disconnect = useCallback(() => {
        if (sock) {
            sock.close()
            setSock(null)
        }
        if (api) {
            setApi(null)
        }
        setConnecting(false)
    }, [api, sock])

    const connect = useCallback(() => {
        disconnect()
        setError('')
        setConnecting(true)
        const ws = new WebSocket(`ws://localhost:${port}`)
        ws.addEventListener('open', async () => {
            try {
                let authenticationToken = storeSelector(s => s.vtubeStudio.authToken)
                const api = ApiClient.fromWebSocket(ws)
                if (!authenticationToken) {
                    ({ authenticationToken } = await api.authenticationToken({ pluginName, pluginDeveloper }))
                    dispatch(a => a.setVTubeStudioAuthToken(authenticationToken))
                }
                await api.authentication({ pluginName, pluginDeveloper, authenticationToken })

                setConnecting(false)
                setApi(api)
            } catch (e) {
                console.error(e)
                setError(`Connection to VTube Studio failed: ${String(e)}`)
                setConnecting(false)
            }
        })
        ws.addEventListener('close', e => {
            setConnecting(false)
            setApi(null)
            setError('Connection to VTube Studio failed. Ensure that the Plugin API is enabled and the port number below matches the one in the VTube Studio Plugin API settings.')
        })
        ws.addEventListener('error', () => {
            setConnecting(false)
            setApi(null)
            setError('Connection to VTube Studio failed. Ensure that the Plugin API is enabled and the port number below matches the one in the VTube Studio Plugin API settings.')
        })
    }, [disconnect, dispatch, port])

    useEffect(() => {
        return () => {
            disconnect()
        }
    }, [disconnect, sock])

    useAnimation(useCallback(async () => {
        try {
            if (!api) return
            const trackingEnabled = storeSelector(s => s.vtubeStudio.enableTracking)
            if (!trackingEnabled) return
            const { parameters } = await api.live2DParameterList()
            const presetOverrides: GenericModelCustomizationOverrides = {
                unmappedParameterValues: Object.fromEntries(parameters.filter(p => trackingParams.has(p.name)).map(p => tuple(p.name, p.value))),
            }
            dispatch(a => a.updateVTubeStudioTracking(presetOverrides))
        } catch (e) {
            console.error(e)
            setError(`Connection to VTube Studio failed: ${String(e)}`)
        }
    }, [api, dispatch]))

    useEffect(() => {

    }, [])

    const ctx = useMemo(() => ({ api, error, connecting, connect, disconnect }), [api, connect, disconnect, connecting, error])

    return <VTubeStudioProviderInternal value={ctx}>
        {children}
    </VTubeStudioProviderInternal>
}

export const VTubeStudioSyncPart = memo(function VTubeStudioSyncPart() {
    const enableTracking = useStoreSelector(s => s.vtubeStudio.enableTracking)
    const port = useStoreSelector(s => s.vtubeStudio.port)
    const dispatch = useStoreDispatch()
    const { api, connecting, connect, disconnect, error } = useVTubeStudio()

    return <>
        {error ? <Alert severity='error'>{error}</Alert> : null}
        {api ? <Alert severity='success'>Connected to VTube Studio!</Alert> : null}
        {enableTracking && !api ? <Alert severity='warning'>Tracking is enabled but the app is not currently connected to VTube Studio. No tracking data will be sent.</Alert> : null}
        <BodyPart label='VTube Studio'>
            <BodyField label='Enable Tracking Sync'>
                <Switch checked={enableTracking} onChange={(_, v) => dispatch(a => a.setVTubeStudioTrackingEnabled(v))} />
            </BodyField>
            {api ? <>
                <Button onClick={disconnect} disabled={connecting}>Disconnect from VTube Studio</Button>
            </> : <>
                <TextField label='API Port' size='small' inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} value={String(port)} onChange={e => dispatch(a => a.setVTubeStudioPort(parseInt(e.target.value, 10)))} />
                <Button onClick={connect} disabled={connecting}>Connect to VTube Studio</Button>
            </>}
        </BodyPart>
        <BodyPart label='Export'>
            <Button onClick={() => dispatch(a => a.setAppSetting({ key: 'tab', value: CustomizerTab.Export }))}>Export Preset to VTube Studio</Button>
        </BodyPart>
    </>
})
