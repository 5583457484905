import { PlayArrow, Stop } from '@mui/icons-material'
import { Button } from '@mui/material'
import { memo, useCallback, useEffect } from 'react'
import { BodyPart } from '../BodyField'
import { useModel } from '../context'
import { useDelta } from '../hooks'
import { useStoreSelector, useStoreDispatch } from '../store'

export const useMotions = () => {
    const model = useModel()
    const active = useStoreSelector(s => s.preset.current.motions)
    const dispatch = useStoreDispatch()
    const [prev, next] = useDelta(active, [])

    useEffect(() => {
        const toAdd = (next ?? []).filter(n => !(prev ?? []).includes(n))
        const toRemove = (prev ?? []).filter(p => !(next ?? []).includes(p))

        for (const name of toAdd) {
            const m = model.otherMotions.find(e => e.name === name)
            if (!m) continue
            m.data._onFinishedMotion = () => dispatch(a => a.removeActiveMotion(name))
            model.userModel.motionManager.startMotionPriority(m.data, false, 3)
        }

        for (const name of toRemove) {
            const m = model.otherMotions.find(e => e.name === name)
            if (!m) continue
            const item = model.userModel.motionManager._motions._ptr.find(e => e && e._motion === m.data && !e.isFinished())
            if (item) item.setFadeOut(m.data.getFadeOutTime())
        }

    }, [active, dispatch, model, next, prev])
}

export const PlayMotionsPart = memo(function PlayMotionsPart() {
    const model = useModel()
    const active = useStoreSelector(s => s.preset.current.motions)
    const dispatch = useStoreDispatch()

    const toggleMotion = useCallback((name: string) => {
        if (active.includes(name)) {
            dispatch(a => a.removeActiveMotion(name))
        } else {
            dispatch(a => a.addActiveMotion(name))
        }
    }, [active, dispatch])

    return <>
        <BodyPart label='Play Motions'>
            {model.otherMotions.map(m => <Button key={m.name} variant='outlined' endIcon={active.includes(m.name) ? <Stop /> : <PlayArrow />} onClick={() => toggleMotion(m.name)}>{m.name}</Button>)}
        </BodyPart>
    </>
})
