import { createModel, GenericModelStaticData, ModelType } from './common'

export const UNKNOWN_MODEL: GenericModelStaticData = createModel(ModelType.Unknown, {
    type: ModelType.Unknown,
    colors: {},
    links: {},
    toggles: {},
    choices: {},
    clothing: {},
    sliders: {},
    unmappedArtMeshColors: {},
    unmappedParameterValues: {},
    motions: [],
    expressions: [],
    decals: {},
}, {
    type: ModelType.Unknown,
    possibleModelFiles: [],
    sharedParts: [],
    tabs: [],
    choiceOptions: {},
    clothingOptions: {},
    links: {},
    colorArtMeshes: {},
    toggleParams: {},
    choiceParams: {},
    clothingParams: {},
    sliderParams: {},
    smoothParams: {},
    staticArtMeshColors: {},
    staticParameterValues: {},
    decals: {},
})
