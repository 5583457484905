import { createModel, enumToChoiceOptions, GenericModelStaticData, ModelType } from './common'

export enum LowerType {
    Normal = 0,
    NoBelt = 1,
    NoThong = 2,
    Dick = 4,
}

export const LACE_MODEL: GenericModelStaticData = createModel(ModelType.Lace_P_V8, {
    type: ModelType.Lace_P_V8,
    colors: {},
    links: {},
    toggles: {
        topOff: false,
        breastsOff: false,
        tongueOut: false,
        clubLightning: false,
        dickLightning: false,
    },
    choices: {
        lower: LowerType.Normal,
    },
    clothing: {},
    sliders: {
        blush: 0,
        clubDown: 0,
        dickErection: 0,
        breastSize: 0,
        thighSize: 0,
        dickSize: 0,
        ballSize: 0,
    },
    unmappedArtMeshColors: {},
    unmappedParameterValues: {},
    motions: [],
    expressions: [],
    decals: {},
}, {
    type: ModelType.Lace_P_V8,
    possibleModelFiles: [
        'HSG Game Asset -  Lace (Vtube Studio)/HSG Characters Live2D Set 3 Lace P v8.model3.json',
        'HSG Characters Live2D Set 3 Lace P v8.model3.json',
    ],
    sharedParts: [],
    tabs: [{
        name: 'Options',
        parts: [{
            name: 'Face',
            fields: [{
                name: 'Blush',
                type: 'slider',
                key: 'blush',
            }, {
                name: 'Tongue Out',
                type: 'toggle',
                key: 'tongueOut',
            }],
        }, {
            name: 'Chest',
            fields: [{
                name: 'Top On',
                type: 'toggle',
                key: 'topOff',
                invert: true,
            }, {
                name: 'Breasts On',
                type: 'toggle',
                key: 'breastsOff',
                invert: true,
            }, {
                name: 'Breast Size',
                type: 'slider',
                key: 'breastSize',
                conditions: [{ type: 'toggle', key: 'breastsOff', value: false }],
            }],
        }, {
            name: 'Club',
            fields: [{
                name: 'Club Angle',
                type: 'slider',
                key: 'clubDown',
            }, {
                name: 'Club Lightning',
                type: 'toggle',
                key: 'clubLightning',
            }],
        }, {
            name: 'Hips',
            fields: [{
                name: 'Lower',
                type: 'choice',
                key: 'lower',
            }, {
                name: 'Dick Size',
                type: 'slider',
                key: 'dickSize',
                conditions: [{ type: 'choice', key: 'lower', value: LowerType.Dick }],
            }, {
                name: 'Balls Size',
                type: 'slider',
                key: 'ballSize',
                conditions: [{ type: 'choice', key: 'lower', value: LowerType.Dick }],
            }, {
                name: 'Dick Erection',
                type: 'slider',
                key: 'dickErection',
                conditions: [{ type: 'choice', key: 'lower', value: LowerType.Dick }],
            }, {
                name: 'Dick Lightning',
                type: 'toggle',
                key: 'dickLightning',
                conditions: [{ type: 'choice', key: 'lower', value: LowerType.Dick }],
            }],
        }, {
            name: 'Legs',
            fields: [{
                name: 'Thigh Size',
                type: 'slider',
                key: 'thighSize',
            }]
        }],
    }],
    choiceOptions: {
        lower: enumToChoiceOptions(LowerType),
    },
    clothingOptions: {},
    links: {},
    colorArtMeshes: {},
    toggleParams: {
        topOff: ['ParamExp28'],
        breastsOff: ['ParamExp5'],
        tongueOut: ['ParamExp12'],
        clubLightning: ['ParamExp10'],
        dickLightning: ['ParamExp11', 'ParamExp9'],
    },
    choiceParams: {
        lower: ['ParamExp8'],
    },
    clothingParams: {},
    sliderParams: {
        blush: ['ParamBlush'],
        clubDown: ['ParamExp13'],
        dickErection: ['ParamPDrive'],
        breastSize: ['ParamBSize'],
        thighSize: ['ParamHSize'],
        dickSize: ['ParamPSize'],
        ballSize: ['ParamPSize2'],
    },
    smoothParams: {
        'ParamBSize': 5,
        'ParamHSize': 5,
        'ParamPSize': 5,
        'ParamPSize2': 5,
        'ParamExp28': 2,
        'ParamExp5': 2,
    },
    staticArtMeshColors: {},
    staticParameterValues: {},
    decals: {},
})
