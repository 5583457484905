import { ModelType } from './models'

const url = new URL(window.location.href)
export const GUMROAD_ENABLED = url.searchParams.has('gumroad')

export interface GumroadModelsResponse {
    models: GumroadModel[]
}

export interface GumroadModel {
    type: ModelType
    fileName: string
    gumroadID: string
}

export const fetchGumroadModels = async () => {
    const res = await fetch(`https://hsg-customizer.hawkbar.workers.dev/gumroad/models?${new URLSearchParams({})}`, { mode: 'cors', cache: 'default' })
    if (res.ok) {
        const data = await res.json()
        return data as GumroadModelsResponse
    }
    throw new Error(await res.text())
}


export const downloadGumroadModel = async (licenseKey: string, modelType: ModelType, setProgress: (p: number) => void) => {
    const res = await fetch(`https://hsg-customizer.hawkbar.workers.dev/gumroad/download?${new URLSearchParams({ licenseKey, modelType })}`, { mode: 'cors', cache: 'no-cache' })
    if (res.ok && res.body) {
        const contentLength = parseInt(res.headers.get('Content-Length') ?? '', 10)
        const modelType = res.headers.get('X-HSG-Customizer-Model-Type')
        const fileName = res.headers.get('X-HSG-Customizer-Model-File-Name')
        if (!modelType || !fileName) throw new Error('Failed to download Gumroad model because the model type and file name could not be determined')
        const reader = res.body.getReader()
        let receivedLength = 0
        let chunks: Uint8Array[] = []
        while (true) {
            const { done, value } = await reader.read()
            if (done) break
            chunks.push(value)
            receivedLength += value.length
            setProgress(receivedLength / contentLength)
        }
        const mergedChunks = new Uint8Array(receivedLength)
        let chunkPointer = 0
        for (const chunk of chunks) {
            mergedChunks.set(chunk, chunkPointer)
            chunkPointer += chunk.length
        }
        const buffer = mergedChunks.buffer
        return { buffer, modelType, fileName }
    } else {
        throw new Error(await res.text())
    }
}
