import { FormControlLabel, Switch } from '@mui/material'
import { CubismMotionQueueEntry } from 'live2d-cubism-framework/dist/motion/cubismmotionqueueentry'
import { memo, useCallback, useEffect } from 'react'
import { BodyPart } from '../BodyField'
import { useModel } from '../context'
import { useDelta } from '../hooks'
import { useStoreDispatch, useStoreSelector } from '../store'

export const useExpressions = () => {
    const model = useModel()
    const active = useStoreSelector(s => s.preset.current.expressions)
    const dispatch = useStoreDispatch()
    const [prev, next] = useDelta(active, [])

    useEffect(() => {
        const toAdd = (next ?? []).filter(n => !(prev ?? []).includes(n))
        const toRemove = (prev ?? []).filter(p => !(next ?? []).includes(p))

        for (const name of toAdd) {
            const m = model.otherExpressions.find(e => e.name === name)
            if (!m) continue
            const item = new CubismMotionQueueEntry()
            item._motion = m.data
            item._autoDelete = false
            model.userModel.expressionManager._motions.pushBack(item)
        }

        for (const name of toRemove) {
            const m = model.otherExpressions.find(e => e.name === name)
            if (!m) continue
            const item = model.userModel.expressionManager._motions._ptr.find(e => e && e._motion === m.data && !e.isFinished())
            if (item) item.setFadeOut(m.data.getFadeOutTime())
        }

    }, [active, dispatch, model, next, prev])
}

export const ToggleExpressionsPart = memo(function ToggleExpressionsPart() {
    const model = useModel()
    const active = useStoreSelector(s => s.preset.current.expressions)
    const dispatch = useStoreDispatch()

    const toggleExpression = useCallback((name: string) => {
        if (active.includes(name)) {
            dispatch(a => a.removeActiveExpression(name))
        } else {
            dispatch(a => a.addActiveExpression(name))
        }
    }, [active, dispatch])

    return <>
        <BodyPart label='Toggle Expressions'>
            {model.otherExpressions.map(e => <FormControlLabel key={e.name} label={e.name} control={<Switch checked={active.includes(e.name)} onChange={() => toggleExpression(e.name)} />} />)}
        </BodyPart>
    </>
})
