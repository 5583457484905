import { createModel, enumToChoiceOptions, GenericModelStaticData, ModelType } from './common'

enum ColorType {
    Blue = 0,
    Green = 1,
    SeaGreen = 2,
    Yellow = 3,
    Orange = 4,
    Pink = 5,
    Purple = 6,
    Red = 7,
    Cum = 8,
    Dark = 9,
    Rainbow = 10,
}

export const SLIME_FAMILIAR_MODEL: GenericModelStaticData = createModel(ModelType.Slime_Familiar, {
    type: ModelType.Slime_Familiar,
    colors: {},
    links: {},
    toggles: {},
    choices: {
        colorType: ColorType.Blue,
    },
    clothing: {},
    sliders: {
        blush: 0,
        transparency: 0,
    },
    unmappedArtMeshColors: {},
    unmappedParameterValues: {},
    motions: [],
    expressions: [],
    decals: {},
}, {
    type: ModelType.Slime_Familiar,
    possibleModelFiles: [
        'Slime - HSG Patreon (Webcam)/Slime - HSG Patreon.model3.json',
        'Slime - HSG Patreon.model3.json',
    ],
    sharedParts: [],
    tabs: [{
        name: 'Options',
        parts: [{
            name: 'Options',
            fields: [{
                name: 'Color',
                type: 'choice',
                key: 'colorType',
            }, {
                name: 'Transparency',
                type: 'slider',
                key: 'transparency',
            }, {
                name: 'Blush',
                type: 'slider',
                key: 'blush',
            }],
        }],
    }],
    choiceOptions: {
        colorType: enumToChoiceOptions(ColorType),
    },
    clothingOptions: {},
    links: {},
    colorArtMeshes: {},
    toggleParams: {},
    choiceParams: {
        colorType: ['ParamBlus3'],
    },
    clothingParams: {},
    sliderParams: {
        blush: ['ParamBlus'],
        transparency: ['ParamBlus4'],
    },
    smoothParams: {},
    staticArtMeshColors: {},
    staticParameterValues: {
        'ParamBlob': 0,
    },
    decals: {},
})
