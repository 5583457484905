
export function safeMerge<T>(a: T, b: any): T {
    if (b === null || b === undefined || typeof a !== typeof b) return a
    if (Array.isArray(a) && Array.isArray(b)) return b as any
    if (typeof a === 'object' && typeof b === 'object') {
        return Object.fromEntries((Object.keys(a)).map(k => [k, safeMerge((a as any)[k], b[k])])) as any
    }
    return b
}

export function tuple<T extends any[]>(...args: T): T {
    return args
}

export function generateID() {
    return new Array(16).fill(0).map(() => Math.floor(Math.random() * 16).toString(16)).join('')
}

export function generateFileTimestamp() {
    const d = new Date()
    return `${d.getFullYear()}${String(d.getMonth()).padStart(2, '0')}${String(d.getDate()).padStart(2, '0')}_${String(d.getHours()).padStart(2, '0')}${String(d.getMinutes()).padStart(2, '0')}`
}

export type ExposedPromise<T> = Promise<T> & { resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void }

export function makeExposedPromise<T>() {
    let resolve: any
    let reject: any
    const promise: ExposedPromise<T> = new Promise<T>((res, rej) => {
        resolve = res
        reject = rej
    }) as ExposedPromise<T>
    promise.resolve = resolve
    promise.reject = reject
    return promise
}

export function serializeJson<T>(obj: T): string | null {
    try {
        return JSON.stringify(obj)
    } catch {
        return null
    }
}

export function deserializeJson<T>(json: string): T | null {
    try {
        return JSON.parse(json)
    } catch {
        return null
    }
}

export function getNameFromPath(path: string, stripExt?: boolean) {
    const normalized = path.replace(/\\/g, '/')
    const parts = normalized.split('/')
    let name = parts.pop()!
    if (stripExt && name.includes('.')) {
        name = name.substring(0, name.indexOf('.'))
    }
    return name
}

export async function promiseAllObject<T extends Record<string, any | Promise<any>>>(obj: T): Promise<{ [K in keyof T]: Awaited<T[K]> }> {
    return Object.fromEntries(await Promise.all(Object.entries(obj).map(async ([k, p]) => tuple(k, await p)))) as any
}

export async function loadImage(src: string): Promise<HTMLImageElement> {
    const promise = makeExposedPromise<HTMLImageElement>()
    const img = new Image()
    img.onload = () => promise.resolve(img)
    img.onerror = e => promise.reject(String(e))
    img.src = src
    return promise
}

export function getEnumKeys(enumType: Record<number | string, string | number>) {
    return Object.keys(enumType).filter(k => isNaN(Number(k)))
}

export function getEnumEntries(enumType: Record<number | string, string | number>) {
    return getEnumKeys(enumType).map(k => tuple(k, Number(enumType[k])))
}

export function getEnumLength(enumType: Record<number | string, string | number>) {
    return Object.keys(enumType).filter(k => !isNaN(Number(k))).length
}

export function isLowerCase(s: string) {
    return s === s.toLowerCase() && s !== s.toUpperCase()
}

export function isUpperCase(s: string) {
    return s === s.toUpperCase() && s !== s.toLowerCase()
}

export function identifierToLabel(s: string) {
    let o = ''
    for (let i = 0; i < s.length; i++) {
        if (i === 0 && isLowerCase(s[i])) o += s[i].toUpperCase()
        else o += s[i]
        if (isLowerCase(s[i]) && isUpperCase(s[i + 1] ?? '')) o += ' '
    }
    return o
}

export function formatNumber(n: number | null | undefined) {
    if (n === null || n === undefined) return ''
    return String(Math.round(n * 1000) / 1000)
}

export function isPresent<T>(t: T | undefined | null | void): t is T {
    return t !== undefined && t !== null;
}

export function hasPresentKey<K extends string | number | symbol>(k: K) {
    return function <T, V>(
        a: T & { [k in K]?: V | null }
    ): a is T & { [k in K]: V } {
        return a[k] !== undefined && a[k] !== null
    }
}

export function hasValueAtKey<K extends string | number | symbol, V>(k: K, v: V) {
    return function <T>(a: T & { [k in K]: any }): a is T & { [k in K]: V } {
        return a[k] === v
    }
}
