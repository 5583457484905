import { Add, Clear } from '@mui/icons-material'
import { Tooltip, Box, Typography, IconButton, SxProps, Theme, Stack } from '@mui/material'
import { memo, useCallback } from 'react'
import { useLatestCallback } from './hooks'

const sizes = {
    tiny: 40,
    small: 60,
    medium: 100,
    large: 150,
}

type ModelButtonSize = keyof typeof sizes

const fixSize = (size: ModelButtonSize | undefined) => size ? size === 'tiny' ? 'small' : size : 'medium'
const nextSize = (size: ModelButtonSize | undefined) => size === 'tiny' ? 'small' : size === 'small' ? 'medium' : 'large'

const buttonStyles: (size: ModelButtonSize, active: boolean) => SxProps<Theme> = (size, active) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: sizes[size],
    height: sizes[size],
    cursor: 'pointer',
    textAlign: 'center',
    borderRadius: 2,
    border: `${size === 'tiny' ? 1 : 2}px solid ${active ? '#00cc66' : 'gray'}`,
    color: 'lightgray',
    ':hover': {
        border: `${size === 'tiny' ? 1 : 2}px solid ${active ? '#00ff80' : 'lightgray'}`,
        color: 'white',
    },
    ':focus': {
        border: `${size === 'tiny' ? 1 : 2}px solid ${active ? '#80ffbf' : 'white'}`,
    },
})

export const ImageButton = memo(function ImageButton({ label, title, src, active, faded, size, onClick, onDelete }: { label: string, title?: string, src?: string, active?: boolean, faded?: boolean, size?: ModelButtonSize, onClick: () => void, onDelete?: () => void }) {
    const doClick = useLatestCallback(onClick)
    const doDelete = useLatestCallback(onDelete)

    const onButtonClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()

        doClick()
    }, [doClick])

    const onDeleteButtonClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        e.stopPropagation()

        doDelete()
    }, [doDelete])

    return <Stack>
        <Tooltip title={title ?? ''}>
            <Box tabIndex={0} onClick={onButtonClick} sx={{ ...buttonStyles(size ?? 'medium', active ?? false), background: src ? `url("${src}"), url("/ModelIcons/unknown.png")` : '#292929', backgroundSize: 'cover', filter: faded ? 'brightness(50%)' : 'brightness(100%)', ':hover': { filter: faded ? 'brightness(80%)' : 'brightness(110%)' } }}>
                {onDelete ? <Box sx={{ position: 'absolute', opacity: 0, top: -5, right: -5, ':hover': { opacity: 1 } }}>
                    <IconButton size={fixSize(size)} onClick={onDeleteButtonClick} tabIndex={0}>
                        <Clear />
                    </IconButton>
                </Box> : null}
                {!src ? <Typography variant='body2'>{label}</Typography> : null}
            </Box>
        </Tooltip>
        <Box sx={{ width: sizes[size ?? 'medium'], fontSize: 12, textAlign: 'center', wordBreak: 'break-word' }}>
            {label}
        </Box>
    </Stack>
})

export const ImageButtonNone = memo(function ImageButtonNone({ label, active, size, onClick }: { label: string, active?: boolean, size?: ModelButtonSize, onClick: () => void }) {
    const doClick = useLatestCallback(onClick)

    const onButtonClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()

        doClick()
    }, [doClick])

    return <Tooltip title={label}>
        <Box tabIndex={0} onClick={onButtonClick} sx={{ ...buttonStyles(size ?? 'medium', active ?? false), background: 'transparent' }}>
            <Clear fontSize={nextSize(size)} />
        </Box>
    </Tooltip>
})

export const ImageButtonNew = memo(function ImageButtonNew({ label, active, size, onClick }: { label: string, active?: boolean, size?: ModelButtonSize, onClick: () => void }) {
    const doClick = useLatestCallback(onClick)

    const onButtonClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()

        doClick()
    }, [doClick])

    return <Tooltip title={label}>
        <Box tabIndex={0} onClick={onButtonClick} sx={{ ...buttonStyles(size ?? 'medium', active ?? false), background: 'transparent' }}>
            <Add fontSize={nextSize(size)} />
        </Box>
    </Tooltip>
})
