import { Stack, Switch, Select, MenuItem, Slider, TextField, Paper, Typography, Fade, Slide, Collapse } from '@mui/material'
import { memo, useCallback, useState, useEffect, ReactNode, useRef } from 'react'
import { ColorPicker } from './ColorPicker'
import { useConfirm } from './Confirm'
import { useModel, useModelDefaults, useModelDefinition } from './context'
import { ImageFileDropZone, ImageFileDropZoneResult } from './FileDropZone'
import { ImageButton } from './ModelButton'
import { ModelType } from './models'
import { BellyType } from './models/gena'
import { ResetButton } from './ResetButton'
import { useStoreSelector, useStoreDispatch } from './store'
import { formatNumber } from './utils'

export const BodyColor = memo(function BodyColor({ label, colorKey }: { label: string, colorKey: string }) {
    const modelDefaults = useModelDefaults()
    const defaultMultiplyValue = modelDefaults.colors[colorKey][0] ?? 'FFFFFF'
    const defaultScreenValue = modelDefaults.colors[colorKey][1] ?? '000000'
    const multiplyValue = useStoreSelector(s => s.preset.current.colors[colorKey]?.[0]) ?? defaultMultiplyValue
    const screenValue = useStoreSelector(s => s.preset.current.colors[colorKey]?.[1]) ?? defaultScreenValue
    const dispatch = useStoreDispatch()

    const setMultiplyValue = useCallback((v: string, reason: string | null) => {
        dispatch(a => a.updateColor({ key: colorKey, value: [v, screenValue], reason }))
    }, [colorKey, dispatch, screenValue])

    const setScreenValue = useCallback((v: string, reason: string | null) => {
        dispatch(a => a.updateColor({ key: colorKey, value: [multiplyValue, v], reason }))
    }, [colorKey, dispatch, multiplyValue])

    const resetMultiplyValue = useCallback(() => {
        if (defaultMultiplyValue === multiplyValue) return
        setMultiplyValue(defaultMultiplyValue, `Reset Multiply Color (${label})`)
    }, [defaultMultiplyValue, label, multiplyValue, setMultiplyValue])

    const resetScreenValue = useCallback(() => {
        if (defaultScreenValue === screenValue) return
        setScreenValue(defaultScreenValue, `Reset Screen Color (${label})`)
    }, [defaultScreenValue, label, screenValue, setScreenValue])

    const updateMultiplyValue = useCallback((v: string) => {
        setMultiplyValue(v, null)
    }, [setMultiplyValue])

    const finalizeMultiplyValue = useCallback((newValue: string, oldValue: string) => {
        setMultiplyValue(oldValue, null)
        if (newValue !== oldValue) {
            setMultiplyValue(newValue, `Set Multiply Color (${label})`)
        }
    }, [label, setMultiplyValue])

    const updateScreenValue = useCallback((v: string) => {
        setScreenValue(v, null)
    }, [setScreenValue])

    const finalizeScreenValue = useCallback((newValue: string, oldValue: string) => {
        setScreenValue(oldValue, null)
        if (newValue !== oldValue) {
            setScreenValue(newValue, `Set Screen Color (${label})`)
        }
    }, [label, setScreenValue])

    return <>
        <Stack direction='row' spacing={1}>
            <ColorPicker group={label} label='Multiply' value={multiplyValue} setValue={updateMultiplyValue} setValueCommitted={finalizeMultiplyValue} />
            <ResetButton changed={multiplyValue !== defaultMultiplyValue} onClick={resetMultiplyValue} />
            <ColorPicker group={label} label='Screen' value={screenValue} setValue={updateScreenValue} setValueCommitted={finalizeScreenValue} />
            <ResetButton changed={screenValue !== defaultScreenValue} onClick={resetScreenValue} />
        </Stack>
    </>
})

export const BodyToggle = memo(function BodyToggle({ label, toggleKey, invert }: { label: string, toggleKey: string, invert?: boolean }) {
    const modelDefaults = useModelDefaults()
    const defaultValue = modelDefaults.toggles[toggleKey] ?? false
    const value = useStoreSelector(s => s.preset.current.toggles[toggleKey]) ?? defaultValue
    const dispatch = useStoreDispatch()

    const setValue = useCallback((v: boolean, reason: string | null) => {
        dispatch(a => a.updateToggle({ key: toggleKey, value: v, reason }))
    }, [dispatch, toggleKey])


    const resetValue = useCallback(() => {
        if (defaultValue === value) return
        setValue(defaultValue, `Reset Toggle (${label})`)
    }, [defaultValue, label, setValue, value])

    return <Stack direction='row' spacing={1}>
        <Switch checked={invert ? !value : value} onChange={(_, v) => setValue(invert ? !v : v, `Toggle ${v ? 'On' : 'Off'} (${label})`)} />
        <ResetButton changed={value !== defaultValue} onClick={resetValue} />
    </Stack>
})

export const BodyChoice = memo(function BodyChoice({ label, choiceKey }: { label: string, choiceKey: string }) {
    const modelDefaults = useModelDefaults()
    const modelDefinition = useModelDefinition()
    const defaultValue = modelDefaults.choices[choiceKey] ?? 0
    const value = useStoreSelector(s => s.preset.current.choices[choiceKey]) ?? defaultValue
    const dispatch = useStoreDispatch()

    const setValue = useCallback((v: number, reason: string | null) => {
        dispatch(a => a.updateChoice({ key: choiceKey, value: v, reason }))
    }, [choiceKey, dispatch])


    const resetValue = useCallback(() => {
        if (defaultValue === value) return
        setValue(defaultValue, `Reset Choice (${label})`)
    }, [defaultValue, label, setValue, value])

    return <Stack direction='row' spacing={1}>
        <Select size='small' value={value} onChange={e => setValue(Number(e.target.value), `Set Choice (${label})`)}>
            {Object.entries(modelDefinition.choiceOptions[choiceKey]).map(([k, v]) => <MenuItem key={k} value={v}>{k}</MenuItem>)}
        </Select>
        <ResetButton changed={value !== defaultValue} onClick={resetValue} />
    </Stack>
})

export const BodyClothing = memo(function BodyClothing({ label, clothingKey }: { label: string, clothingKey: string }) {
    const modelDefaults = useModelDefaults()
    const modelDefinition = useModelDefinition()
    const defaultValue = modelDefaults.clothing[clothingKey] ?? 0
    const value = useStoreSelector(s => s.preset.current.clothing[clothingKey]) ?? defaultValue
    const dispatch = useStoreDispatch()

    const setValue = useCallback((v: number, reason: string | null) => {
        dispatch(a => a.updateClothing({ key: clothingKey, value: v, reason }))
    }, [clothingKey, dispatch])


    const resetValue = useCallback(() => {
        if (defaultValue === value) return
        setValue(defaultValue, `Reset Clothing (${label})`)
    }, [defaultValue, label, setValue, value])

    return <Stack direction='row' spacing={1}>
        <Select size='small' value={value} onChange={e => setValue(Number(e.target.value), `Set Clothing (${label})`)}>
            {modelDefinition.clothingOptions[clothingKey].map((o, i) => <MenuItem key={o} value={i}>{o}</MenuItem>)}
        </Select>
        <ResetButton changed={value !== defaultValue} onClick={resetValue} />
    </Stack>
})

export const BodySlider = memo(function BodySlider({ label, sliderKey }: { label: string, sliderKey: string }) {
    const model = useModel()
    const modelDefaults = useModelDefaults()
    const modelDefinition = useModelDefinition()
    const defaultValue = modelDefaults.sliders[sliderKey] ?? 0
    const value = useStoreSelector(s => s.preset.current.sliders[sliderKey]) ?? defaultValue
    const bellyType = useStoreSelector(s => s.preset.current.choices.bellyType)
    const dispatch = useStoreDispatch()
    const [textValue, setTextValueDirect] = useState<string>(String(value))
    const initialDragValueRef = useRef<number | null>(null)

    useEffect(() => {
        setTextValueDirect(String(value))
    }, [value])

    let min: number | undefined = undefined
    let max: number | undefined = undefined

    let parameterKey = modelDefinition.sliderParams[sliderKey]?.[0]
    if (modelDefinition.type === ModelType.GenaTypeI_HSGBaseModelR1_P_V1 && sliderKey === 'bellySize') {
        if (bellyType === BellyType.Pregnant) parameterKey = 'size_Preg'
        if (bellyType === BellyType.Stuffed) parameterKey = 'size_Preg2'
        if (bellyType === BellyType.Vore) parameterKey = 'size_Preg3'
        if (bellyType === BellyType.Eggs) parameterKey = 'size_Preg4'
    }
    if (parameterKey) {
        const parameterDefinition = model.parameterDefinitions[parameterKey]
        min = parameterDefinition?.minValue
        max = parameterDefinition?.maxValue
    }

    const setValue = useCallback((v: number, reason: string | null) => {
        dispatch(a => a.updateSlider({ key: sliderKey, value: v, reason }))
    }, [dispatch, sliderKey])

    const setTextValue = useCallback((v: string) => {
        let num = parseFloat(v)
        if (!Number.isNaN(num)) {
            if (min !== undefined) num = Math.max(min, num)
            if (max !== undefined) num = Math.min(max, num)
            setValue(num, `Set Slider (${label})`)
        }
        setTextValueDirect(v)
    }, [label, max, min, setValue])

    const resetValue = useCallback(() => {
        if (defaultValue === value) return
        setValue(defaultValue, `Reset Slider (${label})`)
    }, [defaultValue, label, setValue, value])

    const updateSliderValue = useCallback((v: number) => {
        if (initialDragValueRef.current === null) {
            initialDragValueRef.current = value ?? 0
        }
        setValue(v, null)
    }, [setValue, value])

    const finalizeSliderValue = useCallback((v: number) => {
        if (initialDragValueRef.current !== null) {
            setValue(initialDragValueRef.current, null)
            initialDragValueRef.current = null
        }
        setValue(v, `Set Slider (${label})`)
    }, [label, setValue])

    return <Stack direction='row' spacing={2}>
        <Slider min={min} max={max} step={0.000001} value={value} marks={[{ value: min ?? 0, label: formatNumber(min) }, { value: max ?? 1, label: formatNumber(max) }]} onChangeCommitted={(_, v) => finalizeSliderValue(Array.isArray(v) ? v[0] : v)} onChange={(_, v) => updateSliderValue(Array.isArray(v) ? v[0] : v)} />
        <TextField size='small' value={textValue} onChange={e => setTextValue(e.target.value)} />
        <ResetButton changed={value !== defaultValue} onClick={resetValue} />
    </Stack>
})

export const BodyLink = memo(function BodyLink({ label, linkKey, invert }: { label: string, linkKey: string, invert?: boolean }) {
    const modelDefaults = useModelDefaults()
    const defaultValue = modelDefaults.links[linkKey] ?? false
    const value = useStoreSelector(s => s.preset.current.links[linkKey]) ?? defaultValue
    const dispatch = useStoreDispatch()

    const setValue = useCallback((v: boolean, reason: string | null) => {
        dispatch(a => a.updateLink({ key: linkKey, value: v, reason }))
    }, [dispatch, linkKey])


    const resetValue = useCallback(() => {
        if (defaultValue === value) return
        setValue(defaultValue, `Reset Link (${label})`)
    }, [defaultValue, label, setValue, value])

    return <Stack direction='row' spacing={1}>
        <Switch checked={invert ? !value : value} onChange={(_, v) => setValue(invert ? !v : v, `Toggle Link (${label})`)} />
        <ResetButton changed={value !== defaultValue} onClick={resetValue} />
    </Stack>
})

export const BodyDecal = memo(function BodyDecal({ label, decalKey }: { label: string, decalKey: string }) {
    const modelDefaults = useModelDefaults()
    const modelDefinition = useModelDefinition()
    const def = modelDefinition.decals[decalKey]
    const defaultValue = modelDefaults.decals[decalKey] ?? ''
    const value = useStoreSelector(s => s.preset.current.decals[decalKey]) ?? defaultValue
    const dispatch = useStoreDispatch()

    const { confirm } = useConfirm()

    const setValue = useCallback((v: string, reason: string | null) => {
        dispatch(a => a.updateDecal({ key: decalKey, value: v, reason }))
    }, [decalKey, dispatch])

    const resetValue = useCallback(() => {
        if (defaultValue === value) return
        setValue(defaultValue, `Reset Decal (${label})`)
    }, [defaultValue, label, setValue, value])

    const onUpload = useCallback(async ({ image }: ImageFileDropZoneResult) => {
        const cvs = document.createElement('canvas')
        cvs.width = def.w
        cvs.height = def.h
        const ctx = cvs.getContext('2d')!

        const imageRatio = image.width / image.height
        const canvasRatio = cvs.width / cvs.height
        let stretch = false

        if (imageRatio !== canvasRatio) {
            stretch = await confirm('The image you have uploaded has a different aspect ratio than the chosen decal. Would you like to stretch the image to fit the entire space or attempt to center it?', { confirm: 'Stretch', cancel: 'Center' })
        }

        let w = cvs.width
        let h = cvs.height
        if (!stretch) {
            if (imageRatio < canvasRatio) {
                w = h * imageRatio
            } else {
                h = w / imageRatio
            }
        }
        const x = -(w - cvs.width) / 2
        const y = -(h - cvs.height) / 2
        ctx.drawImage(image, x, y, w, h)

        setValue(cvs.toDataURL('image/png'), `Upload Decal (${label})`)
    }, [confirm, def.h, def.w, label, setValue])

    return <Stack direction='row' spacing={1}>
        {value ? <>
            <ImageButton src={value} label='' onClick={() => {}} />
        </> : <>
            <ImageFileDropZone compact fileDesc='a .jpg or .png image file' onUpload={onUpload} />
        </>}
        <ResetButton changed={value !== defaultValue} onClick={resetValue} />
    </Stack>
})

export const BodyTabs = memo(function BodyTabs({ children }: { children: ReactNode }) {
    return <Stack sx={{ position: 'relative', height: '100%', overflowY: 'auto' }}>
        {children}
    </Stack>
})

export const BodyTab = memo(function BodyTab({ hidden, side, children }: { hidden?: boolean, side?: 'right' | 'left', children: ReactNode }) {
    return <Slide direction={side === 'right' ? 'left' : 'right'} in={!hidden} mountOnEnter unmountOnExit>
        <Stack spacing={2} sx={{ position: 'absolute', width: '100%' }} pl={2} pr={2} pt={2} pb={2}>
            {children}
        </Stack>
    </Slide>
})

export const BodyPart = memo(function BodyPart({ label, hidden, children }: { label: string, hidden?: boolean, children: ReactNode }) {
    return <Fade in={!hidden} mountOnEnter unmountOnExit>
        <Paper>
            <Stack p={2} pt={1} spacing={1}>
                <Typography variant="h6" color="inherit" component="div">{label}</Typography>
                {children}
            </Stack>
        </Paper>
    </Fade>
})

export const BodyField = memo(function BodyField({ label, hidden, children }: { label: string, hidden?: boolean, children?: ReactNode }) {
    return <Collapse in={!hidden} mountOnEnter unmountOnExit>
        <Stack>
            {label ? <Typography variant='body1'>{label}</Typography> : null}
            {children}
        </Stack>
    </Collapse>
})
