import { createModel, enumToChoiceOptions, GenericModelStaticData, ModelType } from './common'

enum ColorType {
    Orange = 0,
    Purple = 1,
    Blue = 2,
    Green = 3,
}

export const FLAME_MODEL: GenericModelStaticData = createModel(ModelType.Flame_V3, {
    type: ModelType.Flame_V3,
    colors: {},
    links: {},
    toggles: {
        blushing: false,
    },
    choices: {
        color: ColorType.Orange,
    },
    clothing: {},
    sliders: {},
    unmappedArtMeshColors: {},
    unmappedParameterValues: {},
    motions: [],
    expressions: [],
    decals: {},
}, {
    type: ModelType.Flame_V3,
    possibleModelFiles: [
        'Flame - HSG Patreon (Webcam)/Flame - May Mini Vtuber v3.model3.json',
        'Flame - May Mini Vtuber v3.model3.json',
    ],
    sharedParts: [],
    tabs: [{
        name: 'Options',
        parts: [{
            name: 'All',
            fields: [{
                name: 'Color',
                type: 'choice',
                key: 'color',
            }, {
                name: 'Blushing',
                type: 'toggle',
                key: 'blushing',
            }]
        }]
    }],
    choiceOptions: {
        color: enumToChoiceOptions(ColorType),
    },
    clothingOptions: {},
    links: {},
    colorArtMeshes: {},
    toggleParams: {
        blushing: ['ParamBlus'],
    },
    choiceParams: {
        color: ['ParamBlus3'],
    },
    clothingParams: {},
    sliderParams: {},
    smoothParams: {},
    staticArtMeshColors: {},
    staticParameterValues: {},
    decals: {},
})
