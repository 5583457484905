import { createCustomContext } from './hooks'
import { Live2DModel } from './live2D'
import { MODELS, ModelType } from './models'
import { useStoreSelector } from './store'

export const [useModelContext, ModelContextProvider] = createCustomContext<{ model: Live2DModel | null }>('ModelContext')

export const [useAppSettersContext, AppSettersContextProvider] = createCustomContext<{
    setModel: (model: Live2DModel | null) => void
}>('AppSettersContext')

export function useModel() {
    const { model } = useModelContext()
    if (!model) throw new Error('No model loaded')
    return model
}

export function useModelDefinition() {
    const modelType: ModelType = useStoreSelector(s => s.preset.current.type)
    return MODELS[modelType].definition
}

export function useModelDefaults() {
    const modelType: ModelType = useStoreSelector(s => s.preset.current.type)
    return MODELS[modelType].defaults
}
