import { Typography, Button, Switch } from '@mui/material'
import JSZip from 'jszip'
import { memo, useState, useCallback } from 'react'
import { BodyPart, BodyField } from '../BodyField'
import { useConfirm } from '../Confirm'
import { useModel } from '../context'
import { deserializeLive2DViewerSettings, Live2DViewerSettings, serializeLive2DViewerSettings } from '../live2DViewer'
import { useComputedModelParams } from '../ModelWrapper'
import { JsonFileDropZone, JsonFileDropZoneResult } from '../FileDropZone'
import { generateFileTimestamp, getNameFromPath } from '../utils'
import { useErrorHandler } from '../error'

export const Live2DViewerExportPart = memo(function VTubeStudioExportPart() {
    const model = useModel()
    const addError = useErrorHandler()
    const [live2DViewerSettings, setLive2DViewerSettings] = useState<Live2DViewerSettings | null>(null)
    const [live2DViewerSettingsJson, setLive2DViewerSettingsJson] = useState<string | null>(null)
    const [live2DViewerSettingsName, setLive2DViewerSettingsName] = useState('')
    const [replaceColors, setReplaceColor] = useState(true)
    const [replaceParameters, setReplaceParameters] = useState(true)
    const [exporting, setExporting] = useState(false)
    const { confirm } = useConfirm()

    const onUploadFailed = useCallback((reason: any, fileName: string) => {
        addError(reason)
    }, [addError])

    const onUploadSettingsJson = useCallback(({ json, fileName }: JsonFileDropZoneResult) => {
        const settings = deserializeLive2DViewerSettings(json)
        if (!settings) {
            addError(new Error('The uploaded Live2D Viewer settings file was not in the correct format'))
            setLive2DViewerSettings(null)
            setLive2DViewerSettingsJson(null)
            setLive2DViewerSettingsName('')
            return
        }
        if (getNameFromPath(settings.modelFileName) !== getNameFromPath(model.modelName)) {
            confirm('The uploaded Live2D Viewer settings file did not match the currently loaded model. Load it anyway?', { title: 'Load settings anyway?' }).then(confirmed => {
                if (confirmed) {
                    setLive2DViewerSettings(settings)
                    setLive2DViewerSettingsJson(json)
                    setLive2DViewerSettingsName(fileName)
                }
            })
            setLive2DViewerSettings(null)
            setLive2DViewerSettingsJson(null)
            setLive2DViewerSettingsName('')
            return
        }
        setLive2DViewerSettings(settings)
        setLive2DViewerSettingsJson(json)
        setLive2DViewerSettingsName(fileName)
    }, [addError, confirm, model])

    const unloadSettings = useCallback(() => {
        setLive2DViewerSettings(null)
        setLive2DViewerSettingsJson(null)
        setLive2DViewerSettingsName('')
    }, [])

    const { multiplyColors, screenColors, parameterValues } = useComputedModelParams()

    const onExportSettings = useCallback(() => {
        try {
            if (!live2DViewerSettings || !live2DViewerSettingsJson || (!replaceColors && !replaceParameters)) return
            setExporting(true)

            let modifiedSettings: Live2DViewerSettings = { ...live2DViewerSettings }

            if (replaceColors) {
                const white = { r: 1, g: 1, b: 1, a: 1 }
                const black = { r: 0, g: 0, b: 0, a: 0 }

                const colors: NonNullable<Live2DViewerSettings['drawables']>['Drawables'][number][] = [...new Set([...Object.keys(multiplyColors), ...Object.keys(screenColors)]).keys()].map(k => ({ Id: k, MultiplyColor: { ...(multiplyColors[k] ?? white), a: 1 }, ScreenColor: { ...(screenColors[k] ?? black), a: 1 } }))

                modifiedSettings = { ...modifiedSettings, drawables: { Drawables: colors } }
            }

            if (replaceParameters) {
                const params: NonNullable<Live2DViewerSettings['parameters']>['Parameters'][number][] = Object.entries(parameterValues).map(([k, v]) => ({ Id: k, Value: v }))

                modifiedSettings = { ...modifiedSettings, parameters: { Parameters: params } }
            }

            const settingsJson = serializeLive2DViewerSettings(modifiedSettings)

            const zip = new JSZip()
            zip.file(`${live2DViewerSettingsName}.${generateFileTimestamp()}.bak`, live2DViewerSettingsJson)
            zip.file(`${live2DViewerSettingsName}`, settingsJson)

            zip.generateAsync({ type: 'blob' }).then(blob => {
                const url = URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                a.download = `Modified Cubism Viewer Settings.zip`
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
                setExporting(false)

                setTimeout(() => {
                    URL.revokeObjectURL(url)
                }, 60 * 1000)
            }).catch(e => {
                addError(e)
                setExporting(false)
            })
        } catch (e) {
            addError(e as Error)
            setExporting(false)
        }
    }, [addError, live2DViewerSettings, live2DViewerSettingsJson, live2DViewerSettingsName, multiplyColors, parameterValues, replaceColors, replaceParameters, screenColors])

    return <BodyPart label='Export Cubism Viewer for Unity'>
        {live2DViewerSettings ? <>
            <BodyField label='Loaded Settings File'>
                <Typography variant='body2'>{live2DViewerSettingsName}</Typography>
                <Button onClick={unloadSettings}>Unload Settings File</Button>
            </BodyField>
            <BodyField label='Replace Colors'>
                <Switch checked={replaceColors} onChange={(_, v) => setReplaceColor(v)} />
            </BodyField>
            <BodyField label='Replace Parameters'>
                <Switch checked={replaceParameters} onChange={(_, v) => setReplaceParameters(v)} />
            </BodyField>
            <BodyField label='Export Settings File'>
                <Button disabled={!live2DViewerSettings || (!replaceColors && !replaceParameters) || exporting} onClick={onExportSettings}>Download Modified Settings File</Button>
            </BodyField>
        </> : <>
            <Typography variant='body2'>Select the Cubism Viewer settings.json file that you want to save your colors and/or parameter values to.</Typography>
            <JsonFileDropZone fileDesc='your model&apos;s settings.json file from the Cubism Viewer' onUpload={onUploadSettingsJson} onUploadFailed={onUploadFailed} />
        </>}
    </BodyPart>
})
